export const removeHiddenFormAndScripts = () => {
  // Get all the elements created by the Marketo script and remove them from the DOM when the component unmounts
  const form = document.getElementById("mktoForm_6083");
  if (form) {
    form.remove();
  }

  const form2 = document.getElementsByClassName("mktoForm");
  if (form2.length > 0) {
    for (let i = 0; i < form2.length; i++) {
      form2[i].remove();
    }
  }

  const scriptCaller = document.getElementById("mktoForm_script_caller");
  if (scriptCaller) {
    scriptCaller.remove();
  }

  const bigScript = document.getElementById("mktoScript_big");
  if (bigScript) {
    bigScript.remove();
  }

  const iframe = document.getElementById("MktoForms2XDIframe");
  if (iframe) {
    iframe.remove();
  }

  const mktoStyleDiv = document.getElementById("mktoStyleLoaded");
  if (mktoStyleDiv) {
    mktoStyleDiv.remove();
  }
};
