import basicFetch from "./basicFetch";
import backendURL from "../helpers/backendURL";

const fetchRegions = async (language = "en/") => {
	const url = `${backendURL}/${language}api-v1/FetchTaxonomyByMachineName/region_list`;
	const response = await basicFetch(url);
	if (response.data) {
		return response.data;
	} else {
		throw new Error();
	}
};

export default fetchRegions;
