import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
} from "../../actions";
import { Hero1 } from "luna-one";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";
import backendURL from "../../utils/helpers/backendURL";
import fetchWhereToBuy from "../../utils/async/fetchWhereToBuy";
import "./MetalocatorPage.scss";
import PropTypes from "prop-types";

class MetalocatorPage extends Component {
  constructor() {
    super();

    this.state = {
      content: null,
    };
  }
  componentDidMount() {
    this.getData();
  }

  getUrl = () => {
    return `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/where-to-buy/$`;
  };

  getLanguage = () => {
    return `${this.props.language || "en"}/`;
  };

  getData = async () => {
    this.setLoading();

    const url = this.getUrl();
    try {
      const { innerPageNavData, metadata, content } = await fetchWhereToBuy(
        url
      );
      this.props.setInnerPageNav(innerPageNavData);
      this.props.setMetadata(metadata);
      this.setState({ content });
      this.renderEmbeddedCode();
      this.setSuccess();
    } catch (e) {
      console.log(e);
      this.setError();
    }
  };

  renderEmbeddedCode = () => {
    const script = document.createElement("script");
    script.src =
      "//code.metalocator.com/index.php?option=com_locator&view=directory&layout=_javascript&framed=1&format=raw&tmpl=component&no_html=1&Itemid=12883";

    document.body.appendChild(script);
    if (script && document.body.appendChild(script)) {
      this.setSuccess();
    } else {
      this.setError();
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  render() {
    const { status } = this.props;
    const iframeStyle = {
      margin: "50px 32px",
      padding: "0 calc((100vw - 1275px) / 2) 48px",
      width: "100%",
      height: "1000px",
      border: "none",
      overflow: "hidden",
    };

    const embeddedCode =
      "https://admin.metalocator.com/index.php?option=com_locator&view=directory&layout=combined&preview=1&framed=1&tmpl=component&Itemid=12883";
    return (
      <div className="metalocator">
        {status === "loading" && <Loading />}
        {status === "error" && (
          <ErrorPage type="500" history={this.props.history} />
        )}
        {status === "success" && this.state.content && (
          <div className="metalocator__container">
            <Hero1 content={this.state.content} theme="brand-very-dark" />
            <iframe
              className="metalocator__iframe"
              title="metalocator"
              style={iframeStyle}
              src={embeddedCode}
            ></iframe>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(updateStatus(status)),
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
  setMetadata: (metadata) => dispatch(updateMetadata(metadata)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MetalocatorPage);

MetalocatorPage.propTypes = {
  status: PropTypes.string,
  language: PropTypes.string,
  setInnerPageNav: PropTypes.func,
  setStatus: PropTypes.func,
  setMetadata: PropTypes.func,
};
