import basicFetch from "./basicFetch";
import backendURL from "../helpers/backendURL";

const fetchRoutesData = async (language = "en/") => {
  const url = `${backendURL}/${language}api-v1/FetchAllURLandType`;
  const response = await basicFetch(url);
  return formatRoutesData(response.data.nodes, language);
};

const formatRoutesData = (data = [], language) => {
  return data
    .filter((point) => {
      return (
        point.type === "page_builder" ||
        point.type === "products_landing_page" ||
        point.type === "solutions_page" ||
        point.type === "solutions_landing_page" ||
        point.type === "industry_solutions_page"
      );
    })
    .map((point) => {
      return { url: point.url.replace(`/${language}`, "/"), type: point.type };
    });
};

export default fetchRoutesData;
