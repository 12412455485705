import basicFetch from "./basicFetch";
import backendURL from "../helpers/backendURL";
import formatApplicationData from "../methods/formatApplicationData";
const fetchApplicationData = async (language = "en/") => {
  const url = `${backendURL}/${language}api-v1/GetNodeByURL/site-application-data/$`;
  const response = await basicFetch(url);

  return formatApplicationData(response.data[0]);
};

export default fetchApplicationData;
