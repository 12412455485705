import React, { Component } from "react";
import { Icon } from "terra-component-lib";
import "./HardwareTable.scss";
import uniq from "lodash/uniq";
import _ from "lodash";
import fetchTableOrder from "../../utils/async/fetchTableOrder";
import backendURL from "../../utils/helpers/backendURL";

class HardwareTable extends Component {
  constructor() {
    super();
    this.state = {
      scrollX: 0,
      tableOrder: null,
      IE: false,
    };
  }

  componentWillUnmount() {
    this.unsetEventListener();
  }

  componentDidMount() {
    this.setEventListener();
    this.fetchOrderOfTable();
    this.checkForIE();
  }

  checkForIE = () => {
    const userAgent = navigator.userAgent;

    // MSIE used to detect old browsers and Trident used to newer ones (checks for IE11)
    const is_ie =
      userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1;
    if (is_ie) {
      this.setState({ IE: true });
    }
  };

  getLanguage = () => {
    return `${this.props.language || "en"}/`;
  };

  fetchOrderOfTable = async () => {
    try {
      const { data, content } = await fetchTableOrder(
        `${backendURL}/${this.getLanguage()}api-v1/FetchTaxonomyByMachineName/product_features`,
        this.props.content.list
      );

      this.setState({ tableOrder: data, content });
    } catch (e) {
      console.log(e);
    }
  };

  setEventListener = () => {
    const table = document.querySelector(".table");
    table.addEventListener("scroll", this.checkScrollX);
  };

  unsetEventListener = () => {
    const table = document.querySelector(".table");
    table.removeEventListener("scroll", this.checkScrollX);
  };

  checkScrollX = () => {
    const table = document.querySelector(".table");
    const scrollX = table.scrollLeft;
    this.setState({ scrollX });
  };

  columnHeaders = () => {
    // this maps through the data and returns the column headers for the table
    return this.props.content.list.map((columnHeader = {}, i) => {
      return (
        <h1
          className="column-headers"
          style={{ gridColumn: `${i + 2} / span 1` }}
          key={i}
        >
          {columnHeader.product_model}
        </h1>
      );
    });
  };

  columnHeadersIE = () => {
    // this maps through the data and returns the column headers for the table
    return this.props.content.list.map((columnHeader = {}, i) => {
      return (
        <h1
          style={{
            background: "#005f9e",
            color: "#ffffff",
            float: "left !important",
            paddingRight: "40px",
            display: "inline",
          }}
        >
          {columnHeader.product_model}
        </h1>
      );
    });
  };

  tableContent = () => {
    // this reformats the that data we recieve from the API to one array of objects so it is easier to work with
    let tableBody = [];
    const headers = this.state.tableOrder;
    let fullContent = Object.values(this.props.content.list);

    fullContent.map((content = {}) => {
      content.list.map((listItem) => {
        listItem["product_model"] = content.product_model;
        tableBody.push(listItem);
      });
    });

    // some of the data coming in has duplicates (content mistake) .. this removes any duplicate indices
    tableBody = _.uniqWith(tableBody, _.isEqual);

    return tableBody;
  };

  rowHeaders = () => {
    // this puts the row headers in the order we want them to show, based on taxonomy
    let headers = this.state.tableOrder;
    if (headers) {
      return headers.map((rowHeader, i) => {
        return (
          <h1
            key={i}
            style={{
              gridRow: `${i + 2} / span 1 !important`,
            }}
            className="row-headers"
          >
            {rowHeader}
          </h1>
        );
      });
    }
  };

  rowHeadersIE = () => {
    // this puts the row headers in the order we want them to show, based on taxonomy
    let headers = this.state.tableOrder;
    if (headers) {
      return headers.map((rowHeader, i) => {
        return (
          <h1
            style={{
              float: "left !important",
              height: "45px",
              background: "#d0d0d7",
              fontWeight: "300",
            }}
          >
            {rowHeader}
          </h1>
        );
      });
    }
  };

  renderHeaders = () => {
    // this renders the headers and calulates the correct number of rows needed for table
    if (this.state.tableOrder) {
      let height =
        parseInt(JSON.parse(JSON.stringify(this.state.tableOrder.length))) * 50;
      let margin = height / this.state.tableOrder.length / 2;
      return (
        <div
          className="row-headers-container"
          style={{
            transform: `translateX(${this.state.scrollX}px)`,
            gridColumn: `1 / span 1`,
            gridRow: `2 / span ${this.state.tableOrder.length}`,
            MsGridRowSpan: `${this.state.tableOrder.length}`,
            MsGridColumnSpan: `1 / span 1`,
          }}
        >
          {this.rowHeaders()}
        </div>
      );
    }
  };

  renderHeadersIE = () => {
    // this renders the headers and calulates the correct number of rows needed for table
    if (this.state.tableOrder) {
      let height =
        parseInt(JSON.parse(JSON.stringify(this.state.tableOrder.length))) * 50;
      let margin = height / this.state.tableOrder.length / 2;
      return (
        <div
          style={{
            marginTop: margin,
            float: "left",
            height: `${height}px`,
          }}
        >
          {this.rowHeadersIE()}
        </div>
      );
    }
  };

  rowBody = () => {
    const body = this.tableContent();
    let headers = this.state.tableOrder;
    if (headers) {
      // we map through the row headers first, this is just an array of strings, for example - ["Feature 1", "Feature 2", "Feature 3"]
      return headers.map((rowHeader, i) => {
        // here we map through the array of objects that contain all the data we want displayed on the table
        let tableBody = body.map((row = {}, i) => {
          // the conditional checks if the feature name matches the header name
          if (row.feature_name === rowHeader) {
            // the next conditionals check the data for rendering. if there is a cell value and cell icon present in the data, we want the cell value to be displayed.
            if (!row.cell_value && row.cell_icon) {
              return (
                <p className="row-body" key={i}>
                  <Icon
                    key={i}
                    style={{
                      gridColumn: `${i + 2} / span 1 !important`,
                      MsGridColumnSpan: `1 !important`,
                    }}
                    className="row-body"
                    type={row.cell_icon}
                    size="32px"
                  />
                </p>
              );
            }
            if (!row.cell_icon && row.cell_value) {
              return (
                <p
                  key={i}
                  style={{
                    gridColumn: `${i + 2} / span 1 !important`,
                    MsGridColumnSpan: `${i + 2} / span 1 !important`,
                  }}
                  className="row-body"
                >
                  {row.cell_value}
                </p>
              );
            } else {
              return (
                <p
                  key={i}
                  style={{
                    gridColumn: `${i + 2} / span 1 !important`,
                    MsGridColumnSpan: `${i + 2} / span 1 !important`,
                  }}
                  className="row-body"
                ></p>
              );
            }
          }
        });

        return (
          <div
            key={i}
            className="table-body"
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${this.state.content.length}, 1fr)`,
              gridColumn: `2 / span ${this.state.content.length + 1}`,
              MsGridColumnSpan: `${this.state.content.length + 1}`,
            }}
          >
            {tableBody}
          </div>
        );
      });
    }
  };

  rowBodyIE = () => {
    const body = this.tableContent();
    let headers = this.state.tableOrder;
    let width = this.props.content.list.length + 1;
    width = 1200 / width;
    if (headers) {
      // we map through the row headers first, this is just an array of strings, for example - ["Feature 1", "Feature 2", "Feature 3"]
      return headers.map((rowHeader, i) => {
        // here we map through the array of objects that contain all the data we want displayed on the table
        let tableBody = body.map((row = {}, i) => {
          // the conditional checks if the feature name matches the header name
          if (row.feature_name === rowHeader) {
            // the next conditionals check the data for rendering. if there is a cell value and cell icon present in the data, we want the cell value to be displayed.
            if (!row.cell_value && row.cell_icon) {
              return (
                <p
                  key={i}
                  style={{
                    width: `${width}px !important`,
                    float: "left",
                    display: "inline",
                    height: "50px !important",
                    margin: 0,
                  }}
                >
                  <p className="row-body" key={i}>
                    X
                  </p>
                  <Icon
                    key={i}
                    className="row-body"
                    type={row.cell_icon}
                    size="32px"
                  />
                </p>
              );
            }
            if (
              (row.cell_value && row.cell_icon) ||
              (row.cell_value && !row.cell_icon)
            ) {
              return (
                <p
                  key={i}
                  style={{
                    width: `${width}px`,
                    float: "left",
                    display: "inline",
                    height: "50px !important",
                    margin: 0,
                  }}
                  className="row-body"
                >
                  {row.cell_value || row.cell_icon}
                </p>
              );
            }
          }
        });
        return (
          <div
            key={i}
            className="table-body"
            style={{
              float: "left",
            }}
          >
            {tableBody}
          </div>
        );
      });
    }
  };

  render() {
    if (!this.state.IE) {
      return (
        <div className="page-table">
          <h1 style={{ textAlign: "center", fontSize: "28px" }}>
            {this.props.content.intro_header}
          </h1>
          <p
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "300",
              lineHeight: "1.6",
            }}
          >
            {this.props.content.intro_description}
          </p>
          <div
            className="table"
            style={{
              display: "grid",
              gridTemplateColumns: `repeat( ${this.props.content.list.length +
                1}, 1fr)`,
            }}
          >
            {this.columnHeaders()}
            <div
              className="empty"
              style={{
                gridRow: "1 / span 1",
                MsGridRowSpan: 1,
                background: "#ffffff",
                transform: `translateX(${this.state.scrollX}px)`,
              }}
            ></div>
            {this.renderHeaders()}
            {this.rowBody()}
          </div>
        </div>
      );
    } else if (this.state.IE) {
      return (
        <div className="page-table">
          <h1 style={{ textAlign: "center", fontSize: "28px" }}>
            {this.props.content.intro_header}
          </h1>
          <p
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "300",
              lineHeight: "1.6",
            }}
          >
            {this.props.content.intro_description}
          </p>
          <div className="table">
            <div style={{ marginLeft: "100px" }}>{this.columnHeadersIE()}</div>
            <div
              className="empty"
              style={{
                gridRow: "1 / span 1",
                MsGridRowSpan: 1,
                background: "#ffffff",
                transform: `translateX(${this.state.scrollX}px)`,
              }}
            ></div>
            {this.renderHeadersIE()}
            {this.rowBodyIE()}
          </div>
        </div>
      );
    }
  }
}

export default HardwareTable;
