import basicFetch from "./basicFetch";

export default async (url) => {
  let content;
  const response = await basicFetch(url);

  if (response.data && response.data[0]) {
    content = formatContent(
      response.data[0].content_sections.data_struct_items
    );
  }
  return content;
};

const formatContent = (content = []) => {
  let data = {};

  content.forEach((datum = {}) => {
    data[datum.attribute_name] = datum.attribute_value;
  });
  return data;
};
