import basicFetch from "./basicFetch";
import backendURL from "../helpers/backendURL";
import organizeMenus from "../methods/formatMenus";

const fetchMenus = async (language = "en/") => {
  // Fetch menus from Drupal
  const url = `${backendURL}/${language}api-v1/FetchMenuByName/main`;
  const { data } = await basicFetch(url);

  // format and return the navbar data
  return organizeMenus(data);
};

export default fetchMenus;
