export const countryCodes = (code) => {
  let country = [];
  if (!code) {
    return (country = null);
  }
  let asiaPac =
    "AS,AQ,AU,BD,BT,IO,BN,KH,CN,CX,CC,CK,FJ,PF,TF,GU,HM,HK,IN,ID,JP,KI,KG,LA,MO,MY,MV,MH,FM,MN,MM,NR,NP,NC,NZ,NU,NF,MP,PK,PW,PG,PH,PN,WS,SG,SB,SS,LK,TW,TJ,TH,TL,TK,TO,TM,TV,UM,UZ,VU,VN,WF";

  let europeMiddleEastAfria =
    "AF,AX,AL,DZ,AD,AO,AM,AT,AZ,BH,BY,BE,BJ,BA,BW,BV,BG,BF,BI,CM,CV,CF,TD,KM,CD,CG,CI,HR,CY,CZ,DK,DJ,EG,GQ,ER,EE,ET,FK,FO,FI,FR,GA,GM,GE,DE,GH,GI,GR,GL,GG,GN,GW,VA,HU,IS,IR,IQ,IE,IM,IL,IT,JE,JO,KZ,KE,KV,KR,KW,LV,LB,LS,LR,LY,LI,LT,LU,MK,MG,MW,ML,MT,MR,MU,YT,MD,MC,ME,MA,MZ,NA,NL,NE,NG,NO,OM,PS,PL,PT,QA,RE,RO,RU,RW,SH,SM,ST,SA,SN,RS,SC,SL,SK,SI,SO,ZA,GS,ES,SD,SJ,SZ,SE,CH,SY,TZ,TG,TN,TR,UG,UA,AE,GB,EH,YE,ZM,ZW";

  let northAmerica = "US,CA";

  let southAmerica = "AR,AW,BO,BQ,BR,KY,CL,CO,CW,EC,GF,GY,PY,PE,SR,UY,VE";

  let asiaList = asiaPac.split(",");
  let europeAfrialist = europeMiddleEastAfria.split(",");
  let NAmericalist = northAmerica.split(",");
  let SAmericalist = southAmerica.split(",");

  if (code.includes(asiaPac)) {
    country.push("Asia Pacific");
  }
  if (code.includes(europeMiddleEastAfria)) {
    country.push("Europe Middle East Africa");
  }
  if (code.includes(northAmerica)) {
    country.push("US/CA");
  }
  if (code.includes(southAmerica)) {
    country.push("South America");
  }
  if (code.length > 1) {
    code.forEach((a) => {
      asiaList.forEach((x) => {
        if (a === x) {
          country.push("Asia Pacific");
        }
      });
      europeAfrialist.forEach((x) => {
        if (a === x) {
          country.push("Europe Middle East Africa");
        }
      });
      NAmericalist.forEach((x) => {
        if (a === x) {
          country.push("North America");
        }
      });
      SAmericalist.forEach((x) => {
        if (a === x) {
          country.push("South America");
        }
      });
    });
  }
  return country;
};
