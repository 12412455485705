import basicFetch from "./basicFetch";

export default async (url) => {
  let misc;

  const response = await basicFetch(url);

  if (response.data && response.data[0]) {
    misc = formatContent(response.data[0]);
  }

  const metadata = response.data[0].metatags;
  return { misc, metadata };
};

const formatContent = (content) => {
  let data = {};
  if (
    content &&
    content.content_sections &&
    content.content_sections.data_struct_items
  ) {
    content.content_sections.data_struct_items.forEach((datum) => {
      data[datum.attribute_name] = datum.attribute_value;
    });
  }

  return data;
};
