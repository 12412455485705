import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
} from "../../actions";
import Loading from "../../components/Loading/Loading";
import "./ContactPage.scss";
//import * as Sentry from "@sentry/browser";
import backendURL from "../../utils/helpers/backendURL";
import { removeHiddenFormAndScripts } from "./ContactFormMethods";
import queryString from "query-string";
import fetchContactPageData, {
  fetchProductName,
} from "../../utils/async/fetchContactPageData";
import { MarketoForm } from "luna-one";
import _ from "lodash";

class ContactPage extends Component {
  constructor() {
    super();

    this.state = {
      formReady: false,
      content: null,
      domData: null,
      warnings: [],
      entries: [],
      optin: false,
      categoryPrefilExecuted: false,
      submitting: false,
      debug: false,
      checkBoxLabel: "",
      submissionAgreementText: "",
    };

    this.timeoutID = null;
  }

  componentDidMount() {
    this.checkRouteForCompletion();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  componentWillUnmount() {
    // grabs all the generated marketo scripts and forms and removes them from the DOM so they don't just sit there
    removeHiddenFormAndScripts();
    this.clearSetTimeout();
  }

  clearSetTimeout = () => {
    if (this.timeoutID) {
      window.clearTimeout(this.timeoutID);
    }
  };

  getData = async () => {
    this.setLoading();
    window.prerenderReady = false;

    const url = this.getURL();
    const productName =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.productName
        ? this.props.match.params.productName
        : null;

    try {
      // This only fetches basic label info and not the actual marketo form labels - those are scraped from the DOM
      const {
        content,
        innerPageNavData,
        metadata,
      } = await fetchContactPageData(url, this.props.demo);

      if (productName) {
        // Retrive the product name for use in auto-filling the product field
        const url = this.getProductFetchURL(productName);
        const formattedProductName = await fetchProductName(url);
        this.setState({ content, productName: formattedProductName });
      } else {
        this.setState({ content });
      }
      this.props.setInnerPageNav(innerPageNavData);
      this.props.setMetadata(metadata);
      this.setSuccess();
    } catch (error) {
      this.props.setStatus("error");
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(error);
      // }
      console.log(error);
    }

    this.generateMarketoScript();
  };

  getProductFetchURL = (productName) => {
    return `${backendURL}/${this.getLanguage()}/api-v1/GetNodeByURL/product$${productName}/$`;
  };

  checkRouteForCompletion = () => {
    const values = queryString.parse(this.props.location.search);

    // Evaluate the the pathname for query strings
    if (!values || !values.aliId) {
      // Build the Marketo form if query strings for first/last name are missing
      this.getData();
    } else {
      // Push the user to the 'submitted' page if query strings are present
      const location = this.props.location.pathname;
      const lastChar = location[location.length - 1];
      // Add a forward slash if necessary
      if (lastChar === "/") {
        this.props.history.push(`${location}success`);
      } else {
        this.props.history.push(`${location}/success`);
      }
    }
  };

  getURL = () => {
    return `${backendURL}/${this.getLanguage()}/api-v1/GetNodeByURL/contact/$`;
  };

  getLanguage = () => {
    return this.props.language || "en";
  };

  generateMarketoScript = () => {
    // This generates the Marketo script, appends it to the DOM, and calls the function that generates the cooresponding <form>
    const script = document.createElement("script");

    script.src = "//app-ab13.marketo.com/js/forms2/js/forms2.min.js";
    script.async = true;
    script.defer = true;
    script.type = "text/javascript";
    script.id = "mktoScript_big";
    document.body.appendChild(script);

    this.callScript();
  };

  postScript = () => {
    const script = document.createElement("script");
    script.id = "mktoForm_script_caller";
    script.text =
      'MktoForms2.loadForm("//app-ab13.marketo.com", "593-HFN-635", 6083);';
    document.body.appendChild(script);

    this.setState({ formReady: true });
    this.setSuccess();
    this.scrapeDOM();
  };

  callScript = () => {
    // Check to see if the script is ready - call a method on it if it is, or call this method again if it isn't after waiting .2 seconds

    if (window.MktoForms2 && window.MktoForms2.loadForm) {
      setTimeout(this.postScript, 3000);
    } else {
      setTimeout(this.callScript, 200);
    }
  };

  scrapeDOM = () => {
    // pull all labels and fields from the DOM and check for length
    // If there is no length, the DOM isn't ready, wait .2 seconds and try again
    // If length, format the data

    const labelData = document.getElementsByClassName("mktoLabel");
    const inputData = document.getElementsByClassName("mktoField");
    // the checkbox label does not have a class name of mktoLabel or mktoField, which is why it was not rendering. Below I am grabbing that field by its unique ID
    const checkBoxLabelData = document.getElementById(
      "LblmktoCheckbox_152487_0"
    );

    const textLabel = document.getElementsByClassName(
      "mktoHtmlText mktoHasWidth"
    );

    if (textLabel[0]) {
      this.setState({ submissionAgreementText: textLabel[0]?.innerHTML });
    }
    if (checkBoxLabelData) {
      this.setState({ checkboxLabel: checkBoxLabelData.innerHTML });
    }
    if (!labelData || labelData.length === 0) {
      setTimeout(this.scrapeDOM, 200);
    } else {
      this.formatLabelData(labelData, inputData);
    }
  };

  formatLabelData = (labelData, inputData) => {
    // Format the data specifically for the input fields first
    const formattedInputData = this.formatInputData(inputData);

    let elements = [];

    // format the label data second, using the labels to correspond with the formattedInputData names to get type and options (if applicable)
    for (let i = 0; i < labelData.length; i++) {
      // labels have a bunch of weird stuff in their strings, like line breaks, asterisks, and colons

      const mockLabel = labelData[i].innerHTML
        ? labelData[i].innerHTML
            .replace('<div class="mktoAsterix">', "")
            .replace("</div>", "")
            .replace(":", "")
            .split("*")
            .join("")
        : null;

      // find the corresponding input to the label
      const input = formattedInputData.find((input) => {
        return (
          input.name &&
          input.name.toLowerCase &&
          labelData[i] &&
          labelData[i].htmlFor &&
          labelData[i].htmlFor.toLowerCase &&
          input.name.toLowerCase() === labelData[i].htmlFor.toLowerCase()
        );
      });

      const elementData = {
        label: mockLabel ? mockLabel : this.state.checkboxLabel,
        for: labelData[i].htmlFor,
        checkBox: labelData[i].htmlFor === "expressOptInForm" ? true : false,
        type: input && input.type ? input.type : undefined,
        options: input && input.options ? input.options : undefined,
        required:
          input.name.toLowerCase() === "postalcode"
            ? true
            : input.name.toLowerCase() === "state"
            ? true
            : input.required,
        email: input && input.type === "email" ? true : false,
      };

      elements.push(elementData);
    }

    this.setDomData(elements);
  };

  formatInputData = (data) => {
    let elements = [];

    for (let i = 0; i < data.length; i++) {
      // filter out hidden forms
      if (data[i].type !== "hidden") {
        const elementData = {
          name: data[i].name,
          type: data[i].type,
          // determine if there are options for a dropdown (country/state)
          options: this.formatInputOptionsData(data[i].options),
          required:
            data[i].classList && data[i].classList.contains("mktoRequired")
              ? true
              : false,
        };
        elements.push(elementData);
      }
    }

    return elements;
  };

  formatInputOptionsData = (data) => {
    if (!data) {
      return undefined;
    }

    const options = [];

    for (let i = 0; i < data.length; i++) {
      const option = {
        // the 'value' refers to the two-letter country/state code that you'll need for selecting the corresponding country/state from the options list - the 'name' won't work for this - name is only for display purposes - thanks HTML!
        value: data[i].value === "" ? null : data[i].value,
        name: data[i].innerText,
      };

      options.push(option);
    }

    if (options[0] && options[0].value) {
      const selectOption = {
        value: "",
        name:
          this.state.content && this.state.content.selectText
            ? this.state.content.selectText
            : "Select",
      };

      options.unshift(selectOption);
    }

    return options;
  };

  checkForRequirement = (string, forField) => {
    // checks for a asterisk to determine if the field is required

    if (
      (string.includes && string.includes("*")) ||
      (forField.toLowerCase && forField.toLowerCase() === "state")
    ) {
      return true;
    } else {
      return false;
    }
  };

  setDomData = (domData) => {
    this.setState({ domData }, () => {
      // Once domData is scrapped and put into state, start the prefil process
      this.prefilProductCategory();
    });
  };

  renderInputs = () => {
    const inputs = this.state.domData || [];
    const formattedInputs = inputs
      .map((input) => {
        return {
          name: input.label,
          type: this.getInputType(input.type),
          options: this.generateOptions(input.options),
          defaultText: this.generateDefaultText(input.options),
          required: this.checkRequirement(input),
          for: input.for,
        };
      })
      // checkboxes are not a part of the normal object for inputs, they're their own little thing that gets passed as their own prop
      .filter((input) => input.type !== "checkbox")
      // Remove the product inputcheckout - they are autofilled
      .filter((input) => input.for !== "Opportunity_Product__c")
      // Only display postal code if the selected country is France
      .filter((input) => {
        const countrySelection = this.getCountrySelection();

        if (countrySelection && this.checkForFrance(countrySelection.value)) {
          return input;
        } else {
          return (
            input.for &&
            input.for.toLowerCase &&
            input.for.toLowerCase() !== "postalcode"
          );
        }
      });

    return formattedInputs;
  };

  checkRequirement = (input = {}) => {
    // This method is designed specifically to check for the combination of a French country selection and that the corresponding input is for Postal Code, in which case it should return true
    const countrySelection = this.getCountrySelection();
    if (
      countrySelection &&
      this.checkForFrance(countrySelection.value) &&
      this.checkForPostalCodeInput(input)
    ) {
      return true;
    } else {
      return input.required || false;
    }
  };

  getCountrySelection = () => {
    const { entries = [] } = this.state;
    return entries.find((entry) => {
      return (
        entry.for &&
        entry.for.toLowerCase &&
        entry.for.toLowerCase() === "country"
      );
    });
  };

  checkForFrance = (value = "") => {
    if (_.isString(value) && value.toLowerCase() === "france") {
      return true;
    } else {
      return false;
    }
  };

  checkForPostalCodeInput = (input = {}) => {
    if (
      input.for &&
      input.for.toLowerCase &&
      input.for.toLowerCase() === "postalcode"
    ) {
      return true;
    } else {
      return false;
    }
  };

  getInputType = (type) => {
    if (type === "text") {
      return type;
    } else if (type === "select-one") {
      // marketo refers to selects/dropdowns as 'select-one' ¯\(°_o)/¯
      return "dropdown";
    } else if (type === "textarea") {
      return "text area";
    } else if (type === "checkbox") {
      return "checkbox";
    } else {
      // used to catch email and phone
      return "text";
    }
  };

  generateOptions = (options) => {
    // Generates the lsit of dropdown options
    if (!options) {
      return null;
    } else {
      return options.map((option) => option.name);
    }
  };

  generateDefaultText = (options) => {
    // Generates the default text that appears in a dropdown without a current selection
    if (options && options[0] && options[0].name) {
      return options[0].name;
    } else {
      return null;
    }
  };

  renderCheckbox = () => {
    const { domData } = this.state;
    const checkBoxData = domData.find((point) => {
      return point.type === "checkbox";
    });
    const checkbox = {
      label: checkBoxData && checkBoxData.label ? checkBoxData.label : "",
      optin: this.state.optin,
      name:
        checkBoxData && checkBoxData.for
          ? checkBoxData.for
          : "expressOptInForm",
    };

    return checkbox;
  };

  handleSelect = (selection, name) => {
    // Handles selection for a dropdown option
    const { domData = [], content = {} } = this.state;

    const input = domData.find((input) => {
      return input.label && input.label === name;
    });

    if (content.selectText && selection === content.selectText) {
      // determines if the the selection is the default 'select' text so that it can be filtered out
      this.removeCountrySelection(selection, name, input);
    } else if (
      input &&
      input.for &&
      input.for.toLowerCase &&
      input.for.toLowerCase() === "country"
    ) {
      // determines if the new selection is a country selection so that the state/region selection can be filtered out
      this.clearStateSelectionFromEntries(selection, name, input);
    } else {
      this.processSelection(selection, name, input);
    }
  };

  removeCountrySelection = (selection, name, input = {}) => {
    const newEntries = [...this.state.entries];
    if (input.for.toLowerCase() === "country") {
      // remove both state and country selections
      const entries = newEntries
        .filter(
          (entry) =>
            entry.for &&
            entry.for.toLowerCase &&
            entry.for.toLowerCase() !== "state"
        )
        .filter(
          (entry) =>
            entry.for &&
            entry.for.toLowerCase &&
            entry.for.toLowerCase() !== "country"
        );
      this.setState({ entries }, () => this.updateHiddenDOM(name, selection));
    } else {
      // remove non-country selection (state/region)
      const entries = newEntries.filter(
        (entry) =>
          entry.for &&
          entry.for.toLowerCase &&
          input.for &&
          input.for.toLowerCase &&
          entry.for.toLowerCase() !== input.for.toLowerCase()
      );
      this.setState({ entries }, () => this.updateHiddenDOM(name, selection));
    }
  };

  clearStateSelectionFromEntries = (selection, name, input) => {
    // Remove the state/region selection from entries if necessary, usually a biproduct of when the country selection changes
    const newEntries = [...this.state.entries];

    const stateEntry = newEntries.find(
      (entry) =>
        entry.for &&
        entry.for.toLowerCase &&
        entry.for.toLowerCase() === "state"
    );

    if (stateEntry) {
      const entries = newEntries.filter(
        (entry) =>
          entry.for &&
          entry.for.toLowerCase &&
          entry.for.toLowerCase() !== "state"
      );
      this.setState({ entries }, () => {
        this.processSelection(selection, name, input);
      });
    } else {
      this.processSelection(selection, name, input);
    }
  };

  processSelection = (selection, name, input) => {
    const newEntries = [...this.state.entries];
    const entry = newEntries.find((entry) => {
      return entry.name && entry.name === name;
    });

    if (entry) {
      entry.value = selection;
      this.updateEntries(newEntries);
    } else {
      if (input.type === "select-one") {
        this.updateEntries([
          ...this.state.entries,
          {
            name: name,
            value: selection,
            for: input.for,
          },
        ]);
      } else {
        this.updateEntries([
          ...this.state.entries,
          {
            name: name,
            value: selection,
            for: input.for,
          },
        ]);
      }
    }

    this.updateHiddenDOM(name, selection);
  };

  handleChange = (e) => {
    const targetName = e.target && e.target.name ? e.target.name : null;
    const targetValue = e.target && e.target.value ? e.target.value : null;
    // Update text inputs in state
    const { domData = [] } = this.state;
    const newEntries = [...this.state.entries];
    const entry = newEntries.find((entry) => {
      return entry.name === targetName;
    });

    // The inputs are generated progromatically and are not hard-coded, and so they live inside an array

    if (entry) {
      entry.value = targetValue;
      this.updateEntries(newEntries);
    } else {
      const input = domData.find((input) => {
        return input.label === targetName;
      });

      this.updateEntries([
        ...this.state.entries,
        {
          name: targetName,
          value: targetValue,
          for: input.for,
        },
      ]);
    }

    this.updateHiddenDOM(e.target.name, targetValue);
  };

  handleCheckboxChange = (e) => {
    const checked = e.target && e.target.checked ? e.target.checked : null;
    this.setState({ optin: checked });

    const hiddenCheckbox = document.getElementById("expressOptInForm");

    if (hiddenCheckbox) {
      hiddenCheckbox.checked = checked;
    }
  };

  updateHiddenDOM = (name, value) => {
    const { domData = [] } = this.state;
    // This updates the hidden marketo form in real time
    const correspondingItem = domData.find((item) => {
      return item.label && item.label === name;
    });

    if (correspondingItem) {
      const domElement = document.getElementById(correspondingItem.for);

      if (domElement) {
        if (
          correspondingItem.type &&
          correspondingItem.type.toLowerCase &&
          correspondingItem.type.toLowerCase() !== "select-one"
        ) {
          domElement.value = value;
        } else if (correspondingItem.options && value) {
          const option = correspondingItem.options.find((option) => {
            const formattedValue = value.replace("-", " ").toLowerCase();
            return option.name && option.name.toLowerCase() === formattedValue;
          });

          if (domElement && option && option.value) {
            domElement.value = option.value;
          }

          // dispatchEvent is necessary to trick the Marketo form into thinking that the change happened, otherwise we don't get states/regions.
          // TODO ContactPage - dispatchEvent is not supported by IE11 - will require a shim

          // Using a conditional to check for the presence of dispatchEvent() was giving false negatives (don't know why, this is all alchemy), so I'm wrapping it in a try/catch instead. This feels hacky, but I don't want to risk a white screen.

          try {
            domElement.dispatchEvent(new Event("change"));
          } catch (e) {
            console.log(
              e,
              `dispatch failed on ${
                option && option.value ? option.value : "select"
              }`
            );
          }
        }
      }
    }

    // It's necessary to rescrape the DOM after updates as some selections can generate new options, like certain countries having states/regions
    this.scrapeDOM();
  };

  updateEntries = (entries) => {
    // Takes in the new entries array and sets it into state
    this.setState({ entries });
  };

  handleSubmit = () => {
    // Check to see if any required inputs/dropdowns are missing input/selection
    const entries = this.state.entries || [];
    const isFrance = entries.find((entry) => {
      return this.checkForFrance(entry.value);
    });

    const warnings = this.state.domData.reduce((accu, input) => {
      if (input.required) {
        const match = this.state.entries.find((entry) => {
          return (
            entry.for &&
            entry.for.toLowerCase &&
            input.for &&
            input.for.toLowerCase &&
            entry.for.toLowerCase() === input.for.toLowerCase()
          );
        });

        if (input.email && match) {
          const validatedEmail = this.validateEmail(match.value);
          if (!validatedEmail) {
            accu.push(input.label);
          }
        }
        if (!match) {
          if (
            // opportunity_product__c is a hidden field that the user cannot interact with - we shouldn't create warnings for it
            input &&
            input.for &&
            input.for.toLowerCase() === "opportunity_product__c"
          ) {
            return accu;
          } else {
            if (input.label.toLowerCase() === "postal code" && !isFrance) {
              return accu;
            } else {
              accu.push(input.label);
            }
          }
        }
      }
      return accu;
    }, []);

    // Pass references to required inputs with missing fields into state and return
    if (warnings.length > 0) {
      this.updateWarnings(warnings);
      return;
    }

    this.submitMarketoForm();
  };

  validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  updateWarnings = (warnings) => {
    // Set required forms with missing data into state to warn the user when they have left the field empty
    this.setState({ warnings });
  };

  submitMarketoForm = () => {
    const submitBtn = document.querySelector(".mktoButton");

    if (submitBtn) {
      this.setSubmitting(true);
      submitBtn.click();
      this.timeoutID = setTimeout(this.revealForm, 5000);
    }
  };

  setSubmitting = () => {
    this.setState({ submitting: true });
  };

  revealForm = () => {
    this.setState({ debug: true });
    this.timeoutID = null;
  };

  prefilProductCategory = () => {
    // Prefil the Product Category Input

    // Determine if Prefil has happened and return if so
    const { categoryPrefilExecuted } = this.state;

    if (categoryPrefilExecuted) {
      this.prefilOpportunityProduct();
      return;
    }

    // Get the category name out of the path
    const { match } = this.props;
    let productCategoryFromPath =
      match && match.params && match.params.category
        ? match.params.category.replace("-visualization", "")
        : null;

    // For contact sales page set mixed-reality
    if (this.props.contactSales) {
      productCategoryFromPath = "mixed-reality";
    }

    // If there is a category name in the path, find the corresponding input for the product category from the Marketo data
    if (productCategoryFromPath) {
      const productCategoryData = this.state.domData.find(
        (point = {}) => point.for === "Product_Category__c"
      );

      // If th ecorresponding Marketo input is found, generate a mock event to feed to handleChange()
      if (productCategoryData) {
        const mockEvent = {
          target: {
            name: productCategoryData.label,
            value: this.capitalize(productCategoryFromPath).replace("-", " "),
            // ^^^ This is safe; this.capitalize will always return a string
          },
        };

        // Set categoryPrefilExecuted to 'true' to avoid stack overflow and call handleChange()
        this.setState({ categoryPrefilExecuted: true }, () =>
          this.handleChange(mockEvent)
        );
      } else {
        // Don't need to prefil
        this.setState({ categoryPrefilExecuted: true });

        return;
      }
    } else {
      // Don't need to prefil
      this.setState({ categoryPrefilExecuted: true });

      return;
    }
  };

  prefilOpportunityProduct = () => {
    if (this.state.productPrefilExecuted) {
      return;
    }

    if (this.state.productName) {
      const opportunityProductData = this.state.domData.find(
        (point = {}) => point.for === "Opportunity_Product__c"
      );

      if (opportunityProductData) {
        const mockEvent = {
          target: {
            name: opportunityProductData.label,
            value: this.state.productName,
          },
        };

        // Set productPrefilExecuted to 'true' to avoid stack overflow and call handleChange()
        this.setState({ productPrefilExecuted: true }, () =>
          this.handleChange(mockEvent)
        );
      } else {
        // Don't need to prefil
        this.setState({ productPrefilExecuted: true });
        return;
      }
    }

    this.setState({ productPrefilExecuted: true });
  };

  capitalize = (s) => {
    if (s && typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  getWarningText = () => {
    const { content } = this.state;

    if (content && content.warningText) {
      return content.warningText;
    } else {
      return "There are required fields that are incomplete.";
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };
  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  render() {
    const { formReady, content } = this.state;
    return (
      <main
        className={`ftg-contact-page ${
          this.props.status === "loading" || !formReady
            ? "ftg-contact-page--loading"
            : "ftg-contact-page--default"
        }`}
      >
        {formReady && (
          <>
            {content &&
              content.header &&
              !this.props.demo &&
              !this.props.contactSales && (
                <p className="ftg-contact-page__header">{content.header}</p>
              )}
            {content && content.demoPageHeader && this.props.demo && (
              <p className="ftg-contact-page__header">
                {content.demoPageHeader}
              </p>
            )}
            {content && content.demoPageHeader && this.props.contactSales && (
              <p className="ftg-contact-page__header">
                Request Sales Contact for Trimble Connect AR
              </p>
            )}
            {this.state.domData && (
              <MarketoForm
                submitting={this.state.submitting}
                inputs={this.renderInputs()}
                handleSelect={this.handleSelect}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                entries={this.state.entries}
                warnings={this.state.warnings}
                submitButtonText={
                  this.state.content && this.state.content.submitText
                    ? this.state.content.submitText
                    : "Submit"
                }
                checkbox={this.renderCheckbox()}
                handleCheckboxChange={this.handleCheckboxChange}
                warningText={this.getWarningText()}
                submittingText={
                  this.state.debug ? "Something went wrong..." : "Submitting..."
                }
              />
            )}
            <p
              className="ftg-contact-page__submission-text"
              dangerouslySetInnerHTML={{
                __html: this.state.submissionAgreementText,
              }}
            ></p>
            <form
              className={`ftg-contact-page__form${
                this.state.debug ? "--debug" : ""
              }`}
              id="mktoForm_6083"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            />
          </>
        )}
        {!formReady && <Loading />}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  region: state.region,
  industries: state.industries,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(updateStatus(status)),
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
  setMetadata: (metadata) => dispatch(updateMetadata(metadata)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
