import React, { Component } from "react";
import { connect } from "react-redux";
import { FilterHero, EventCard } from "luna-one";
import "./EventsPage.scss";
import basicFetch from "../../utils/async/basicFetch";
import _ from "lodash";
import * as moment from "moment";
import { countryCodes } from "./EventPageMethods";
import { updateStatus, updateInnerPageNav } from "../../actions";
import fetchEventMiscData from "../../utils/async/fetchEventMiscData";
import ErrorPage from "../ErrorPage/ErrorPage";
import Loading from "../../components/Loading/Loading";
import backendURL from "../../utils/helpers/backendURL";
import fpoBox from "../../assets/images/fpoBox.png";
//import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";

class EventsPage extends Component {
  constructor() {
    super();
    this.state = {
      dropdowns: [],
      header: null,
      text: null,
      allContent: [],
      industry: null,
      type: null,
      year: null,
      region: null,
    };
  }

  componentDidMount() {
    this.getEventData();
    this.filterEvents();
  }

  getLanguage = () => {
    return `${this.props.language || "en"}/`;
  };

  handleDropdownSelect = (selection, name) => {
    const nameLowerCase =
      name && name.toLowerCase ? name.toLowerCase() : undefined;

    const { dropdowns = [] } = this.state;

    //. Capture the first array index and use it to compare to the response for language-agnoistic 'ALL' options
    let dropdown = dropdowns.find((dropdown) => {
      return (
        dropdown.name &&
        dropdown.name.toLowerCase &&
        dropdown.name.toLowerCase() === nameLowerCase
      );
    });

    const machineName = dropdown.machineName.toLowerCase();
    this.setState({
      [machineName]:
        dropdown.options &&
        dropdown.options[0] &&
        selection === dropdown.options[0]
          ? null
          : selection,
    });
  };

  regionalContentFilter = () => {
    const { allContent = [] } = this.state;
    let regionalContent = allContent.filter((event) => {
      // if statement is necessary in case there are null regions
      return (
        (event.regionText &&
          event.regionText.length === 1 &&
          event.regionText[0] === this.state.region) ||
        (event.regionText &&
          event.regionText.length > 1 &&
          event.regionText.find((event) => {
            return event === this.state.region;
          })) ||
        // this is if there are NO regions, we want to display it in ALL regions
        !event.regionText ||
        !event.region
      );
    });
    return regionalContent;
  };

  industryFilter = () => {
    let industry = this.state.allContent.filter((event = {}) => {
      return event.industry[0] === this.state.industry;
    });
    return industry;
  };

  typeFilter = () => {
    let type = this.state.allContent.filter((event = {}) => {
      return event.industryLabel && event.industryLabel[0] === this.state.type;
    });
    return type;
  };

  filterEvents = () => {
    // filter by industry and type only
    if (
      this.state.industry &&
      this.state.type &&
      !this.state.year &&
      !this.state.region
    ) {
      return this.industryFilter().filter((event = {}) => {
        return event.industryLabel[0] === this.state.type;
      });
      // filter by industry, type, and year only
    } else if (
      this.state.industry &&
      this.state.type &&
      this.state.year &&
      !this.state.region
    ) {
      return this.industryFilter()
        .filter((event = {}) => {
          return event.industryLabel[0] === this.state.type;
        })
        .filter((event = {}) => {
          return event.year === this.state.year;
        });
      // filter by industry only
    } else if (this.state.industry && !this.state.year && !this.state.region) {
      return this.industryFilter();
      // filter by industry and year only
    } else if (this.state.industry && this.state.year && !this.state.region) {
      return this.industryFilter().filter((event) => {
        return event.year === this.state.year;
      });
      // filter by type only
    } else if (this.state.type && !this.state.year && !this.state.region) {
      return this.typeFilter();
      // filter by type and year only
    } else if (this.state.type && this.state.year && !this.state.region) {
      return this.typeFilter().filter((event) => {
        return event.year === this.state.year;
      });
      // filter by year only
    } else if (
      !this.state.industry &&
      !this.state.type &&
      this.state.year &&
      !this.state.region
    ) {
      return this.state.allContent.filter((event) => {
        return event.year === this.state.year;
      });
      // filter by industry, type, and region only
    } else if (
      this.state.industry &&
      this.state.type &&
      !this.state.year &&
      this.state.region
    ) {
      let regionalContent = this.regionalContentFilter();
      return regionalContent
        .filter((event = {}) => {
          return event.industryLabel[0] === this.state.type;
        })

        .filter((event = {}) => {
          return event.industry[0] === this.state.industry;
        });
      // filter by region only
    } else if (
      !this.state.industry &&
      !this.state.type &&
      !this.state.year &&
      this.state.region
    ) {
      return this.regionalContentFilter();
      // filter by type and region only
    } else if (
      !this.state.industry &&
      this.state.type &&
      !this.state.year &&
      this.state.region
    ) {
      let regionalContent = this.regionalContentFilter();

      return regionalContent.filter((event = {}) => {
        return event.industryLabel[0] === this.state.type;
      });
      // filter by industry and region only
    } else if (
      this.state.industry &&
      !this.state.type &&
      !this.state.year &&
      this.state.region
    ) {
      let regionalContent = this.regionalContentFilter();

      return regionalContent.filter((event = {}) => {
        return event.industry[0] === this.state.industry;
      });
      // filter by year and region only
    } else if (
      !this.state.industry &&
      !this.state.type &&
      this.state.year &&
      this.state.region
    ) {
      let regionalContent = this.regionalContentFilter();
      return regionalContent.filter((event = {}) => {
        return event.year === this.state.year;
      });
      // filter by industry, year, and region only
    } else if (
      this.state.industry &&
      !this.state.type &&
      this.state.year &&
      this.state.region
    ) {
      let regionalContent = this.regionalContentFilter();
      return regionalContent
        .filter((event = {}) => {
          return event.industry[0] === this.state.industry;
        })
        .filter((event = {}) => {
          return event.year === this.state.year;
        });
      // filter by type, region, and year only
    } else if (
      !this.state.industry &&
      this.state.type &&
      this.state.year &&
      this.state.region
    ) {
      let regionalContent = this.regionalContentFilter();
      return regionalContent
        .filter((event = {}) => {
          return event.industryLabel[0] === this.state.type;
        })
        .filter((event = {}) => {
          return event.year === this.state.year;
        });
      // filter by industry, type, year, and region
    } else if (
      this.state.industry &&
      this.state.type &&
      this.state.year &&
      this.state.region
    ) {
      let regionalContent = this.regionalContentFilter();
      return regionalContent
        .filter((event = {}) => {
          return (
            event.industryLabel && event.industryLabel[0] === this.state.type
          );
        })
        .filter((event = {}) => {
          return event.year === this.state.year;
        })
        .filter((event = {}) => {
          return event.industry && event.industry[0] === this.state.industry;
        });
    } else {
      return this.state.allContent;
    }
  };

  getEventMiscData = async () => {
    try {
      const response = await fetchEventMiscData(
        `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/event-page-miscellaneous-content/$`
      );

      this.setState({
        header: response.header,
        text: response.text,
        noResults: response.noResultsFound,
      });
      return response;
    } catch (error) {
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(error);
      // }
      console.log(error);
    }
  };

  getEventData = async () => {
    this.setLoading();

    // get the current year and set it into state so that the year selected is always the current year.
    const currentYear = JSON.stringify(new Date().getFullYear());
    this.setState({ year: currentYear });

    try {
      const response = await basicFetch(
        `${backendURL}/${this.getLanguage()}api-v1/FetchAllNodesByType/event`
      );
      let defaultData = await this.getEventMiscData();
      const innerPageNavData = {
        pageTitle: defaultData.header,
        links: [],
      };
      this.props.setInnerPageNav(innerPageNavData);
      const eventData = this.formatEventCardData(response.data.nodes);
      this.setState({ allContent: eventData });
      const filterHeroDropdowns = this.formatEventDropDowns(
        eventData,
        defaultData
      );
      this.setState({ dropdowns: filterHeroDropdowns });
      this.setSuccess();
    } catch (error) {
      this.setError();
      console.log(error);
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(error);
      // }
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  formatEventCardData = (eventData = []) => {
    const content = eventData.map((event = {}) => {
      return {
        date:
          `${moment.unix(event.event_start).format("MMMM Do")} - ${moment
            .unix(event.event_end)
            .format("MMMM Do")}` || null,
        year: event.event_year || null,
        image: {
          url:
            event.event_image && event.event_image.source
              ? event.event_image.source
              : fpoBox,
        },
        industry: event.event_industry || null,
        industryLabel: event.event_type || null,
        name: event.event_title || null,
        description: event.event_description || null,
        // location: event.event_location || null,
        link: { url: event.event_link },
        region: event.event_region || null,
        regionText: countryCodes(event.event_region),
      };
    });
    return content;
  };

  formatEventDropDowns = (eventData, defaultData) => {
    const oneOptions = eventData
      .filter((event) => {
        // Having some issues with event.industry[0] coming back null - need to filter it.
        return event.industry && event.industry[0];
      })
      .map((event = {}) => {
        return event.industry[0];
      });
    const twoOptions = this.props.regions.map((region = {}) => {
      return region.name;
    });

    const threeOptions = eventData.map((event = {}) => {
      return event.industryLabel[0];
    });

    oneOptions.unshift(defaultData.defaultText);
    twoOptions.unshift(defaultData.defaultText);
    threeOptions.unshift(defaultData.defaultText);

    const dropdownOne = {
      options: _.uniq(oneOptions),
      defaultText: defaultData.defaultText,
      name: defaultData.industryLabel,
      machineName: "industry",
    };

    const dropdownThree = {
      options: _.uniq(threeOptions),
      defaultText: defaultData.defaultText,
      name: defaultData.typeLabel,
      machineName: "type",
    };

    const dropdownTwo = {
      options: _.uniq(twoOptions),
      defaultText: defaultData.defaultText,
      name: defaultData.regionLabel,
      machineName: "region",
    };

    const dropdowns = [dropdownOne, dropdownTwo, dropdownThree];
    return dropdowns;
  };

  renderEvents = () => {
    let eventList = this.filterEvents();
    if (eventList.length) {
      return eventList.map((data, index) => {
        return <EventCard key={index} content={data} />;
      });
    } else {
      return (
        <p className="no-events">
          {this.state.noResults || "No results to show"}
        </p>
      );
    }
  };

  renderDates = (date = [], index) => {
    if (date.length) {
      let years = [];
      date.forEach((year) => {
        years.push(year.year);
      });
      years = _.uniq(years).sort();
      return years.map((year, index) => {
        if (year && year.length) {
          return (
            <p
              key={index}
              onClick={() => {
                this.setState({
                  year: year,
                });
              }}
              style={
                this.state.year === year
                  ? { fontWeight: "900" }
                  : { fontWeight: "normal" }
              }
              className={`${
                index === year.length - 1 ? "year-link no-border" : "year-link"
              }`}
            >
              {year}
            </p>
          );
        }
      });
    }
  };

  render() {
    const { status } = this.props;
    return (
      <div className="events-page">
        {status === "loading" && <Loading />}
        {status === "error" && (
          <ErrorPage type="500" history={this.props.history} />
        )}
        {status === "success" && (
          <div className="events-page">
            <FilterHero
              theme="brand-very-dark"
              handleDropdownSelect={
                this.handleDropdownSelect ? this.handleDropdownSelect : null
              }
              header={this.state.header ? this.state.header : null}
              text={this.state.text ? this.state.text : null}
              dropdowns={this.state.dropdowns ? this.state.dropdowns : null}
            />
            <div className="event-card-container">
              {this.state && this.state.allContent && (
                <div className="date-container">
                  {this.renderDates(this.state.allContent)}
                </div>
              )}
              <div className="event-cards">{this.renderEvents()}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(updateStatus(status)),
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsPage);

EventsPage.propTypes = {
  status: PropTypes.string,
  setInnerPageNav: PropTypes.func,
  setStatus: PropTypes.func,
  setMetadata: PropTypes.func,
  region: PropTypes.string,
  regions: PropTypes.array,
};
