import React, { Component } from "react";
import { Card } from "terra-component-lib";
import { FilterHero } from "luna-one";
import { connect } from "react-redux";
import "./AllProductsPage.scss";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
} from "../../actions";
import backendURL from "../../utils/helpers/backendURL";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";
import fetchAllProducts from "../../utils/async/fetchAllProducts";
import fetchAllProdMiscData from "../../utils/async/fetchAllProdMiscData";
//import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";

// TODO Add this page as a link in megaMenu

class AllProductsPage extends Component {
  constructor() {
    super();

    this.state = {
      allProducts: null,
      filterHeroDataURL: null,
      hardwareList: null,
      softwareList: null,
      category: null,
      phase: null,
      productList: null,
      defaultContent: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getLanguage = () => {
    return `${this.props.language || "en"}/`;
  };

  handleDropdownSelect = (selection, name) => {
    let dropdowns = this.formatDropdowns(selection, name);
    const nameLowerCase = name && name.toLowerCase ? name.toLowerCase() : null;
    let dropdown = dropdowns.find((dropdown) => {
      return (
        dropdown.name &&
        dropdown.name.toLowerCase &&
        dropdown.name.toLowerCase() === nameLowerCase
      );
    });
    const machineName = dropdown.machineName.toLowerCase();

    this.setState({
      [machineName]:
        dropdown.options &&
        dropdown.options[0] &&
        selection === dropdown.options[0]
          ? null
          : selection,
    });
  };

  getData = async () => {
    this.setLoading();
    const url = this.getUrl();
    try {
      const { content } = await fetchAllProducts(url);

      const { misc, metadata } = await fetchAllProdMiscData(
        `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/data-structure$all-products-miscellaneous-content/$`
      );

      const innerPageNavData = {
        pageTitle: misc.header,
        links: [],
      };

      this.props.setMetadata(metadata);
      this.props.setInnerPageNav(innerPageNavData);
      const productList = content.reduce((accu, prod) => {
        if (prod && prod.type === "product_hardware_page") {
          accu.push({
            header: prod.header,
            text: prod.text,
            image: prod.image,
            button: prod.button,
            type: prod.type,
            category: misc.hardware,
          });
        } else if (prod && prod.type === "product_software_page") {
          accu.push({
            header: prod.header,
            text: prod.text,
            image: prod.image,
            button: prod.button,
            type: prod.type,
            category: misc.software,
          });
        }

        return accu;
      }, []);

      this.setState({
        productList,
        header: misc.header,
        text: misc.description,
        defaultText: misc.defaultText,
        hardwareLabel: misc.hardware,
        softwareLabel: misc.software,
        phaseLabel: misc.phase_dropdown_title,
        categoryLabel: misc.category_dropdown_title,
      });
      this.formatDropdowns();
      this.setSuccess();
    } catch (error) {
      console.log(error);
      this.setError();
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(error);
      // }
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  getUrl = () => {
    return `${backendURL}/${this.getLanguage()}api-v1/FetchAllNodesByTypes/product_software_page$product_hardware_page/$`;
  };

  formatDropdowns = (selection, name) => {
    const productList = this.state.productList || [];

    const optionsOne = productList.map((product) => {
      return product.header;
    });

    optionsOne.unshift(this.state.defaultText);
    const dropdownOne = {
      options: optionsOne,
      defaultText: this.state.defaultText,
      name: this.state.phaseLabel || "Products",
      machineName: "phase",
    };

    const dropdownTwo = {
      options: [
        this.state.defaultText,
        this.state.hardwareLabel,
        this.state.softwareLabel,
      ],
      defaultText: this.state.defaultText,
      machineName: "category",
      name: this.state.categoryLabel || "Category",
    };
    let dropdowns = [dropdownOne, dropdownTwo];
    this.filterDropdowns(dropdowns, selection, name);
    this.setState({ dropdowns });
    return dropdowns;
  };

  filterDropdowns = (list, selection, name) => {
    const { productList } = this.state;
    if (productList) {
      if (selection && name) {
        if (selection !== this.state.defaultText) {
          let newDropdownsCat = [];
          productList.forEach((prod) => {
            if (prod && selection === prod.header) {
              newDropdownsCat.push(prod.category);
            } else if (prod && selection === prod.category) {
              newDropdownsCat.push(prod.header);
            }
          });
          newDropdownsCat.unshift(this.state.defaultText);
          let selectedOption;
          list.forEach((dropdown, index) => {
            if (dropdown.name === name) {
              selectedOption = index;
            }
          });
          if (selectedOption === 0) {
            list[1].options = newDropdownsCat;
          } else if (selectedOption === 1) {
            list[0].options = newDropdownsCat;
          }
          return list;
        } else if (selection === this.state.defaultText) return list;
      }
    }
  };

  filterProducts = () => {
    const { productList, phase, category } = this.state;
    if (!phase && !category) {
      return productList;
    } else if (phase && !category) {
      return productList.filter((prod) => {
        return prod.header === phase;
      });
    } else if (!phase && category) {
      return productList.filter((prod) => {
        return prod.category === category;
      });
    } else if (phase && category) {
      return productList
        .filter((prod) => {
          return prod.category === category;
        })
        .filter((prod) => {
          return prod.header === phase;
        });
    }
  };

  renderCards = () => {
    let prodList = this.filterProducts();
    if (prodList) {
      if (prodList.length === 0) {
        const content = {
          text: "Sorry no results found",
        };
        return <Card content={content} />;
      }
      return prodList.map((card, index) => {
        const content = {
          header: card.header,
          text: card.text,
          image: card.image,
          button: card.button,
        };
        return <Card content={content} key={index} />;
      });
    }
  };

  render() {
    const { status } = this.props;
    return (
      <section>
        {status === "loading" && <Loading />}
        {status === "error" && (
          <ErrorPage type="500" history={this.props.history} />
        )}
        {status === "success" && (
          <section className="ftg-all-products-page">
            <FilterHero
              theme="brand-very-dark"
              header={this.state.header || null}
              text={this.state.text || null}
              handleDropdownSelect={this.handleDropdownSelect}
              dropdowns={this.state.dropdowns}
            />
            <div className="ftg-all-products-page__card-container">
              {this.renderCards()}
            </div>
          </section>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  region: state.region,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(updateStatus(status)),
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
  setMetadata: (data) => dispatch(updateMetadata(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllProductsPage);

AllProductsPage.propTypes = {
  status: PropTypes.string,
  language: PropTypes.string,
  setInnerPageNav: PropTypes.func,
  setStatus: PropTypes.func,
  setMetadata: PropTypes.func,
  region: PropTypes.string,
  ignoreLanguage: PropTypes.bool,
};
