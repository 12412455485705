import logoImage from "../../assets/images/trimble-logo.svg";

const navCTA = {
  link: {
    url: "/#",
    text: "Contact Sales",
  },
  className: "ter-button--primary--1",
};

const menuLinkOne = {
  type: "link",
  url: "/",
  text: "Home",
};

const menuLinkTwo = {
  type: "link",
  url: "/",
  text: "All Products",
};

const menuLinkThree = {
  type: "link",
  url: "/events",
  text: "All Events",
};

const languageSelectorData = {
  labels: {
    currentRegion: "Current Region",
    cancel: "Cancel",
    save: "Save",
  },
  regions: [
    {
      region: "North America",
      languages: [
        "EN: English (US)",
        "EN-UK: English (UK)",
        "FR: French",
        "DE: German",
        "NL: Dutch",
      ],
    },
    {
      region: "UK and Middle East",
      languages: [
        "EN: English (US)",
        "EN-UK: English (UK)",
        "FR: French",
        "DE: German",
        "NL: Dutch",
      ],
    },
    {
      region: "France",
      languages: [
        "EN: English (US)",
        "EN-UK: English (UK)",
        "FR: French",
        "DE: German",
        "NL: Dutch",
      ],
    },
    {
      region: "Germany and Austria",
      languages: [
        "EN: English (US)",
        "EN-UK: English (UK)",
        "FR: French",
        "DE: German",
        "NL: Dutch",
      ],
    },
    {
      region: "Switzerland",
      languages: [
        "EN: English (US)",
        "EN-UK: English (UK)",
        "FR: French",
        "DE: German",
        "NL: Dutch",
      ],
    },
  ],
  currentRegionLanguage: {
    region: "UK and Middle East",
    language: "EN-UK",
  },

  activeRegion: {
    region: "UK and Middle East",
    language: "EN: English",
  },

  companyDivision: "FTG",
};

const navOptions = [menuLinkOne, menuLinkTwo, menuLinkThree];
const currentLanguage = { region: "N. America", language: "EN: English" };
const searchSubmit = () => {
  console.log("searching...");
};

const divisionName = "FTG";
const utilityNav = {};
const setLanguage = () => {
  console.log("setting language...");
};
const language = "en";

const rightLinks = [
  {
    url: "/",
    text: "Home",
  },
];

const logo = {
  link: { url: "/" },
  url: logoImage,
  alt: "Trimble logo",
};

export default {
  currentLanguage,
  searchSubmit,
  rightLinks,
  divisionName,
  navCTA,
  navOptions,
  utilityNav,
  setLanguage,
  language,
  languageSelectorData,
  logo,
};
