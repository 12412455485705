import React, { Component } from "react";
import "./ContactSuccessPage.scss";
import fetchContactPageData from "../../utils/async/fetchContactPageData";
//import * as Sentry from "@sentry/browser";
import backendURL from "../../utils/helpers/backendURL";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
} from "../../actions";
import { Hero5 } from "luna-one";

class ContactSuccessPage extends Component {
  constructor() {
    super();

    this.state = {
      content: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getData = async () => {
    this.setLoading();
    const url = this.getURL();

    try {
      const { content } = await fetchContactPageData(url);
      this.setState({ content });
      this.setSuccess();
    } catch (error) {
      console.log(error);
      this.props.setStatus("error");
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(error);
      // }
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };
  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  getURL = () => {
    return `${backendURL}/${this.getLanguage()}/api-v1/GetNodeByURL/contact/$`;
  };

  getLanguage = () => {
    return this.props.language || "en";
  };

  getContent = () => {
    const { content } = this.state || {};

    const header = content.submissionHeader;
    const image = content.submissionImage;
    const text = content.submissionSubHeader;
    const ctas = {
      ctaOne: {
        text: content.homeButtonText,
        url: `/`,
        className: "ter-button--primary--1",
      },
      ctaTwo: {},
    };

    return {
      text,
      header,
      image,
      ctas,
    };
  };

  render() {
    const { status } = this.props;
    const { content } = this.state;
    return (
      <main className="cec-contact-success-page" style={{ marginTop: "140px" }}>
        {status === "success" && content && (
          <Hero5 content={this.getContent()} motion={false} />
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  region: state.region,
  industries: state.industries,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(updateStatus(status)),
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
  setMetadata: (metadata) => dispatch(updateMetadata(metadata)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactSuccessPage);
