import React, { Component } from "react";
import { connect } from "react-redux";
import { updateStatus, updateInnerPageNav } from "../../actions";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";
import { Hero5 } from "luna-one";
import fpoBox from "../../assets/images/fpoBox.png";
import PropTypes from "prop-types";

class RegionBlockPage extends Component {
  nullCheckProps = () => {
    if (this.state.props) {
      return;
    }
    if (!this.state.props) {
      // for the off chance that there are no props.. this should never happen but just in case for null checking purposes.
      const content = {
        header: "This product page is region blocked.",
        text:
          "To view this product, change your region to one where this product is available. ",
        ctas: {
          ctaOne: {
            url: "/",
            text: "Go Home",
            className: "ter-button--primary--1",
          },
          image: {
            url: fpoBox,
            altText: "placeholder image",
          },
        },
      };
      this.setState({ content });
    }
  };

  render() {
    const { status } = this.props;
    return (
      <div className="ftg-region-block-page">
        {status === "loading" && <Loading />}
        {status === "success" && this.props.content && (
          <Hero5
            content={
              this.props.content ? this.props.content : this.state.content
            }
            motion
            type="hero_5"
          />
        )}
        {status === "error" && (
          <ErrorPage type="500" history={this.props.history} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(updateStatus(status)),
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionBlockPage);

RegionBlockPage.propTypes = {
  status: PropTypes.string,
  language: PropTypes.string,
  setInnerPageNav: PropTypes.func,
  setStatus: PropTypes.func,
  region: PropTypes.string,
};
