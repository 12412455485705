import { combineReducers } from "redux";
import { statusReducer } from "./statusReducer";
import { languageReducer } from "./languageReducer";
import { innerPageNavReducer } from "./innerPageNavReducer";
import { metadataReducer } from "./metadataReducer";
import { offsetYReducer } from "./offsetYReducer";
import { regionReducer } from "./regionReducer";
import { languagesReducer } from "./languagesReducer";
import { fiveHundredReducer } from "./fiveHundredReducer";
import { fourOhFourReducer } from "./fourOhFourReducer";
import { categoriesReducer } from "./categoriesReducer";
import { regionsReducer } from "./regionsReducer";
import { learnMoreReducer } from "./learnMoreReducer";

const rootReducer = combineReducers({
  status: statusReducer,
  language: languageReducer,
  languages: languagesReducer,
  innerPageNav: innerPageNavReducer,
  metadata: metadataReducer,
  offsetY: offsetYReducer,
  region: regionReducer,
  fiveHundredData: fiveHundredReducer,
  fourOhFourData: fourOhFourReducer,
  categories: categoriesReducer,
  regions: regionsReducer,
  learnMoreLabel: learnMoreReducer,
});

export default rootReducer;
