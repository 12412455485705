export const handleGetItem = (request, defaultResponse) => {
  try {
    const response = localStorage.getItem(request);

    if (response) {
      return JSON.parse(response) || defaultResponse;
    }
  } catch {
    console.log("localStorage disabled");

    try {
      // sometimes the language code NEEDS to be parsed and other times parsing the language code makes the entire application fail. So a try catch has been added to the catch block.
      return localStorage.getItem(request);
    } catch (e) {
      console.log(e);
    }

    return defaultResponse;
  }
};

export const handleSetItem = (key, request) => {
  try {
    localStorage.setItem(key, JSON.stringify(request));
  } catch {
    console.log("localStorage disabled");
  }
};

export const handleRemoveItem = (request) => {
  try {
    localStorage.removeItem(request);
  } catch {
    console.log("localStorage disabled");
  }
};

export const handleClearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch {
    console.log("localStorage disabled");
  }
};
