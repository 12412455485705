export const updateStatus = (status) => {
  return {
    type: "UPDATE_STATUS",
    status,
  };
};

export const updateLanguage = (language) => {
  return {
    type: "UPDATE_LANGUAGE",
    language,
  };
};

export const updateInnerPageNav = (innerPageNavData) => {
  return {
    type: "UPDATE_INNER_PAGE_NAV",
    innerPageNavData,
  };
};

export const updateMetadata = (metadata) => {
  return {
    type: "UPDATE_METADATA",
    metadata,
  };
};

export const updateOffsetY = (offsetY) => {
  return {
    type: "UPDATE_OFFSET_Y",
    offsetY,
  };
};

export const updateRegion = (region) => {
  return {
    type: "UPDATE_REGION",
    region,
  };
};

export const setLanguageOptions = (languages) => {
  return {
    type: "SET_LANGUAGE_OPTIONS",
    languages,
  };
};

export const setFiveHundredData = (errorData) => {
  return {
    type: "SET_FIVE_HUNDRED_DATA",
    errorData,
  };
};

export const setFourOhFourData = (errorData) => {
  return {
    type: "SET_FOUR_OH_FOUR_DATA",
    errorData,
  };
};

export const setCategories = (categories) => {
  return {
    type: "SET_CATEGORIES",
    categories,
  };
};

export const setRegions = (regions) => {
  return {
    type: "SET_REGIONS",
    regions,
  };
};

export const setLearnMoreLabel = (label) => {
  return {
    type: "SET_LEARN_MORE_LABEL",
    label,
  };
};
