const formatApplicationData = (data) => {
  return {
    divisionName: data.division_name,
    searchPlaceholder: data.search_bar_placeholder,
    rightLinks: data.top_navbar_link || [],
    navCTA: formatNavCTA(data),
    learnMoreLabel: data.learn_more_label,
    demoLabel: data.demo_label,
  };
};

const formatNavCTA = (data) => {
  if (data.utility_nav_link && data.utility_nav_link[0]) {
    return { link: data.utility_nav_link[0] };
  } else {
    return {};
  }
};

export default formatApplicationData;
