import basicFetch from "./basicFetch";

const fetchSupportEmailPage = async (url, type) => {
  const response = await basicFetch(url);

  if (type === "success") {
    const content = formatContent(
      response.data[0].content_sections.data_struct_items
    );
    const innerPageNavData = { pageTitle: content.nonInputs.header, links: [] };
    const metadata = response.data[0].metatags;

    return {
      content: content.nonInputs,
      innerPageNavData,
      metadata,
    };
  } else {
    const content = formatContent(
      response.data[0].content_sections.data_struct_items
    );

    const innerPageNavData = { pageTitle: content.nonInputs.header, links: [] };
    const metadata = response.data[0].metatags;
    return { content, innerPageNavData, metadata };
  }
};

const formatContent = (data) => {
  const inputLabels = data.filter((item) => {
    return item.attribute_name.toLowerCase().includes("inputlabel");
  });

  const nonInputs = data
    .filter((item) => {
      return !item.attribute_name.toLowerCase().includes("inputlabel");
    })
    .map((item) => {
      return {
        [item.attribute_name]: item.attribute_value,
      };
    })
    .reduce((accu, item) => {
      accu = { ...accu, ...item };
      return accu;
    }, {});

  const inputs = inputLabels.map((input) => {
    const machineName = input.attribute_name.replace("InputLabel", "");
    return {
      machineName,
      name: input.attribute_value,
      required: checkRequired(machineName),
      email: machineName === "email" ? true : false,
      type: machineName === "issueDetails" ? "text area" : "text",
    };
  });

  return { inputs, nonInputs };
};

const checkRequired = (machineName) => {
  const required = ["product", "firstName", "lastName", "email"];

  if (required.includes(machineName)) {
    return true;
  } else {
    return false;
  }
};

export default fetchSupportEmailPage;
