import basicFetch from "./basicFetch";
import fpoBox from "../../assets/images/fpoBox.png";

export default async url => {
  const response = await basicFetch(url);
  let data = response.data[0].content_sections.data_struct_items;
  let content = formatContent(data);
  return content;
};

const formatContent = data => {
  const formatKeys = data.map(x => {
    return {
      [x.attribute_name]: x.attribute_value
    };
  });
  let formattedContent = {};

  for (let i in formatKeys) {
    if (formatKeys[i].header) {
      formattedContent["header"] = formatKeys[i].header;
    }

    if (formatKeys[i].subHeader) {
      formattedContent["text"] = formatKeys[i].subHeader;
    }

    if (formatKeys[i].regionBlockImage) {
      formattedContent["image"] = {
        url: formatKeys[i].regionBlockImage.source,
        alText: formatKeys[i].regionBlockImage.alt
      };
    }

    if (!formatKeys[i].regionBlockImage) {
      formattedContent["image"] = { url: fpoBox, alText: "placeholder image" };
    }

    if (formatKeys[i].goHomeButton) {
      formattedContent["ctas"] = {
        ctaOne: { url: "/", text: formatKeys[i].goHomeButton }
      };
    }
    if (formatKeys[i].changeRegionButton) {
      formattedContent["ctas"].ctaTwo = {
        url: "",
        text: formatKeys[i].changeRegionButton
      };
    }
  }
  return formattedContent;
};
