import basicFetch from "./basicFetch";
import backendURL from "../helpers/backendURL";

export default async (lang = "en") => {
  const response = await basicFetch(
    `${backendURL}/${lang}api-v1/GetNodeByURL/cross-browser-banner/$`
  );
  if (
    response &&
    response.data[0] &&
    response.data[0].content_sections &&
    response.data[0].content_sections.data_struct_items
  ) {
    let data = response.data[0].content_sections.data_struct_items;
    let content = formatContent(data);
    return content;
  } else {
    return;
  }
};

const formatContent = (data) => {
  const formatKeys = data.map((x) => {
    return {
      [x.attribute_name]: x.attribute_value,
    };
  });
  let formattedContent = {};

  for (let i in formatKeys) {
    if (formatKeys[i].text) {
      formattedContent["text"] = formatKeys[i].text;
    }

    if (formatKeys[i].type) {
      formattedContent["type"] = formatKeys[i].type;
    }

    if (formatKeys[i].browser) {
      formattedContent["browser"] = formatKeys[i].browser;
    }
  }
  return formattedContent;
};
