import basicFetch from "./basicFetch";

export default async (url) => {
  const response = await basicFetch(url);
  if (response.data[0]) {
    const innerPageNav = {
      pageTitle: response.data[0].page_name
        ? response.data[0].page_name
        : response.data[0].name,
      links: [],
    };
    const metaData = response.data[0].metatags;

    const pageData = formatContent(response.data[0]);

    return {
      innerPageNav,
      metaData,
      pageData,
    };
  }
};

const formatContent = (content = {}) => {
  let data = {};
  if (content.content_sections.data_struct_items) {
    content.content_sections.data_struct_items.forEach((datum) => {
      data[datum.attribute_name] = datum.attribute_value;
    });
  }
  return data;
};
