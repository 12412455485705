const formatPageBuilderData = (unformattedContent, type, isEULA) => {
  if (!type) {
    const formattedContent = Object.values(unformattedContent)[0];

    const content = formattedContent.map((point) => {
      // Sean would like themes to be programatically determined by the 'human_name' of an organism
      if (point.human_name && organismThemes[point.human_name]) {
        point.theme = organismThemes[point.human_name];
      } else {
        point.theme = "default";
      }

      return [{ ...point }];
    });

    const innerPageNavLinks = formattedContent.reduce((accu, orgGroup) => {
      orgGroup.forEach((item) => {
        if (!accu.includes(item.anchor_text) && item.anchor_text) {
          accu.push({ text: item.anchor_text, url: item.anchor_text });
        }
      });
      return accu;
    }, []);

    return { content, innerPageNavLinks };
  } else {
    if (
      type === "category" ||
      type === "persona" ||
      type === "solutions" ||
      type === "homepage" ||
      type === "page_builder" ||
      isEULA
    ) {
      const content = Object.values(unformattedContent);
      const innerPageNavLinks = content.reduce((accu, orgGroup) => {
        orgGroup.forEach((item) => {
          if (!accu.includes(item.anchor_text) && item.anchor_text) {
            accu.push({ text: item.anchor_text, url: item.anchor_text });
          }
        });
        return accu;
      }, []);
      return { content, innerPageNavLinks };
    }
  }
};

const organismThemes = {
  "Hero 5 - Image Square with Content": "theme-1",
  "Feat 5 - Video with Intro Content": "theme-2",
  "ListFeat 7 - List 3-Column Variable Content": "theme-4",
  "ListFeat 1 - List 2-Column Card with Link": "theme-3",
  "ListFeat 6 - List Variable Text Block": "theme-1",
  "Feat 6 - Quote with Variable Content": "theme-3",
  "Feat 4 - CTAs with Intro Content": "theme-4",
  "Product Information": "theme-3",
  "ListFeat 3 - List Card Group": "theme-2",
  "Hero 1 - Image Full with Content Inset": "theme-1",
};

export default formatPageBuilderData;
