import basicFetch from "./basicFetch";
import formatPageBuilderData from "../methods/formatPageBuilderData";

export default async (url, type, isEULA) => {
  const response = await basicFetch(url);

  // I'm unsure if this first condition is even necessary in FTG - KS
  if (
    response &&
    response.data &&
    response.data[0] & response.data[0].content_sections &&
    response.data[0].content_sections.main_content
  ) {
    const rejiggeredContent = response.data[0].content_sections.main_content.map(
      (item) => {
        return [item];
      }
    );

    const { content, innerPageNavLinks } = formatPageBuilderData(
      rejiggeredContent
    );
    const innerPageNavData = {
      pageTitle: response.data[0].page_name,
      links: innerPageNavLinks,
    };

    const metadata = response.data[0].metatags;
    return { content, innerPageNavData, metadata };
  } else {
    const { content, innerPageNavLinks } = formatPageBuilderData(
      response.data[0].content_sections,
      type,
      isEULA
    );
    const innerPageNavData = {
      pageTitle: response.data[0].page_name,
      links: innerPageNavLinks,
    };
    const metadata = response.data[0].metatags;
    return { content, innerPageNavData, metadata };
  }
};
