import React, { Component } from "react";
import fetchPageBuilderPage from "../../utils/async/fetchPageBuilderPage";
import PageBuilder from "../../components/PageBuilder/PageBuilder";
import { connect } from "react-redux";
import backendURL from "../../utils/helpers/backendURL";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
} from "../../actions";
import Loading from "../../components/Loading/Loading";
import "./PageBuilderPage.scss";
import ErrorPage from "../ErrorPage/ErrorPage";
//import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class PageBuilderPage extends Component {
  constructor() {
    super();
    this.state = {
      content: null,
    };
  }
  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  getLanguage = () => {
    return `${this.props.language || "en"}/`;
  };

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getData = async () => {
    this.setLoading();

    // The EULA page is weird. It's a PageBuilder but returns data unlike PageBuilder pages. Had to make a one-time exception for it.
    const isEULA =
      this.props.route ===
      "/trimble-sketchup-extension-end-user-license-agreement"
        ? true
        : false;

    const url = this.getUrl();
    try {
      const {
        content,
        innerPageNavData,
        metadata,
      } = await fetchPageBuilderPage(url, this.props.type, isEULA);
      this.setState({ content });
      const miniFeat = this.checkForMiniFeatDropDown(content);
      if (miniFeat) {
        this.setState({ miniFeat });
      }
      this.props.setInnerPageNav(innerPageNavData);
      this.setSuccess();
      this.props.setMetadata(metadata);
    } catch (error) {
      console.log(error);
      this.setError();
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(error);
      // }
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  checkForMiniFeatDropDown = (content = []) => {
    let miniFeat = content[0].find((data = {}) => {
      return data.type === "custom_feat_2";
    });
    if (!miniFeat) {
      return;
    }
    return miniFeat;
  };

  getUrl = () => {
    if (this.props.type === "plain_page") {
      return `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/page$${this.props.route.replace(
        "/",
        ""
      )}/$`;
    } else if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.persona
    ) {
      return `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/solutions$${
        this.props.match.params.persona
      }/$`;
    } else if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.category
    ) {
      return `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/products$${
        this.props.match.params.category
      }/$`;
    } else if (this.props.type === "solutions") {
      return `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/solutions/$`;
    } else if (this.props.type === "homepage") {
      return `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/homepage/$`;
    } else {
      return `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL${
        this.props.route
      }/$`;
    }
  };

  generatePathInfo = () => {
    const { match } = this.props;
    if (match && match.params && match.params.category) {
      return match.params.category;
    } else {
      return null;
    }
  };

  render() {
    const { content } = this.state;
    const { status, language } = this.props;
    return (
      <section className="ftg-page-builder-page">
        {status === "loading" && <Loading />}
        {status === "error" && (
          <ErrorPage type="500" history={this.props.history} />
        )}
        {status === "success" && content && content !== "404" && (
          <div>
            <PageBuilder
              type={this.props.type}
              region={this.props.region}
              content={content}
              language={language}
              categories={this.props.categories}
              miniFeat={this.state.miniFeat ? this.state.miniFeat : null}
              sort={this.props.sort}
              demoLabel={this.props.demoLabel}
              pathInfo={this.generatePathInfo()}
            />
          </div>
        )}
        {status === "success" && content && content === "404" && (
          <ErrorPage type="404" history={this.props.history} />
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  region: state.region,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(updateStatus(status)),
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
  setMetadata: (metadata) => dispatch(updateMetadata(metadata)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PageBuilderPage));

PageBuilderPage.propTypes = {
  status: PropTypes.string,
  language: PropTypes.string,
  setInnerPageNav: PropTypes.func,
  setStatus: PropTypes.func,
  setMetadata: PropTypes.func,
  region: PropTypes.string,
  type: PropTypes.string,
};
