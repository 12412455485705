import basicFetch from "./basicFetch";

const postSupportEmailPage = async (entries, reCAPTCHACode) => {
  const url =
    "https://6822xk66ab.execute-api.us-east-2.amazonaws.com/v1/sendEmail";

  const submission = formatEntries(entries, reCAPTCHACode);

  const html = `
    <p style='font-weight:bold; color:black;'>First name: <span style='font-weight: normal;'>${
      submission?.entries?.firstName
    }</span></p>
    <p style='font-weight:bold; color:black;'>Last name: <span style='font-weight: normal;'>${
      submission?.entries?.lastName
    }</span></p>
    <p style='font-weight:bold; color:black;'>Email: <span style='font-weight: normal;'>${
      submission?.entries?.email
    }</span></p>
    <p style='font-weight:bold; color:black;'>Phone: <span style='font-weight: normal;'>${submission
      ?.entries?.phoneNumber || "no response"}</span></p>
    <p style='font-weight:bold; color:black;'>Company: <span style='font-weight: normal;'>${submission
      ?.entries?.companyName || "no response"}</span></p>
    <p style='font-weight:bold; color:black;'>Product: <span style='font-weight: normal;'>${submission
      ?.entries?.product || "no response"}</span></p>
    <p style='font-weight:bold; color:black;'>Issue: <span style='font-weight: normal;'>${submission
      ?.entries?.issueDetails || "no response"}</span></p>
    `;

  const body = JSON.stringify({
    senderName: submission?.entries?.fName,
    senderEmail: submission?.entries?.email,
    toEmail: ["FTGsupport@trimble.com"],
    subject: "New Support Request from trimble.fieldtech.com",
    message: html,
  });

  const requestObject = {
    method: "POST",
    headers: {
      authorizationToken: process.env.REACT_APP_SES_AUTH_TOKEN,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body,
  };
  const response = await fetch(url, requestObject);
  const data = await response.json();

  if (data && data === "success") {
    return true;
  } else {
    return false;
  }
};

const formatEntries = (entries, code) => {
  const formattedEntries = entries.reduce((accu, entry) => {
    accu[entry.machineName] = entry.value;
    return accu;
  }, {});

  return {
    entries: formattedEntries,
    key: "i@i)cj#.f;aAfIPQjfL38Fj",
    reCAPTCHACode: code,
  };
};

export default postSupportEmailPage;
