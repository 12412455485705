import basicFetch from "./basicFetch";

const fetchContactPageData = async (url, page) => {
  const response = await basicFetch(url);
  const content = formatContent(
    response.data[0].content_sections.data_struct_items
  );

  const innerPageNavData = {
    pageTitle: page ? content.demoPageHeader : content.header,
    links: [],
  };

  const metadata = response.data[0].metatags;

  return { content, innerPageNavData, metadata };
};

export default fetchContactPageData;

const formatContent = (data) => {
  const header = data.find((point) => point.attribute_name === "Header")
    .attribute_value;

  const demoPageHeader = data.find(
    (point) => point.attribute_name === "Demo Page Header"
  ).attribute_value;
  const submissionHeader = data.find(
    (point) => point.attribute_name === "Submission Header"
  ).attribute_value;
  const subHeader = data.find(
    (point) => point.attribute_name === "Submission Sub Header"
  );
  const homeButtonText = data.find(
    (point) => point.attribute_name === "Home Button"
  ).attribute_value;
  const imageDataObj = data.find(
    (point) => point.attribute_name === "Success Page Image"
  ).attribute_value;
  const submissionImage = {
    url: imageDataObj && imageDataObj.source ? imageDataObj.source : null,
    alt: imageDataObj && imageDataObj.alt ? imageDataObj.alt : null,
  };
  const selectText = data.find(
    (point) => point.attribute_name === "Select Text"
  ).attribute_value;
  const submitText = data.find(
    (point) => point.attribute_name === "Submit Text"
  ).attribute_value;
  const warningText = data.find(
    (point) => point.attribute_name === "Warning Text"
  ).attribute_value;

  return {
    header,
    demoPageHeader,
    submissionHeader,
    homeButtonText,
    submissionImage,
    selectText,
    submitText,
    warningText,
    submissionSubHeader:
      subHeader && subHeader.attribute_value ? subHeader.attribute_value : null,
  };
};

export const fetchProductName = async (url) => {
  const response = await basicFetch(url);

  if (
    response &&
    response.data &&
    response.data[0] &&
    (response.data[0].marketo_name || response.data[0].name)
  ) {
    // Use the marketo_name or default to the product name if that is not filled out
    // The marketo_name refers to the name used on the Marketo form, which is occasionally different than the product name because reasons

    return response.data[0].marketo_name || response.data[0].name;
  } else {
    return null;
  }
};
