import React, { Component } from "react";
import { connect } from "react-redux";
import { FilterHero, StoreFrontCard, Feat4 } from "luna-one";
import ErrorPage from "../ErrorPage/ErrorPage";
import Loading from "../../components/Loading/Loading";
import backendURL from "../../utils/helpers/backendURL";
//import * as Sentry from "@sentry/browser";
import "./StorefrontPage.scss";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
} from "../../actions";
import { Button } from "terra-component-lib";
import fetchStorefrontCards from "../../utils/async/fetchStorefrontCards";
import fetchStorefrontData from "../../utils/async/fetchStorefrontData";
import PropTypes from "prop-types";

// TODO country list will need to be updated. Currently it is using regions as the country list, but the only region in the list is "US/CA"

// TODO when we have a country list and a way to add items to a cart, there will need to be some updates on functionality

class StorefrontPage extends Component {
  constructor() {
    super();
    this.state = {
      content: null,
    };
  }
  componentDidMount() {
    this.getData();
  }

  getLanguage = () => {
    return `${this.props.language || "en"}/`;
  };

  getUrl = () => {
    return `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/storefront/$`;
  };

  getUrlForCards = () => {
    return `${backendURL}/${this.getLanguage()}api-v1/FetchAllNodesByType/store_item`;
  };

  handleDropdownSelect = (selection) => {
    this.setState({
      selectedCountry: selection,
    });
  };

  getData = async () => {
    this.setLoading();
    const cardsURL = this.getUrlForCards();
    const contentURL = this.getUrl();
    try {
      const content = await fetchStorefrontCards(cardsURL);
      const { innerPageNav, metaData, pageData } = await fetchStorefrontData(
        contentURL
      );
      const dropdowns = this.formatDropdown(this.props.regions, pageData);
      const featFourData = this.formatFeatFourData(pageData);
      this.props.setInnerPageNav(innerPageNav);
      this.props.setMetadata(metaData);
      this.setSuccess();
      this.setState({ content, pageData, featFourData, dropdowns });
    } catch (e) {
      console.log(e);
      this.setError();
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(e);
      // }
    }
  };

  formatDropdown = (data = [], content = {}) => {
    const list = data.map((datum = {}) => {
      return datum.name;
    });
    const dropdownOne = {
      options: list,
      defaultText: list[0],
      name: content.dropdown_title || null,
    };
    const dropdowns = [dropdownOne];
    return dropdowns;
  };

  formatFeatFourData = (content = {}) => {
    const ctas = {
      ctaOne: {
        text: content.button_text || null,
        url: content.button_link || null,
      },
    };
    const header = content.feat_4_header || null;
    const subHeader = content.feat_4_desciption || null;
    return {
      header,
      subHeader,
      ctas,
    };
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  renderCards = () => {
    if (this.state.content) {
      return this.state.content.map((card, index) => {
        return (
          <div key={index} className="store-front-page__card-container">
            <StoreFrontCard key={index} content={card} />
          </div>
        );
      });
    }
  };

  render() {
    const { content, pageData, featFourData, dropdowns } = this.state;
    const { status } = this.props;
    return (
      <section>
        {status === "loading" && <Loading />}
        {status === "error" && (
          <ErrorPage type="500" history={this.props.history} />
        )}

        {status === "success" && content && content === "404" && (
          <ErrorPage type="404" history={this.props.history} />
        )}
        {status === "success" && content && content !== "404" && (
          <section className="store-front-page">
            <FilterHero
              theme="dark"
              handleDropdownSelect={this.handleDropdownSelect}
              header={pageData.header}
              text={pageData.description}
              dropdowns={dropdowns}
            />
            <section className="store-front-page__cards">
              <div className="store-front-page__custom">
                <p className="store-front-page__custom-text">
                  {pageData.custom_text_block}
                </p>
                <Button
                  url={pageData.button_link}
                  text={pageData.button_text}
                  className="ter-button--secondary--1 store-front-page__custom-button"
                />
              </div>
              {this.renderCards()}
            </section>
            <Feat4 content={featFourData} />
          </section>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(updateStatus(status)),
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
  setMetadata: (metadata) => dispatch(updateMetadata(metadata)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StorefrontPage);

StorefrontPage.propTypes = {
  status: PropTypes.string,
  language: PropTypes.string,
  setInnerPageNav: PropTypes.func,
  setStatus: PropTypes.func,
  setMetadata: PropTypes.func,
  region: PropTypes.string,
  regions: PropTypes.array,
};
