import basicFetch from "./basicFetch";
import backendURL from "../helpers/backendURL";

const fetchCategories = async (language) => {
  const url = `${backendURL}/${language}api-v1/FetchTaxonomyByMachineName/ftg_categories`;
  const { data } = await basicFetch(url);
  return data.map((datum = {}) => {
    return datum.name;
  });
};

export default fetchCategories;
