import React from "react";
import { connect } from "react-redux";
import { Hero5 } from "luna-one";
import { updateInnerPageNav } from "../../actions";
import PropTypes from "prop-types";

const ErrorPage = (props) => {
  if (props.mainError) {
    return (
      <div style={{ marginTop: "150px" }}>
        <Hero5 content={props.content} motion type="hero_5" />
      </div>
    );
  }
  if (props.type === "404") {
    const content = props.fourOhFourData;
    const innerPageNavData = {
      pageTitle: content.header,
      links: [],
    };
    props.setInnerPageNav(innerPageNavData);
    if (content.ctas && content.ctas.ctaTwo) {
      content.ctas.ctaTwo.onClick = props.history.goBack;
    }
    return (
      <div style={{ marginTop: "150px" }}>
        <Hero5 content={content} motion type="hero_5" />
      </div>
    );
  } else {
    const content = props.fiveHundredData;
    const innerPageNavData = {
      pageTitle: content.header,
      links: [],
    };
    props.setInnerPageNav(innerPageNavData);
    if (
      content.ctas &&
      content.ctas.ctaTwo &&
      props.histroy &&
      props.history.goBack
    ) {
      content.ctas.ctaTwo.onClick = props.history.goBack;
    }
    return (
      <div style={{ marginTop: "150px" }}>
        <Hero5 content={content} motion type="hero_5" />
      </div>
    );
  }
};
const mapStateToProps = (state) => {
  return {
    fourOhFourData: state.fourOhFourData,
    fiveHundredData: state.fiveHundredData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);

ErrorPage.propTypes = {
  fourOhFourData: PropTypes.object,
  fiveHundredData: PropTypes.object,
  setInnerPageNav: PropTypes.func,
};
