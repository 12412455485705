import basicFetch from "./basicFetch";

export default async (url) => {
  const response = await basicFetch(url);
  const innerPageNavData = {
    pageTitle: response.data[0].page_name
      ? response.data[0].page_name
      : response.data[0].name,
    links: [],
  };
  const content = formatContent(response.data[0]);
  const metadata = response.data[0].metatags;
  return {
    innerPageNavData,
    metadata,
    content,
  };
};

const formatContent = (content) => {
  let data = {};

  content.content_sections.data_struct_items.forEach((datum) => {
    data[datum.attribute_name] = datum.attribute_value;
  });
  data.description =
    data &&
    data.description &&
    data.description &&
    data.description.value &&
    data.description.value[0] &&
    data.description.value[0].value
      ? data.description.value[0].value
      : null;

  data.images = {
    desktop: {
      url:
        data.Header_Image && data.Header_Image.source
          ? data.Header_Image.source
          : null,
      altText:
        data.Header_Image && data.Header_Image.alt
          ? data.Header_Image.alt
          : null,
    },
  };
  data.text = data.description ? data.description : null;
  return data;
};
