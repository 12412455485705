import basicFetch from "./basicFetch";
import backendURL from "../helpers/backendURL";

export default async () => {
  // TODO Uncomment when Drupal works on prod
  const url = `https://fieldtech-cms.trimble.com/en/api-v1/GetLanguages`;
  // const url = `${backendURL}/api-v1/GetLanguages`;

  const response = await basicFetch(url);
  if (response.data) {
    return response.data;
  } else {
    throw new Error();
  }
};
