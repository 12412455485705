import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import ProductPage from "../../pages/ProductPage/ProductPage";
import EventsPage from "../../pages/EventsPage/EventsPage";
import ProductsLandingPage from "../../pages/ProductsLandingPage/ProductsLandingPage";
import PageBuilderPage from "../../pages/PageBuilderPage/PageBuilderPage";
import AllProductsPage from "../../pages/AllProductsPage/AllProductsPage";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import { connect } from "react-redux";
import { updateStatus, updateOffsetY } from "../../actions";
import { ParallaxController, ScrollToTop } from "luna-one";
import AdminProductPage from "../../pages/AdminProductPage/AdminProductPage";
import AdminCategoriesPage from "../../pages/AdminCategoriesPage/AdminCategoriesPage";
import ContactPage from "../../pages/ContactPage/ContactPage";
import ContactSuccessPage from "../../pages/ContactSuccessPage/ContactSuccessPage";
import StorefrontPage from "../../pages/StorefrontPage/StorefrontPage";
import SearchResultsPage from "../../pages/SearchResultsPage/SearchResultsPage";
import MetalocatorPage from "../../pages/MetalocatorPage/MetalocatorPage";
import SupportEmailPage from "../../pages/SupportEmailPage/SupportEmailPage";
class Routing extends Component {
  constructor() {
    super();
    this.state = {};
  }
  getLanguage = () => {
    return `${this.props.language || "en"}`;
  };

  getInactiveLanguages = () => {
    return this.props.languages.filter((language) => {
      return language.id !== this.props.language;
    });
  };
  generatePageBuilderRoutes = () => {
    return this.props.routesData.map((route) => {
      return (
        <Route
          hreflang={this.getLanguage()}
          path={`/${this.getLanguage()}${route.url}`}
          key={route.url}
          render={() => <PageBuilderPage route={route.url} type={route.type} />}
          exact
        />
      );
    });
  };

  generatePageBuilderNoLangRedirects = () => {
    return this.props.routesData.map((route) => {
      return (
        <Route
          hreflang={this.getLanguage()}
          path={`/${route}`}
          key={route}
          render={() => <Redirect to={`/${this.getLanguage()}/${route}`} />}
          exact
        />
      );
    });
  };

  generatePageBuilderWrongLangRedirects = () => {
    return this.getInactiveLanguages().reduce((accu, language) => {
      this.props.routesData.forEach((route) => {
        const newRoute = (
          <Route
            hreflang={this.getLanguage()}
            path={`/${language}/${route}`}
            render={() => <Redirect to={`/${this.getLanguage()}/${route}`} />}
            exact
            key={`${language}-${route}`}
          />
        );
        accu.push(newRoute);
      });
      return accu;
    }, []);
  };

  generateHomeRoute = () => {
    const homeRoute = (
      <Route
        key={`home-page-${this.getLanguage()}-route`}
        path={`/${this.getLanguage()}`}
        render={(props) => <PageBuilderPage {...props} type="homepage" />}
        exact
      />
    );

    return [homeRoute, this.generateHomeRedirects()];
  };

  generateHomeRedirects = () => {
    return (
      <Route
        key={`home-page-${this.getLanguage()}-route-redirect`}
        hreflang={this.getLanguage()}
        path="/"
        exact
        render={() => <Redirect to={`/${this.getLanguage()}/`} />}
      />
    );
  };

  generateAllProductsRoute = () => {
    const productRoute = (
      <Route
        key={`all-products-${this.getLanguage()}-route`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/all-products`}
        exact
        render={(props) => <AllProductsPage {...props} type="all-products" />}
      />
    );

    return [productRoute, this.generateAllProductsRedirects()];
  };

  generateAllProductsRedirects = () => {
    return (
      <Route
        key={`all-products-${this.getLanguage()}-route-redirect`}
        hreflang={this.getLanguage()}
        path="/all-products"
        exact
        render={(props) => (
          <Redirect to={`/${this.getLanguage()}/all-products`} />
        )}
      />
    );
  };

  generateProductsLandingRoute = () => {
    const productRoute = (
      <Route
        key={`/${this.getLanguage()}/products-key`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/products`}
        exact
        render={(props) => (
          <ProductsLandingPage {...props} type="product-overview" />
        )}
      />
    );

    return [productRoute, this.generateProductsLandingRedirects()];
  };

  generateProductsLandingRedirects = () => {
    return (
      <Route
        key={`${this.getLanguage()}/products-redirect-landing`}
        hreflang={this.getLanguage()}
        path="/products"
        exact
        render={(props) => <Redirect to={`/${this.getLanguage()}/products`} />}
      />
    );
  };

  generateSolutionsRoute = () => {
    const productRoute = (
      <Route
        key={`${this.getLanguage()}/solutions-`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/solutions`}
        exact
        render={(props) => <PageBuilderPage {...props} type="solutions" />}
      />
    );

    return [productRoute, this.generateSolutionsRedirect()];
  };

  generateSolutionsRedirect = () => {
    return (
      <Route
        key={`${this.getLanguage()}/solutions-redirect-landing`}
        hreflang={this.getLanguage()}
        path="/solutions"
        exact
        render={() => <Redirect to={`/${this.getLanguage()}/solutions`} />}
      />
    );
  };

  generateEventsRoute = () => {
    const eventRoute = (
      <Route
        key={`${this.getLanguage()}/events-landing`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/events`}
        exact
        render={(props) => {
          return <EventsPage {...props} regions={this.props.regions} />;
        }}
      />
    );

    return [eventRoute, this.generateEventsRedirect()];
  };

  generateEventsRedirect = () => {
    return (
      <Route
        key={`${this.getLanguage()}/events-redirect-landing`}
        hreflang={this.getLanguage()}
        path="/events"
        exact
        render={(props) => <Redirect to={`/${this.getLanguage()}/events`} />}
      />
    );
  };

  generateCategoryRoute = () => {
    const categoryRoute = (
      <Route
        key={`${this.getLanguage()}/products-category-landing`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/products/:category`}
        render={(props) => (
          <PageBuilderPage
            sort
            type="category"
            {...props}
            demoLabel={this.props.demoLabel}
          />
        )}
        exact
      />
    );

    return [categoryRoute, this.generateCategoryRedirect()];
  };

  generateCategoryRedirect = () => {
    return (
      <Route
        key={`${this.getLanguage()}/products-category-redirect-landing`}
        hreflang={this.getLanguage()}
        path="/products/:category"
        exact
        render={(props) => (
          <Redirect
            to={`/${this.getLanguage()}/products/${
              props.match.params.category
            }`}
          />
        )}
      />
    );
  };

  generateProductPageRoute = () => {
    const productRoute = (
      <Route
        key={`${this.getLanguage()}/products-category-productName`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/products/:category/:productName`}
        render={(props) => (
          <ProductPage
            demoLabel={this.props.demoLabel}
            {...props}
            toggleRegionLanguageDeployed={
              this.props.toggleRegionLanguageDeployed
            }
          />
        )}
        exact
      />
    );

    return [productRoute, this.generateProductPageRedirect()];
  };

  generateProductPageRedirect = () => {
    return (
      <Route
        key={`${this.getLanguage()}/products-category-productName-redirect`}
        hreflang={this.getLanguage()}
        path="/products/:category/:productName"
        exact
        render={(props) => (
          <Redirect
            to={`/${this.getLanguage()}/products/${
              props.match.params.category
            }/${props.match.params.productName}`}
          />
        )}
      />
    );
  };

  generateProductDemoRoute = () => {
    const productDemoRoute = (
      <Route
        key={`${this.getLanguage()}/products-category-productName-demo`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/products/:category/:productName/demo`}
        render={(props) => <ContactPage {...props} demo />}
        exact
      />
    );

    return [productDemoRoute, this.generateProductDemoRedirect()];
  };

  generateProductDemoRedirect = () => {
    return (
      <Route
        key={`${this.getLanguage()}/products-category-productName-redirect`}
        hreflang={this.getLanguage()}
        path="/products/:category/:productName/demo"
        exact
        render={(props) => (
          <Redirect
            to={`/${this.getLanguage()}/products/${
              props.match.params.category
            }/${props.match.params.productName}/demo`}
          />
        )}
      />
    );
  };

  generateContactSalesRoute = () => {
    const contactSalesRoute = (
      <Route
        key="en/products/trimble-connect-ar/contact-sales"
        hreflang="en"
        path={`/en/products/trimble-connect-ar/contact-sales`}
        render={(props) => <ContactPage {...props} contactSales />}
        exact
      />
    );

    return [contactSalesRoute, this.generateContactSalesRedirect()];
  };

  generateContactSalesRedirect = () => {
    return (
      <Route
        key="en/products/trimble-connect-ar/contact-sales-redirect"
        hreflang="en"
        path="/products/trimble-connect-ar/contact-sales"
        exact
        render={(props) => (
          <Redirect
            to="/en/products/trimble-connect-ar/contact-sales"
          />
        )}
      />
    );
  };

  generateSolutionPersonaPageRoute = () => {
    const solutionPersonaRoute = (
      <Route
        key={`${this.getLanguage()}/solutions-persona-page`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/solutions/:persona`}
        render={(props) => <PageBuilderPage type="persona" sort {...props} />}
        exact
      />
    );

    return [solutionPersonaRoute, this.generateSolutionPersonaRedirect()];
  };

  generateSolutionPersonaRedirect = () => {
    return (
      <Route
        key={`${this.getLanguage()}/solution-persona-redirect`}
        hreflang={this.getLanguage()}
        path="/solutions/:persona"
        exact
        render={(props) => (
          <Redirect
            to={`/${this.getLanguage()}/solutions/${
              props.match.params.persona
            }`}
          />
        )}
      />
    );
  };

  generateProductRoute = () => {
    const productRoute = (
      <Route
        key={`${this.getLanguage()}/products-productName`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/product/:productName`}
        exact
        render={(props) => {
          return (
            <ProductPage
              demoLabel={this.props.demoLabel}
              {...props}
              toggleRegionLanguageDeployed={
                this.props.toggleRegionLanguageDeployed
              }
            />
          );
        }}
      />
    );

    return [productRoute, this.generateProductRedirect()];
  };

  generateProductRedirect = () => {
    return (
      <Route
        key={`${this.getLanguage()}/products-productName-redirect`}
        hreflang={this.getLanguage()}
        path="/product/:productName"
        exact
        render={(props) => (
          <Redirect
            to={`/${this.getLanguage()}/product/${
              props.match.params.productName
            }`}
          />
        )}
      />
    );
  };

  generateContactPageRoute = () => {
    const productDemoRoute = (
      <Route
        key={`${this.getLanguage()}/products-productName-demo-`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/product/:productName/demo`}
        exact
        render={(props) => <ContactPage {...props} demo />}
      />
    );

    return [productDemoRoute, this.generateContactPageRedirect()];
  };

  generateContactPageRedirect = () => {
    return (
      <Route
        key={`${this.getLanguage()}/products-productName-demo-redirect-`}
        hreflang={this.getLanguage()}
        path="/product/:productName/demo"
        exact
        render={(props) => (
          <Redirect
            to={`/${this.getLanguage()}/product/${
              props.match.params.productName
            }/demo`}
          />
        )}
      />
    );
  };

  generateSearchRoute = () => {
    const searchRoute = (
      <Route
        key={`${this.getLanguage()}/search`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/search/:searchTerm`}
        component={SearchResultsPage}
      />
    );

    return [searchRoute, this.generateSearchRedirect()];
  };

  generateSearchRedirect = () => {
    return (
      <Route
        key={`${this.getLanguage()}-search-redirect`}
        hreflang={this.getLanguage()}
        path="/search/:searchTerm"
        render={(props) => (
          <Redirect
            to={`/${this.getLanguage()}/search/${
              props.match.params.searchTerm
            }`}
          />
        )}
      />
    );
  };

  generateContactRoute = () => {
    const contactRoute = (
      <Route
        key={`${this.getLanguage()}-contact`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/contact`}
        component={ContactPage}
        exact
      />
    );

    return [contactRoute, this.generateContactRedirect()];
  };

  generateContactRedirect = () => {
    return (
      <Route
        key={`${this.getLanguage()}-contact-redirect`}
        hreflang={this.getLanguage()}
        path={`/contact`}
        exact
        render={() => <Redirect to={`/${this.getLanguage()}/contact`} />}
      />
    );
  };

  // generateContactSupportRoute = () => {
  //   const contactRoute = (
  //     <Route
  //       key={`${this.getLanguage()}-contact-support`}
  //       hreflang={this.getLanguage()}
  //       path={`/${this.getLanguage()}/contact-support`}
  //       render={(props) => {
  //         return <SupportEmailPage {...props} />;
  //       }}
  //       exact
  //     />
  //   );

  //   return [contactRoute, this.generateContactSupportRedirect()];
  // };

  generateContactSupportRedirect = () => {
    return (
      <Route
        key={`${this.getLanguage()}-contact-support-redirect`}
        hreflang={this.getLanguage()}
        path={`/contact-support`}
        exact
        render={(props) => (
          <Redirect to={`/${this.getLanguage()}/contact-support`} />
        )}
      />
    );
  };

  generateWhereToBuyRoute = () => {
    const whereToBuyRoute = (
      <Route
        key={`${this.getLanguage()}-where-to-buy`}
        hreflang={this.getLanguage()}
        path={`/${this.getLanguage()}/where-to-buy`}
        exact
        render={(props) => <MetalocatorPage {...props} />}
      />
    );

    return [whereToBuyRoute, this.generateWhereToBuyRedirect()];
  };

  generateWhereToBuyRedirect = () => {
    return (
      <Route
        key={`${this.getLanguage()}-where-to-buy-redirect`}
        hreflang={this.getLanguage()}
        path={`/where-to-buy`}
        exact
        render={() => <Redirect to={`/${this.getLanguage()}/where-to-buy`} />}
      />
    );
  };

  render() {
    return (
      <Fragment>
        <ParallaxController
          updateOffsetY={this.props.updateOffsetY}
          updateWindowHeight={() => {}}
        />
        <ScrollToTop />
        <Switch>
          {this.generateHomeRoute()}
          {this.generateAllProductsRoute()}
          {this.generateProductsLandingRoute()}
          {this.generateSolutionsRoute()}
          {this.generateEventsRoute()}
          {this.generateContactSalesRoute()}
          {this.generateCategoryRoute()}
          {this.generateProductPageRoute()}
          {this.generateProductDemoRoute()}
          {this.generateSolutionPersonaPageRoute()}
          {this.generateProductRoute()}
          {this.generateContactPageRoute()}
          {this.generateSearchRoute()}
          {this.generateContactRoute()}
          {/* {this.generateContactSupportRoute()} */}
          {this.generateWhereToBuyRoute()}
          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                path={`/${language}`}
                exact
                render={() => <Redirect to={`/${this.getLanguage()}/`} />}
                key={`${language}-home-redirect`}
              />
            );
          })}

          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                path={`/${language}/all-products`}
                exact
                render={() => (
                  <Redirect to={`/${this.getLanguage()}/all-products`} />
                )}
                key={`${language}-all-products-redirect`}
              />
            );
          })}

          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                path={`/${language}/products`}
                exact
                key={`${language}-products-redirect`}
                render={() => (
                  <Redirect to={`/${this.getLanguage()}/products`} />
                )}
              />
            );
          })}
          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                path={`/${language}/solutions`}
                exact
                key={`${language}-solutions-redirect`}
                render={() => (
                  <Redirect to={`/${this.getLanguage()}/solutions`} />
                )}
              />
            );
          })}        

          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                path={`/${language}/products/:category`}
                exact
                key={`${language}-products-category-redirect`}
                render={(props) => (
                  <Redirect
                    to={`/${this.getLanguage()}/products/${
                      props.match.params.category
                    }`}
                  />
                )}
              />
            );
          })}

          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                path={`/${language}/products/:category/:productName`}
                exact
                key={`${language}-products-category-proredirect`}
                render={(props) => (
                  <Redirect
                    to={`/${this.getLanguage()}/products/${
                      props.match.params.category
                    }/${props.match.params.productName}`}
                  />
                )}
              />
            );
          })}

          <Route
            hreflang={this.getLanguage()}
            path={`/${this.getLanguage()}/products/:category/:productName/demo/success`}
            component={ContactSuccessPage}
            exact
          />

          <Route
            hreflang={this.getLanguage()}
            path={`/${this.getLanguage()}/products/trimble-connect-ar/contact-sales/success`}
            component={ContactSuccessPage}
            exact
          />

          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                path={`/${language}/products/:category/:productName/demo`}
                exact
                key={`${language}-products-category-proredirect`}
                render={(props) => (
                  <Redirect
                    to={`/${this.getLanguage()}/products/${
                      props.match.params.category
                    }/${props.match.params.productName}/demo`}
                  />
                )}
              />
            );
          })}        

          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                key={`${language}-solutions-persona-redirect`}
                path={`/${language}/solutions/:persona`}
                exact
                render={(props) => (
                  <Redirect
                    to={`/${this.getLanguage()}/solutions/${
                      props.match.params.persona
                    }`}
                  />
                )}
              />
            );
          })}

          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                path={`/${language}/product/:productName`}
                exact
                key={`${language}-product-page-redirect`}
                render={(props) => (
                  <Redirect
                    to={`/${this.getLanguage()}/product/${
                      props.match.params.productName
                    }`}
                  />
                )}
              />
            );
          })}
          <Route
            hreflang={this.getLanguage()}
            path={`/${this.getLanguage()}/product/:productName/demo/success`}
            exact
            component={ContactSuccessPage}
          />

          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                path={`/${language}/product/:productName/demo`}
                exact
                key={`${language}-product-page-redirect`}
                render={(props) => (
                  <Redirect
                    to={`/${this.getLanguage()}/product/${
                      props.match.params.productName
                    }/demo`}
                  />
                )}
              />
            );
          })}
          <Route
            hreflang={this.getLanguage()}
            path="/solutions/:productName"
            exact
            render={(props) => (
              <Redirect
                to={`/${this.getLanguage()}/product/${
                  props.match.params.persona
                }`}
              />
            )}
          />
          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                key={`${language}-solutions-product-page-redirect`}
                path={`/${language}/solutions/:productName`}
                exact
                render={(props) => (
                  <Redirect
                    to={`/${this.getLanguage()}/product/${
                      props.match.params.persona
                    }`}
                  />
                )}
              />
            );
          })}
          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                key={`${language}-events-page-redirect`}
                path={`/${language}/events`}
                exact
                render={() => <Redirect to={`/${this.getLanguage()}/events`} />}
              />
            );
          })}

          {this.generatePageBuilderRoutes()}
          {this.generatePageBuilderNoLangRedirects()}

          <Route
            hreflang={this.getLanguage()}
            path={`/${this.getLanguage()}/admin/products`}
            component={AdminProductPage}
            exact
          />
          <Route
            hreflang={this.getLanguage()}
            path={`/${this.getLanguage()}/admin/product/:productName/:translation`}
            exact
            render={(props) => {
              return (
                <ProductPage
                  demoLabel={this.props.demoLabel}
                  {...props}
                  admin
                />
              );
            }}
          />
          <Route
            hreflang={this.getLanguage()}
            path={`/${this.getLanguage()}/admin/products/:productName/:translation`}
            exact
            render={(props) => {
              return <ProductPage {...props} category admin />;
            }}
          />
          <Route
            hreflang={this.getLanguage()}
            path={`/admin/products/:productName/:translation`}
            exact
            render={(props) => {
              return (
                <Redirect
                  to={`/${this.getLanguage()}/admin/products/${
                    props.match.params.productName
                  }/${props.match.params.translation}`}
                />
              );
            }}
          />
          <Route
            hreflang={this.getLanguage()}
            path={`/admin/product/:productName/:translation`}
            exact
            render={(props) => {
              return (
                <Redirect
                  to={`/${this.getLanguage()}/admin/products/${
                    props.match.params.productName
                  }/${props.match.params.translation}`}
                />
              );
            }}
          />

          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                path={`/${language}/search/:searchTerm`}
                key={`${language}-search-results-redirect`}
                render={(props) => (
                  <Redirect
                    to={`/${this.getLanguage()}/search/${
                      props.match.params.searchTerm
                    }`}
                  />
                )}
              />
            );
          })}

          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                key={`${language}-contact-redirect`}
                path={`/${language}/contact`}
                exact
                render={() => (
                  <Redirect to={`/${this.getLanguage()}/contact`} />
                )}
              />
            );
          })}
          <Route
            hreflang={this.getLanguage()}
            path={`/${this.getLanguage()}/contact/success`}
            component={ContactSuccessPage}
            exact
          />
          <Route
            hreflang={this.getLanguage()}
            path={`/${this.getLanguage()}/admin/categories`}
            component={AdminCategoriesPage}
            exact
          />
          <Route
            hreflang={this.getLanguage()}
            path={`/${this.getLanguage()}/store`}
            render={(props) => (
              <StorefrontPage
                type="store"
                {...props}
                regions={this.props.regions}
              />
            )}
            exact
          />

          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                path={`/${language}/contact-support`}
                exact
                key={`${language}-contact-support-redirect`}
                render={(props) => (
                  <Redirect to={`/${this.getLanguage()}/contact-support`} />
                )}
              />
            );
          })}
          <Route
            hreflang={this.getLanguage()}
            path={`/${this.getLanguage()}/contact-support/success`}
            render={(props) => {
              return <ContactSuccessPage {...props} contactSupportSuccess />;
            }}
            exact
          />
          <Route
            hreflang={this.getLanguage()}
            path={`/${this.getLanguage()}/contact-support/error`}
            render={(props) => {
              return <ErrorPage {...props} type={"500"} />;
            }}
            exact
          />
          <Route
            hreflang={this.getLanguage()}
            path="/store"
            exact
            render={(props) => <Redirect to={`/${this.getLanguage()}/store`} />}
          />
          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                key={`${language}-store-redirect`}
                path={`/${language}/store`}
                exact
                render={(props) => (
                  <Redirect to={`/${this.getLanguage()}/store`} />
                )}
              />
            );
          })}

          {this.getInactiveLanguages().map((language) => {
            return (
              <Route
                hreflang={this.getLanguage()}
                path={`/${language}/where-to-buy`}
                exact
                render={() => (
                  <Redirect to={`/${this.getLanguage()}/where-to-buy`} />
                )}
                key={`${language}-where-to-buy-redirect`}
              />
            );
          })}
          <Route
            hreflang={this.getLanguage()}
            render={(props) => {
              this.props.setStatus("error");
              return <ErrorPage type="404" {...props} />;
            }}
          />
        </Switch>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  languages: state.languages,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(updateStatus(status)),
    updateOffsetY: (data) => dispatch(updateOffsetY(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
