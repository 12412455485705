import basicFetch from "./basicFetch";
//import * as Sentry from "@sentry/browser";

export default async () => {
  // This call is occassionally returning a status code of '0', meaning that the request is getting crushed. It has been isolated into its own try/catch block so that it doesn't cascade into a site loading failure.
  try {
    const response = await basicFetch(
      `https://pro.ip-api.com/json/?key=Lx7Oi3ihnOKR38Z&fields=status,countryCode&lang=`
    );

    if (response && response.data && response.data.countryCode) {
      return response.data.countryCode;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
    //   Sentry.captureException(e);
    // }
    return null;
  }
};
