import React, { Component, Fragment } from "react";
import { Element } from "react-scroll";
import { AccordionFold } from "terra-component-lib";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import generic1 from "../../assets/patterns/Generic-A-1.svg";
import generic2 from "../../assets/patterns/Generic-A-2.svg";
import generic3 from "../../assets/patterns/Generic-A-3.svg";
import generic4 from "../../assets/patterns/Generic-A-4.svg";
import generic5 from "../../assets/patterns/Generic-A-5.svg";
import siteManagement1 from "../../assets/patterns/Labor-Management-1.svg";
import siteManagement2 from "../../assets/patterns/Labor-Management-2.svg";
import siteManagement3 from "../../assets/patterns/Labor-Management-3.svg";
import siteManagement4 from "../../assets/patterns/Labor-Management-4.svg";
import siteManagement5 from "../../assets/patterns/Labor-Management-5.svg";
import layout1 from "../../assets/patterns/Layout-A-1.svg";
import layout2 from "../../assets/patterns/Layout-A-2.svg";
import layout3 from "../../assets/patterns/Layout-A-3.svg";
import layout4 from "../../assets/patterns/Layout-A-4.svg";
import layout5 from "../../assets/patterns/Layout-A-5.svg";
import mixedReality1 from "../../assets/patterns/Mixed-Reality-A-1.svg";
import mixedReality2 from "../../assets/patterns/Mixed-Reality-A-2.svg";
import mixedReality3 from "../../assets/patterns/Mixed-Reality-A-3.svg";
import mixedReality4 from "../../assets/patterns/Mixed-Reality-A-4.svg";
import mixedReality5 from "../../assets/patterns/Mixed-Reality-A-5.svg";
import scanning1 from "../../assets/patterns/Scanning-A-1.svg";
import scanning2 from "../../assets/patterns/Scanning-A-2.svg";
import scanning3 from "../../assets/patterns/Scanning-A-3.svg";
import scanning4 from "../../assets/patterns/Scanning-A-4.svg";
import scanning5 from "../../assets/patterns/Scanning-A-5.svg";
import toolTracking1 from "../../assets/patterns/Tool-tracking-1.svg";
import toolTracking2 from "../../assets/patterns/Tool-tracking-2.svg";
import toolTracking3 from "../../assets/patterns/Tool-tracking-3.svg";
import toolTracking4 from "../../assets/patterns/Tool-tracking-4.svg";
import toolTracking5 from "../../assets/patterns/Tool-tracking-5.svg";

import {
  formatListFeatOne,
  formatListFeatTwo,
  formatListFeatThree,
  formatListFeatFour,
  formatListFeatFive,
  formatListFeatSix,
  formatListFeatSeven,
  formatHeroOne,
  formatHeroTwo,
  formatHeroThree,
  formatHeroFour,
  formatHeroFive,
  formatHeroSix,
  formatFeatOne,
  formatFeatTwoA,
  formatFeatTwoB,
  formatFeatThree,
  formatFeatFour,
  formatFeatFive,
  formatFeatSix,
  formatFeatSeven,
  formatHardwareTable,
  formatTeaserListFeatThree,
  formatMiniFeatDropDown,
  formatListFeatCustom,
} from "../../utils/methods/organismDataFormat";
import {
  Hero1,
  Hero2,
  Hero3,
  Hero4,
  Hero5,
  Hero6,
  Feat1,
  Feat2A,
  Feat2B,
  Feat3,
  Feat4,
  Feat5,
  Feat6,
  Feat7,
  ListFeat,
  MiniFeatDropdown,
} from "luna-one";
import HardwareTable from "../../components/HardwareTable/HardwareTable";

class PageBuilder extends Component {
  constructor() {
    super();
    this.state = {};
  }

  renderOrganisms = () => {
    const content = this.props.content || [];
    return content.reduce((acc, organism, index) => {
      organism.forEach((org, orgIndex) => {
        const organismMethod = this.getOrganism(org.type);
        if (organismMethod) {
          acc.push(organismMethod(org, index, orgIndex));
        }
      });

      return acc;
    }, []);
  };

  convertStringToKebabCase = (string) => {
    if (string && string.toLowerCase) {
      return string
        .replace(/\s+/g, "-")
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "-");
    } else {
      return string;
    }
  };

  camelCase = (str) => {
    let arr = str.split("-");
    let capital = arr.map((item, index) =>
      index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item
    );
    return capital.join("");
  };

  getCategory = () => {
    if (this.props.category && this.props.category.toLowerCase) {
      return this.props.category.toLowerCase();
    } else {
      if (this.props.categories) {
        const categories = this.props.categories || [];
        return categories.reduce((accu, category) => {
          category = this.convertStringToKebabCase(category);
          if (
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.category &&
            this.props.match.params.category.toLowerCase &&
            category.toLowerCase &&
            this.props.match.params.category.toLowerCase() ===
              category.toLowerCase()
          ) {
            accu = category.toLowerCase();
            accu = this.camelCase(category.toLowerCase());
          }
          return accu;
        }, null);
      }
    }
  };

  getPattern = (theme) => {
    const category = this.getCategory();
    // Takes the pattern imports and organizes them into sorted objects based on category and theme

    const genericPatterns = {
      "theme-1": generic1,
      "theme-2": generic2,
      "theme-3": generic3,
      "theme-4": generic4,
      "theme-5": generic5,
    };

    const siteManagementPatterns = {
      "theme-1": siteManagement1,
      "theme-2": siteManagement2,
      "theme-3": siteManagement3,
      "theme-4": siteManagement4,
      "theme-5": siteManagement5,
    };

    const layoutPatterns = {
      "theme-1": layout1,
      "theme-2": layout2,
      "theme-3": layout3,
      "theme-4": layout4,
      "theme-5": layout5,
    };

    const mixedRealityPatterns = {
      "theme-1": mixedReality1,
      "theme-2": mixedReality2,
      "theme-3": mixedReality3,
      "theme-4": mixedReality4,
      "theme-5": mixedReality5,
    };

    const scanningPatterns = {
      "theme-1": scanning1,
      "theme-2": scanning2,
      "theme-3": scanning3,
      "theme-4": scanning4,
      "theme-5": scanning5,
    };

    const toolTrackingPatterns = {
      "theme-1": toolTracking1,
      "theme-2": toolTracking2,
      "theme-3": toolTracking3,
      "theme-4": toolTracking4,
      "theme-5": toolTracking5,
    };

    const patterns = {
      generic: genericPatterns,
      siteManagement: siteManagementPatterns,
      layout: layoutPatterns,
      mixedRealityVisualization: mixedRealityPatterns,
      scanning: scanningPatterns,
      toolTracking: toolTrackingPatterns,
    };

    // Do the thing
    const activePattern = patterns[category];

    // Check to see if the category in props matches a key in the patterns object
    if (activePattern) {
      return activePattern[theme];
    } else {
      // If not, return a random category pattern based on the appropriate theme
      const thisPattern = generic1;

      if (patterns[thisPattern] && patterns[thisPattern][theme]) {
        return patterns[thisPattern][theme];
      } else {
        return genericPatterns[theme];
      }
    }
  };

  getBackgroundData = (theme, index) => {
    return {
      image: this.getPattern(theme, index),

      height: 1800,

      width: 3600,

      position: index === 1 ? "top-right" : "top-left",
    };
  };

  renderHeroOne = (data = {}, index, orgIndex) => {
    const { content } = formatHeroOne(
      data,
      this.props.type,
      this.props.demoLabel
    );
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Hero1 key={`${index}-${orgIndex}`} content={content} motion />
      </Element>
    );
  };

  renderHeroTwo = (data = {}, index, orgIndex) => {
    const { content, theme } = formatHeroTwo(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Hero2
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          motion
        />
      </Element>
    );
  };

  renderHeroThree = (data = {}, index, orgIndex) => {
    const { content, theme } = formatHeroThree(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Hero3
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          motion
        />
      </Element>
    );
  };

  renderHeroFour = (data = {}, index, orgIndex) => {
    const { content, theme } = formatHeroFour(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Hero4
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          motion
        />
      </Element>
    );
  };

  renderHeroFive = (data = {}, index, orgIndex) => {
    const { content, theme } = formatHeroFive(
      data,
      this.props.location,
      this.props.productType,
      this.props.demoLabel
    );
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Hero5
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          motion
        />
      </Element>
    );
  };

  renderHeroSix = (data = {}, index, orgIndex) => {
    const { content, theme } = formatHeroSix(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Hero6
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          motion
        />
      </Element>
    );
  };

  renderFeatOne = (data = {}, index, orgIndex) => {
    const { content, theme, parallax } = formatFeatOne(data);
    let newTheme;

    if (this.props.type === "category") {
      newTheme = "theme-4";
    } else if (this.props.type === "persona") {
      newTheme = "theme-4";
    }

    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Feat1
          key={`${index}-${orgIndex}`}
          content={content}
          theme={newTheme ? newTheme : theme}
          parallax={parallax}
          background={
            this.getBackgroundData(newTheme, index)
              ? this.getBackgroundData(newTheme, index)
              : this.getBackgroundData(theme, index)
          }
          parallaxPosition={this.props.offsetY}
          motion
        />
      </Element>
    );
  };

  renderFeatTwo = (data = {}, index, orgIndex) => {
    if (!data.list) {
      data.list = [];
    }

    if (
      data.list &&
      data.list[0] &&
      data.list[0].type &&
      data.list[0].type === "m_icon_list"
    ) {
      return this.renderFeatTwoB(data, index, orgIndex);
    } else {
      return this.renderFeatTwoA(data, index, orgIndex);
    }
  };

  renderFeatTwoA = (data = {}, index, orgIndex) => {
    const { content, theme, background, parallax } = formatFeatTwoA(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Feat2A
          key={`${index}-${orgIndex}`}
          image={content.image}
          content={content}
          header={content.header}
          text={content.text}
          theme={theme}
          parallax={parallax}
          background={background}
          motion
        >
          {this.renderAccordionFolds(content.children)}
        </Feat2A>
      </Element>
    );
  };

  renderAccordionFolds = (data) => {
    return data.map((fold) => {
      return (
        <AccordionFold header={fold.header} key={fold.header}>
          <p>{fold.text}</p>
        </AccordionFold>
      );
    });
  };

  renderFeatTwoB = (data = {}, index, orgIndex) => {
    const { content, theme, background, parallax } = formatFeatTwoB(data);
    let newTheme;

    if (this.props.type === "product-page") {
      newTheme = "theme-2";
    }
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Feat2B
          key={`${index}-${orgIndex}`}
          content={content}
          header={content.header}
          text={content.text}
          theme={newTheme ? newTheme : theme}
          parallax={parallax}
          background={background}
          motion
        />
      </Element>
    );
  };

  renderFeatThree = (data = {}, index, orgIndex) => {
    const { content, theme, background, parallax } = formatFeatThree(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Feat3
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          parallax={parallax}
          background={background}
          motion
        />
      </Element>
    );
  };

  renderFeatFour = (data = {}, index, orgIndex) => {
    const { content, theme, parallax } = formatFeatFour(data);
    let newTheme;

    if (this.props.type === "homepage") {
      newTheme = "theme-4";
    }

    if (this.props.type === "product-page") {
      newTheme = "theme-5";
    }

    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Feat4
          key={`${index}-${orgIndex}`}
          content={content}
          theme={newTheme ? newTheme : theme}
          parallax={parallax}
          background={
            newTheme
              ? this.getBackgroundData(newTheme, index)
              : this.getBackgroundData(theme, index)
          }
          parallaxPosition={this.props.offsetY}
          motion
        />
      </Element>
    );
  };

  renderFeatFive = (data = {}, index, orgIndex) => {
    const { content, theme, parallax } = formatFeatFive(data);
    let newTheme;

    if (this.props.type === "product-page") {
      newTheme = "theme-3";
    }
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Feat5
          content={content}
          theme={newTheme ? newTheme : theme}
          background={
            newTheme
              ? this.getBackgroundData(newTheme, index)
              : this.getBackgroundData(theme, index)
          }
          parallaxPosition={this.props.offsetY}
          motion
          parallax={parallax}
        />
      </Element>
    );
  };

  renderFeatSix = (data = {}, index, orgIndex) => {
    const { content, theme, background, parallax } = formatFeatSix(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Feat6
          key={`${index}-${orgIndex}`}
          content={content}
          background={background}
          motion
          theme={theme}
          parallax={parallax}
        />
      </Element>
    );
  };

  renderFeatSeven = (data = {}, index, orgIndex) => {
    const { content, theme, background, parallax } = formatFeatSeven(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <Feat7
          key={`${index}-${orgIndex}`}
          content={content}
          background={background}
          motion
          theme={theme}
          parallax={parallax}
        />
      </Element>
    );
  };

  renderListFeatOne = (data = {}, index, orgIndex) => {
    const { content, theme, background, parallax } = formatListFeatOne(data);
    return (
      <Element
        name={`#${content.sectionName}`}
        key={`${content.header || "feat1"}-${index}`}
      >
        <ListFeat
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          background={background}
          motion
          parallax={parallax}
        />
      </Element>
    );
  };

  renderListFeatTwo = (data = {}, index, orgIndex) => {
    const { content, theme } = formatListFeatTwo(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <ListFeat
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          motion
        />
      </Element>
    );
  };

  renderListFeatThree = (data = {}, index, orgIndex) => {
    const { content, theme, parallax } = formatListFeatThree(
      data,
      this.props.learnMoreLabel
    );
    let newTheme;

    if (this.props.type === "category") {
      newTheme = "theme-3";
    }

    if (this.props.type === "persona" && orgIndex === 2) {
      newTheme = "theme-3";
    }

    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <ListFeat
          key={`${index}-${orgIndex}`}
          content={content}
          theme={newTheme ? newTheme : theme}
          background={
            this.getBackgroundData(newTheme, index)
              ? this.getBackgroundData(newTheme, index)
              : this.getBackgroundData(theme, index)
          }
          parallaxPosition={this.props.offsetY}
          motion
          parallax={parallax}
        />
      </Element>
    );
  };

  renderListFeatFour = (data = {}, index, orgIndex) => {
    const { content, theme, background, parallax } = formatListFeatFour(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <ListFeat
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          background={background}
          motion
          parallax={parallax}
        />
      </Element>
    );
  };

  renderListFeatFive = (data = {}, index, orgIndex) => {
    const { content, theme, background, parallax } = formatListFeatFive(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <ListFeat
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          background={background}
          motion
          parallax={parallax}
        />
      </Element>
    );
  };

  renderListFeatSix = (data = {}, index, orgIndex) => {
    const { content, theme } = formatListFeatSix(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <ListFeat
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          motion
        />
      </Element>
    );
  };

  renderListFeatSeven = (data = {}, index, orgIndex) => {
    const { content, theme, background, parallax } = formatListFeatSeven(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <ListFeat
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          background={background}
          motion
          parallax={parallax}
        />
      </Element>
    );
  };

  renderTeaserListFeatThree = (data = {}, index, orgIndex) => {
    const { content, theme } = formatTeaserListFeatThree(
      data,
      this.props.type,
      this.props.pathInfo,
      this.props.learnMoreLabel
    );
    content.sort = this.props.sort;
    if (content.cards) {
      const productsInRegion = content.cards.filter((region = {}) => {
        return (
          (region && !region.regions.length) ||
          region.regions.includes(this.props.region)
        );
      });
      content.cards = productsInRegion;
    }

    // filters out all the sticky cards and put them into a new array
    const stickyCards = content.cards.filter((card) => {
      return card.sticky === true;
    });

    // if stickyCards has elements, we want to loop through the stickyCards array and remove the elements from content.cards. We will add them to the front of the array later, this is the first step to that.
    if (stickyCards.length) {
      for (let i in stickyCards) {
        // pass in each indice of stickyCards into the removeAllElements method
        this.removeAllElements(content.cards, stickyCards[i]);
      }
    }

    // after we remove elements from the content.cards array, we need to place the removed elements to the beginning of the array, so that the featured cards show up first.
    if (stickyCards.length) {
      content.cards = [...stickyCards, ...content.cards];
    } else {
      content.sort = this.props.sort;
    }

    // if there are no sticky elements, cards will render as they normally would.

    let newTheme;

    if (this.props.type === "homepage") {
      newTheme = "theme-3";
    } else if (this.props.type === "category") {
      newTheme = "theme-2";
    } else if (this.props.type === "solutions") {
      newTheme = "theme-2";
    } else if (this.props.type === "persona" && orgIndex === 1) {
      newTheme = "theme-2";
    }

    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <ListFeat
          key={`${index}-${orgIndex}`}
          content={content}
          theme={newTheme ? newTheme : theme}
          motion
        />
      </Element>
    );
  };

  removeAllElements = (array, elem) => {
    let index = array.indexOf(elem);
    // we use a while loop in case there are duplicate elements in the array (content.cards). this seems very unlikely, but its better to check incase content was entered twice.
    while (index > -1) {
      array.splice(index, 1);
      index = array.indexOf(elem);
    }
  };

  renderHardwareTable = (data = {}, index, orgIndex) => {
    const content = formatHardwareTable(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <HardwareTable key={`${index}-${orgIndex}`} content={content} />
      </Element>
    );
  };

  handleDropdownSelectForMiniFeat = (selection, name) => {
    if (this.props.miniFeat && this.props.miniFeat.taxonomy_list) {
      const path = this.props.miniFeat.taxonomy_list.find((item) => {
        return (
          selection &&
          selection.toLowerCase &&
          item.name &&
          item.name.toLowerCase &&
          item.name.toLowerCase() === selection.toLowerCase()
        );
      });

      this.props.history.push(path.machine);
    } else {
      this.props.history.push("/");
    }
  };

  renderMinifeatDropdown = (data = {}, index, orgIndex) => {
    const content = formatMiniFeatDropDown(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <MiniFeatDropdown
          key={`${index}-${orgIndex}`}
          content={content}
          dropdowns={content.dropdowns}
          handleDropdownSelect={this.handleDropdownSelectForMiniFeat}
        />
      </Element>
    );
  };

  renderListFeatCustom = (data = {}, index, orgIndex) => {
    const { content, theme } = formatListFeatCustom(data);
    return (
      <Element
        key={`${index}-${orgIndex}`}
        name={data.anchor_toggle && data.anchor_text ? data.anchor_text : null}
      >
        <ListFeat
          key={`${index}-${orgIndex}`}
          content={content}
          theme={theme}
          motion
          type="list_feat_custom"
        />
      </Element>
    );
  };

  getOrganism = (type) => {
    if (!type || typeof type !== "string") {
      return null;
    }
    const organismRefs = {
      hero_1: this.renderHeroOne,
      hero_2: this.renderHeroTwo,
      hero_3: this.renderHeroThree,
      hero_4: this.renderHeroFour,
      hero_5: this.renderHeroFive,
      hero_6: this.renderHeroSix,
      feat_1: this.renderFeatOne,
      feat_2: this.renderFeatTwo,
      feat_3: this.renderFeatThree,
      feat_4: this.renderFeatFour,
      feat_5: this.renderFeatFive,
      feat_6: this.renderFeatSix,
      feat_7: this.renderFeatSeven,
      listfeat_1: this.renderListFeatOne,
      listfeat_2: this.renderListFeatTwo,
      listfeat_3: this.renderListFeatThree,
      listfeat_4: this.renderListFeatFour,
      listfeat_5: this.renderListFeatFive,
      listfeat_6: this.renderListFeatSix,
      listfeat_7: this.renderListFeatSeven,
      custom_feat_1: this.renderHardwareTable,
      custom_feat_2: this.renderMinifeatDropdown,
      drupal_view: this.renderTeaserListFeatThree,
      listfeat_custom: this.renderListFeatCustom,
    };
    return organismRefs[type];
  };

  render() {
    return <Fragment>{this.renderOrganisms()}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    offsetY: state.offsetY,
    learnMoreLabel: state.learnMoreLabel,
  };
};

export default connect(mapStateToProps, null)(withRouter(PageBuilder));

PageBuilder.propTypes = {
  name: PropTypes.string,
  content: PropTypes.array,
  offsetY: PropTypes.number,
  type: PropTypes.string,
  region: PropTypes.string,
};
