import basicFetch from "./basicFetch";
import formatSolutionsData from "../methods/formatSolutionsData";

export default async (defaultText, url, type) => {
  const response = await basicFetch(url);
  let data;
  if (!type || type === "products") {
    data = formatProductData(response.data[0].content_sections, defaultText);
  } else if (type === "solutions") {
    return formatSolutionsData(response.data[0].content_sections, defaultText);
  }

  const innerPageNavLinks = data.reduce((accu, orgGroup) => {
    orgGroup.forEach((item) => {
      if (!accu.includes(item.anchor_text) && item.anchor_text) {
        accu.push({ text: item.anchor_text, url: item.anchor_text });
      }
    });
    return accu;
  }, []);
  const metadata =
    response && response.data && response.data[0] && response.data[0].metatags
      ? response.data[0].metatags
      : null;
  const innerPageNavData = {
    pageTitle: response.data[0].page_name,
    links: innerPageNavLinks,
  };
  return { data, innerPageNavData, metadata };
};

const formatProductData = (data, defaultText) => {
  const categories = data.plp_categories;
  categories[0].type = "listfeat_3";
  categories[0].list = categories[0].values.map((val) => {
    return {
      header: val.teaser_title,
      text: val.teaser_desc || null,
      image: { url: val.teaser_img.source, altText: val.teaser_img.alt },
      link: { url: val.teaser_url, text: defaultText || "Learn More" },
    };
  });
  return Object.values(data);
};
