import React, { Component } from "react";
import fetchProduct from "../../utils/async/fetchProduct";
import PageBuilder from "../../components/PageBuilder/PageBuilder";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
} from "../../actions";
import ErrorPage from "../ErrorPage/ErrorPage";
import Loading from "../../components/Loading/Loading";
//import * as Sentry from "@sentry/browser";
import backendURL from "../../utils/helpers/backendURL";
import RegionBlockPage from "../../pages/RegionBlockPage/RegionBlockPage";
import regionBlockFetch from "../../utils/async/regionBlockFetch";
import PropTypes from "prop-types";
import "./ProductPage.scss";

class ProductPage extends Component {
  constructor() {
    super();
    this.state = {
      content: null,
      regionBlocked: false,
    };
  }
  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  getLanguage = () => {
    return `${this.props.language || "en"}/`;
  };

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getData = async () => {
    this.setLoading();

    const url = this.getUrl();
    try {
      const {
        content,
        innerPageNavData,
        metadata,
        regions,
        productType,
      } = await fetchProduct(url);

      const regionBlockContent = await regionBlockFetch(
        `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/region-blocked/$`
      );
      this.changeRegionClickEvent(regionBlockContent);

      this.setState({ regionBlockContent: regionBlockContent });
      this.checkForRegion(this.props.region, regions);

      this.setState({ content, regions: regions, productType: productType });
      this.props.setInnerPageNav(innerPageNavData);
      this.props.setMetadata(metadata);
      this.setSuccess();
    } catch (error) {
      console.log(error);
      this.setError();
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(error);
      // }
    }
  };

  changeRegionClickEvent = (content) => {
    if (content.ctas && content.ctas.ctaTwo) {
      content.ctas.ctaTwo.onClick = this.props.toggleRegionLanguageDeployed;
    }
  };

  checkForRegion = (currentRegion, regionOfProduct = []) => {
    let unblockedRegion;
    if (!currentRegion && !regionOfProduct) {
      return;
    }
    // this checks if the region of the product matches the region of the user and returns a boolean

    if (Array.isArray(regionOfProduct)) {
      unblockedRegion = regionOfProduct.find((region) => {
        return region === currentRegion;
      });
    }

    // this says if there are NO regions, do not block.
    if (!regionOfProduct.length) {
      this.setState({ regionBlocked: false });
    }
    if (!unblockedRegion) {
      this.setState({ regionBlocked: true });
    } else {
      this.setState({ regionBlocked: false });
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  getUrl = () => {
    if (
      this.props.admin &&
      this.props.category &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.translation
    ) {
      return `${backendURL}/${this.props.match.params.translation}/api-v1/GetNodeByURL/products$${this.props.match.params.productName}/$`;
    } else if (this.props.admin && this.props.match.params.translation) {
      return `${backendURL}/${this.props.match.params.translation}/api-v1/GetNodeByURL/product$${this.props.match.params.productName}/$`;
    } else if (this.props.category) {
      return `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/products$${
        this.props.match.params.productName
      }/$`;
    } else {
      return `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/product$${
        this.props.match.params.productName
      }/$`;
    }
  };

  renderOptions = () => {
    // renders pagebuilderpage if all conditionals are met
    const { status, language, admin } = this.props;
    const { regionBlocked, content, productType } = this.state;
    if (
      (status === "success" &&
        content &&
        content !== "404" &&
        !regionBlocked) ||
      (status === "success" &&
        content &&
        content !== "404" &&
        regionBlocked &&
        admin)
    ) {
      return (
        <div className="ftg-product-page">
          <PageBuilder
            type="product-page"
            content={content}
            language={language}
            productType={productType}
            location={this.getLocation()}
            demoLabel={this.props.demoLabel}
          />
        </div>
      );
    } else {
      return;
    }
  };

  getLocation = () => {
    const { history } = this.props;

    if (history && history.location && history.location.pathname) {
      return history.location.pathname;
    } else {
      return null;
    }
  };

  render() {
    const { content, regionBlocked } = this.state;
    const { status } = this.props;
    return (
      <section
        className={`ftg-product-page-wrapper ${status === "loading" &&
          "ftg-product-page-wrapper--loading"}`}
      >
        {status === "loading" && <Loading />}
        {status === "success" && regionBlocked && !this.props.admin && (
          <RegionBlockPage
            content={this.state.regionBlockContent}
            language={this.props.language}
            toggleRegionLanguageDeployed={
              this.props.toggleRegionLanguageDeployed
            }
          />
        )}
        {this.renderOptions()}
        {status === "error" && (
          <ErrorPage type="500" history={this.props.history} />
        )}

        {status === "success" && content && content === "404" && (
          <ErrorPage type="404" history={this.props.history} />
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  region: state.region,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(updateStatus(status)),
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
  setMetadata: (metadata) => dispatch(updateMetadata(metadata)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);

ProductPage.propTypes = {
  status: PropTypes.string,
  language: PropTypes.string,
  setInnerPageNav: PropTypes.func,
  setStatus: PropTypes.func,
  setMetadata: PropTypes.func,
  region: PropTypes.string,
  admin: PropTypes.bool,
  toggleRegionLanguageDeployed: PropTypes.func,
};
