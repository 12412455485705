import React, { Component } from "react";
import "./SupportEmailPage.scss";
import { connect } from "react-redux";
import backendURL from "../../utils/helpers/backendURL";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
} from "../../actions";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";
//import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";
import fetchSupportEmailPage from "../../utils/async/fetchSupportEmailPage";
import postSupportEmailPage from "../../utils/async/postSupportEmailPage";
import { MarketoForm } from "luna-one";

class SupportEmailPage extends Component {
  constructor() {
    super();
    this.state = {
      content: null,
      submitting: false,
      entries: [],
      warnings: [],
      reCAPTCHAValue: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  getLanguage = () => {
    return `${this.props.language || "en"}/`;
  };

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getData = async () => {
    this.setLoading();

    const url = this.getUrl();
    try {
      const {
        content,
        innerPageNavData,
        metadata,
      } = await fetchSupportEmailPage(url);
      this.props.setInnerPageNav(innerPageNavData);
      this.props.setMetadata(metadata);
      this.setState({ content });
      this.setSuccess();
    } catch (error) {
      console.log(error);
      this.setError();
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(error);
      // }
    }
  };

  getUrl = () => {
    const endpoint = "data-structure$support-email-form/$";
    const language = this.props.language || "en";
    return `${backendURL}/${language}/api-v1/getNodeByURL/${endpoint}`;
  };

  handleChange = (e) => {
    const targetName = e.target && e.target.name ? e.target.name : null;
    const targetValue = e.target && e.target.value ? e.target.value : null;
    // Update text inputs in state
    const newEntries = [...this.state.entries];
    const entry = newEntries.find((entry) => {
      return entry.name === targetName;
    });

    const correspondingInput = this.state.content.inputs.find((input) => {
      return (
        input.name && input.name.toLowerCase() === targetName.toLowerCase()
      );
    });

    // The inputs are generated progromatically and are not hard-coded, and so they live inside an array

    if (entry) {
      entry.value = targetValue;
      this.updateEntries(newEntries);
    } else {
      this.updateEntries([
        ...this.state.entries,
        {
          name: targetName,
          value: targetValue,
          machineName:
            correspondingInput && correspondingInput.machineName
              ? correspondingInput.machineName
              : null,
        },
      ]);
    }
  };

  updateEntries = (entries) => {
    // Takes in the new entries array and sets it into state
    this.setState({ entries });
  };

  handleSubmit = () => {
    // Check to see if any required inputs/dropdowns are missing input/selection
    if (this.state.submitting) {
      return;
    }

    const entries = this.state.entries || [];

    const warnings = this.state.content.inputs.reduce((accu, input) => {
      if (input.required) {
        const match = entries.find((entry) => {
          return (
            entry.machineName &&
            entry.machineName.toLowerCase &&
            input.machineName &&
            input.machineName.toLowerCase &&
            entry.machineName.toLowerCase() === input.machineName.toLowerCase()
          );
        });

        if (input.email && match) {
          const validatedEmail = this.validateEmail(match.value);
          if (!validatedEmail) {
            accu.push(input.name);
          }
        }
        if (!match) {
          accu.push(input.name);
        }
      }
      return accu;
    }, []);

    // Pass references to required inputs with missing fields into state and return
    if (warnings.length > 0) {
      this.updateWarnings(warnings);
      return;
    }

    if (!this.state.reCAPTCHAValue) {
      return;
    }

    this.proceedToSubmit();
  };

  validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  updateWarnings = (warnings) => {
    // Set required forms with missing data into state to warn the user when they have left the field empty
    this.setState({ warnings });
  };

  proceedToSubmit = async () => {
    this.setSubmitting();

    try {
      const response = await postSupportEmailPage(
        this.state.entries,
        this.state.reCAPTCHAValue
      );
      if (response) {
        this.props.history.push(
          `/${this.props.language || "en"}/contact-support/success`
        );
      } else {
        this.props.history.push(
          `/${this.props.language || "en"}/contact-support/error`
        );
      }
    } catch (error) {
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(error);
      // }
      console.log(error);
      this.props.history.push(
        `/${this.props.language || "en"}/contact-support/error`
      );
    }
  };

  onReCaptchaChange = (value) => {
    // reCAPTCHAValue refers to the string that Google returns after completing the reCAPTCHA box. It's a one-time use key that expires after about a minute. We feed it to the backend when we submit the form, which in turn feeds it to Google.
    this.setState({ reCAPTCHAValue: value });
  };

  onReCaptchaExpiration = () => {
    console.log("farts");
    this.setState({ reCAPTCHAValue: null });
  };

  setSubmitting = () => {
    this.setState({ submitting: true });
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  render() {
    const { content } = this.state;
    const { status } = this.props;
    return (
      <section className="ftg-support-email-page">
        {status === "loading" && <Loading />}
        {status === "error" && (
          <ErrorPage type="500" history={this.props.history} />
        )}
        {status === "success" && content && content !== "404" && (
          <div>
            {content.nonInputs.header && (
              <p className="ftg-support-email-page__header">
                {content.nonInputs.header}
              </p>
            )}
            {content.nonInputs.subHeader && (
              <p className="ftg-support-email-page__subHeader">
                {content.nonInputs.subHeader}
              </p>
            )}
            <MarketoForm
              inputs={content.inputs}
              warnings={this.state.warnings}
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
              entries={this.state.entries}
              submitting={this.state.submitting}
              reCAPTCHASiteKey="6Lc-nv8UAAAAAC4iXLHGL843QehvAQ62YC6mU6S8"
              reCAPTCHAChange={this.onReCaptchaChange}
              reCAPTCHAExpiration={this.onReCaptchaExpiration}
            />
          </div>
        )}
        {status === "success" && content && content === "404" && (
          <ErrorPage type="404" history={this.props.history} />
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  region: state.region,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(updateStatus(status)),
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
  setMetadata: (metadata) => dispatch(updateMetadata(metadata)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportEmailPage);

SupportEmailPage.propTypes = {
  status: PropTypes.string,
  language: PropTypes.string,
  setInnerPageNav: PropTypes.func,
  setStatus: PropTypes.func,
  setMetadata: PropTypes.func,
  region: PropTypes.string,
  type: PropTypes.string,
};
