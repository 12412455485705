import React, { Component } from "react";
import "./App.scss";
import { NavBar, BrowserSupportBanner } from "luna-one";
import Routing from "../Routing/Routing";
import { withRouter } from "react-router-dom";
import fetchLanguages from "../../utils/async/fetchLanguages";
import FooterHandler from "../../components/FooterHandler/FooterHandler";
import HelmetHandler from "../../components/HelmetHandler/HelmetHandler";
import fetchMenus from "../../utils/async/fetchMenus";
import fetchApplicationData from "../../utils/async/fetchApplicationData";
import mockNavBarData from "../../utils/mock-data/mockNav";
import { connect } from "react-redux";
import { checkForSavedLanguage, checkForSavedRegion } from "./AppMethods";
import fetchRegions from "../../utils/async/fetchRegions";
import fetchUserLocation from "../../utils/async/fetchUserLocation";
import fetchFooterData from "../../utils/async/fetchFooterData";
import fetchRoutesData from "../../utils/async/fetchRoutesData";
import fetchCategories from "../../utils/async/fetchCategories";
import fetchBrowserBanner from "../../utils/async/fetchBrowserBanner";
import {
  handleGetItem,
  handleSetItem,
  handleClearLocalStorage,
} from "../../utils/localStorage/localStorageHandler";

//import * as Sentry from "@sentry/browser";

import formatRegionLanguageSelectorData from "../../utils/methods/formatRegionLanguageSelectorData";
import {
  updateLanguage,
  setCategories,
  setLanguageOptions,
  updateRegion,
  setFourOhFourData,
  setFiveHundredData,
  setRegions,
  setLearnMoreLabel,
} from "../../actions";
import fetchErrorData from "../../utils/async/fetchErrorData";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import Loading from "../../components/Loading/Loading";
import hardCoded500 from "../../utils/helpers/hardCoded500";
// TODO - replace these with data from Drupal

class App extends Component {
  constructor() {
    super();
    this.state = {
      mainStatus: "loading",
      navOptions: null,
      divisionName: null,
      searchPlaceholder: null,
      rightLinks: null,
      navCTA: null,
      footerData: null,
      regions: null,
      routesData: [],
      innerPageNav: null,
      regionLanguageDeployed: false,
      lastScrollPosition: 0,
      navBarPositonChanged: false,
    };
  }

  componentDidMount() {
    this.clearLocalStorageOnPageLoad();
    this.kickOff();
    if (this.checkForIE()) {
      window.addEventListener("scroll", this.checkForScroll);
    }
  }

  componentWillUnmount() {
    // will only call this method IF browser is IE
    if (this.checkForIE()) {
      window.removeEventListener("scroll", this.checkForScroll);
    }
  }

  clearLocalStorageOnPageLoad = () => {
    // evalutes the versioned local storage data for the site
    const version = handleGetItem("ftg-version");
    const currentVersion = process.env.REACT_APP_VERSION;

    if (version && version !== currentVersion) {
      // Leaving this here on purpose - will remove later if this is stable.
      console.log("Clearing local storage for new version");
      handleClearLocalStorage();

      // Will eventually switch back to these from handleClearLocalStorage()
      // handleRemoveItem("ftg-language");
      // handleRemoveItem("ftg-region");

      this.setVersion(currentVersion);
    } else if (!version) {
      // Leaving this here on purpose - will remove later if this is stable.
      console.log("Clearing local storage for new version");
      handleClearLocalStorage();
      this.setVersion(currentVersion);
    }
  };

  setVersion = (version) => {
    // This is for clearing local storage as the app updates //
    // updates the current version of FTG for cache warning/clearing purposes //
    handleSetItem("ftg-version", version);
  };

  checkForIE = () => {
    const userAgent = navigator.userAgent;

    // MSIE used to detect old browsers and Trident used to newer ones (checks for IE11)
    const is_ie =
      userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1;

    return is_ie;
  };

  kickOff = () => {
    this.setPreRenderNotReady();
    this.initialLanguageAndRegionSet();
    this.getSiteData();
  };

  setPreRenderNotReady = () => {
    window.prerenderReady = false;
  };

  checkForScroll = (e) => {
    const position = window.scrollY;
    if (position > this.state.lastScrollPosition + 100) {
      this.setState({
        navBarPositonChanged: true,
        lastScrollPosition: position,
        isOpen: null,
      });
    } else if (position < this.state.lastScrollPosition) {
      this.setState({
        navBarPositonChanged: false,
        lastScrollPosition: position,
      });
    }
  };

  toggleRegionLanguageDeployed = () => {
    // Toggles the Region/Language switcher modal
    this.setState({
      regionLanguageDeployed: !this.state.regionLanguageDeployed,
    });
  };

  closeModal = () => {
    // Closes the Region/Language switcher modal
    this.setState({ regionLanguageDeployed: false });
  };

  getLanguage = () => {
    return `${this.props.language || "en"}/`;
  };

  initialLanguageAndRegionSet = async () => {
    try {
      // fetch the language options data from Drupal
      const languages = await fetchLanguages();
      // fetch the region options data from Drupal
      const regions = await fetchRegions(this.getLanguage());
      // Query localStorage, then the browser language preference
      const userLocation = await fetchUserLocation();

      // Check the browser/local storage for language/region preferences
      const region = checkForSavedRegion(userLocation, regions);
      const language = checkForSavedLanguage(languages);
      const regionList = this.formatRegionList(regions);
      this.props.setRegions(regionList);
      this.props.setLanguages(languages);
      this.props.setLanguage(language);
      this.props.setRegion(region);
      this.setState({ regions });
      // This forwards the user if the language in the address bar doesn't match their preferred/browser language
      this.forwardUserForWrongLanguage(languages);
    } catch (e) {
      console.log(e);
      this.setMainStatusError();
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(e);
      // }
    }
  };

  formatRegionList = (regions = []) => {
    return regions.map((region) => {
      return {
        name: region.name,
        machine: region.machine,
      };
    });
  };

  getSiteData = async (newLanguageCode) => {
    // newLanguageCode is passed only if getSiteData is being called as a result of a language change
    // Needs to be passed due to asynchronous state setting, can't just rely on what's in state as it won't be updated yet
    this.setMainStatusLoading();

    try {
      // fetch the main navigation options data from Drupal
      const navOptions = await fetchMenus(
        // use the newLanguageCode if getSiteData is being called as a result of the language changing
        // Otherwise, just get the language out of redux
        newLanguageCode ? `${newLanguageCode}/` : this.getLanguage()
      );

      // if (this.checkForIE()) {
      const browserMessage = await fetchBrowserBanner(this.getLanguage());
      this.setState({ browserMessage });
      // }

      // fetch general application data from Drupal
      const {
        divisionName,
        searchPlaceholder,
        rightLinks,
        navCTA,
        learnMoreLabel,
        demoLabel,
      } = await fetchApplicationData(this.getLanguage());

      // fetch footer data from Drupal
      const footerData = await fetchFooterData(this.getLanguage());
      // fetch all routes from Drupal
      const routesData = await fetchRoutesData(this.getLanguage());

      const categories = await fetchCategories(this.getLanguage());
      this.props.setCategories(categories);

      const { fourOhFourData, fiveHundredData } = await fetchErrorData(
        this.getLanguage()
      );

      // fetch the 404 page from Drupal

      // 'mainStatus' refers to the main loading process for the application and is only stored in App.js, as opposed to the general 'status' which is stored in Redux and is used for conditionally rendering page and footer data
      this.setState({
        navOptions,
        divisionName,
        searchPlaceholder,
        rightLinks,
        navCTA,
        footerData,
        routesData,
        demoLabel,
      });

      this.props.setLearnMoreLabel(learnMoreLabel);
      this.props.setFourOhFour(fourOhFourData);
      this.props.setFiveHundred(fiveHundredData);
      this.setMainStatusSuccess();
    } catch (e) {
      console.log(e);
      this.setMainStatusError();
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(e);
      // }
    }
  };

  setLanguageAndRegion = (data = {}) => {
    let languageChange = false;
    // Used if the language is changed
    let newLanguageCode;
    // cloning the old language
    const oldLanguageCode = JSON.parse(JSON.stringify(this.props.language));
    if (data.language) {
      newLanguageCode = data.language.substring(0, 2).toLowerCase();

      // Determine if the language has changed
      if (newLanguageCode !== this.props.language) {
        this.props.setLanguage(newLanguageCode);
        languageChange = true;
      }
    }

    if (data.region) {
      // Determine if the region has changed
      if (data.region !== this.props.region) {
        this.props.setRegion(data.region);
        localStorage.setItem("ftg-region", data.region);
      }
    }

    // Only make a call for new site data if the language has changed
    if (languageChange) {
      this.forwardUserForNewLanguage(oldLanguageCode, newLanguageCode);
      this.getSiteData(newLanguageCode);
    }

    this.closeModal();
  };

  forwardUserForNewLanguage = (oldLanguageCode, newLanguageCode) => {
    // Fowards the user to the new address when the language changes
    localStorage.setItem("ftg-language", newLanguageCode);
    const { pathname } = this.props.location;

    if (
      pathname === `/${oldLanguageCode}` ||
      pathname === `/${oldLanguageCode}/`
    ) {
      // Only used if on the homepage
      const newPathname = pathname.replace(
        `/${oldLanguageCode}`,
        `/${newLanguageCode}`
      );

      // push the user to the new homepage with the new language prefix
      this.props.history.push(newPathname);
    } else {
      // Used if on any other page
      const newPathname = pathname.replace(
        `/${oldLanguageCode}/`,
        `/${newLanguageCode}/`
      );

      // push the user to the new page (not home) with the new language prefix
      this.props.history.push(newPathname);
    }
  };

  forwardUserForWrongLanguage = (languages) => {
    const { pathname } = this.props.location;

    // Check for normal match
    if (pathname.substring(0, 3) === `/${this.props.language}`) {
      if (pathname === `/${this.props.language}`) {
        // Check for homepage match
        return;
      } else if (pathname.substring(0, 4) === `/${this.props.language}/`) {
        // Check for non-homepage match
        return;
      } else {
        // Indicates that the pathname string begins with something like '/en' but has extra characters that don't fit ('/ensdflkj')
        const splitPath = pathname.split("/");
        splitPath[0] = this.props.language;
        const newPathname = `/${splitPath.join("/")}`;
        // fowards the user to the new address without replacing the language if, for some reason, the characters match
        this.props.history.push(newPathname);
      }
    } else if (this.checkForWrongLanguage(pathname, languages).result) {
      const wrongLanguageID = this.checkForWrongLanguage(pathname, languages)
        .id;

      if (pathname === `/${wrongLanguageID}`) {
        // indicates home page in wrong language
        this.props.history.push(`/${this.props.language}`);
      } else {
        // indicates non-home page in wrong language
        const pathnameClone = JSON.parse(JSON.stringify(pathname));
        const newPathName = pathnameClone.replace(
          pathnameClone.substring(1, 3),
          this.props.language
        );
        this.props.history.push(newPathName);
      }
    }
  };

  checkForWrongLanguage = (pathname, languages = []) => {
    // Determine if the the language prefix in the address bar does not match the preferred/browser language in redux
    return languages.reduce(
      (accu, language = {}) => {
        if (
          `/${language.id}` === pathname.substring(0, language.id.length + 1)
        ) {
          if (
            `/${language.id}` === pathname ||
            `/${language.id}/` === pathname.substring(0, 4)
          ) {
            accu.result = true;
            accu.id = language.id;
          }
        }
        return accu;
      },
      { result: false }
    );
  };

  getCurrentLanguage = () => {
    // Determine the current language and region from state and format the strings appropriately for the Navbar
    const region = this.props.region || "US/CA";

    // Create the language string for the navbar
    const language = `${
      this.props.language ? this.props.language.toUpperCase() : "EN"
    }: ${this.setFullLanguageName()}`;

    return { region, language };
  };

  setFullLanguageName = () => {
    // Grab the language code from Redux
    const language = this.props.language || "en";

    // Return the full language object from Redux based on the current lanugage code
    const activeLanguageObject = this.props.languages.find((lang) => {
      return lang.id === language;
    });

    if (activeLanguageObject) {
      // Return just the full name if present
      return activeLanguageObject.name;
    } else {
      return "";
    }
  };

  searchSubmit = async (string) => {
    // handles search submission from the navbar. The <SearchResultsPage> takes the search parameters out of the route and uses them to query Drupal for results.

    let formattedString = string.replace(" ", "%20");
    this.props.history.push(
      `/${this.props.language || "en"}/search/${formattedString}`
    );
  };

  setMainStatusSuccess = () => {
    this.setState({ mainStatus: "success" });
  };

  setMainStatusLoading = () => {
    this.setState({ mainStatus: "loading" });
  };

  setMainStatusError = () => {
    this.setState({ mainStatus: "error" });
  };

  render() {
    const { logo } = mockNavBarData;

    const {
      searchPlaceholder,
      navOptions,
      divisionName,
      navCTA,
      rightLinks,
    } = this.state;
    const { mainStatus, footerData } = this.state;
    const { metadata = {} } = this.props;
    return (
      <div className="App">
        {this.state.browserMessage && this.props.status === "success" && (
          <div
            className={
              this.state.navBarPositonChanged
                ? "browser-banner adjust-banner-position"
                : "browser-banner"
            }
          >
            <BrowserSupportBanner content={this.state.browserMessage} />
          </div>
        )}
        <HelmetHandler status={this.props.status} />
        {this.state.navOptions && (
          <NavBar
            regionLanguageDeployed={this.state.regionLanguageDeployed}
            toggleRegionLanguageDeployed={this.toggleRegionLanguageDeployed}
            closeModal={this.closeModal}
            searchPlaceholder={searchPlaceholder}
            currentLanguage={this.getCurrentLanguage()}
            searchSubmit={this.searchSubmit}
            rightLinks={rightLinks}
            divisionName={divisionName}
            navOptions={navOptions}
            navCTA={navCTA}
            utilityNav={this.props.innerPageNav || {}}
            setLanguage={this.setLanguageAndRegion}
            language={this.props.language}
            languageSelectorData={formatRegionLanguageSelectorData(
              this.props.languages,
              this.state.regions,
              this.state.divisionName,
              this.props.region,
              this.props.language
            )}
            logo={logo}
            location={this.props.location}
          />
        )}
        {mainStatus === "success" && (
          <Routing
            regions={this.props.regions}
            getSiteData={this.getSiteData}
            mainStatus={this.state.mainStatus}
            setMainStatusError={this.setMainStatusError}
            setMainStatusLoading={this.setMainStatusLoading}
            routesData={this.state.routesData}
            toggleRegionLanguageDeployed={this.toggleRegionLanguageDeployed}
            demoLabel={this.state.demoLabel}
          />
        )}
        {mainStatus === "loading" && <Loading />}
        {mainStatus === "error" && (
          <ErrorPage content={hardCoded500} mainError history={() => {}} />
        )}
        <FooterHandler footerData={footerData} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    languages: state.languages,
    language: state.language,
    region: state.region,
    metadata: state.metadata,
    status: state.status,
    innerPageNav: state.innerPageNav,
    regions: state.regions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRegion: (region) => dispatch(updateRegion(region)),
    setLanguage: (language) => dispatch(updateLanguage(language)),
    setLanguages: (options) => dispatch(setLanguageOptions(options)),
    setFourOhFour: (data) => dispatch(setFourOhFourData(data)),
    setFiveHundred: (data) => dispatch(setFiveHundredData(data)),
    setCategories: (categories) => dispatch(setCategories(categories)),
    setRegions: (regions) => dispatch(setRegions(regions)),
    setLearnMoreLabel: (label) => dispatch(setLearnMoreLabel(label)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
