import fpoSquare from "../../assets/images/fpoSquare.png";
import fpoBox from "../../assets/images/fpoBox.png";
// import { IconListItem, AccordionFold } from "terra-component-lib";

// TODO all Heroes need to be written in drupal
export const formatHeroOne = (hereOneData = {}, type, demoLabel = "Demo") => {
  const data = hereOneData || {};
  const ctas = {
    ctaOne: data && data.ctas && data.ctas[0] ? data.ctas[0] : null,
    ctaTwo: data && data.ctas && data.ctas[1] ? data.ctas[1] : null,
  };
  const images = {
    desktop: {
      url:
        data.image_large && data.image_large.source
          ? data.image_large.source
          : fpoBox,
      altText:
        data.image_large && data.image_large.alt ? data.image_large.alt : null,
    },
    tablet: {
      url:
        data.image_medium && data.image_medium.source
          ? data.image_medium.source
          : fpoBox,
      altText:
        data.image_medium && data.image_medium.alt
          ? data.image_medium.alt
          : null,
    },
    mobile: {
      url:
        data.image_small && data.image_small.source
          ? data.image_small.source
          : fpoBox,
      altText:
        data.image_small && data.image_small.alt ? data.image_small.alt : null,
    },
  };

  const content = {
    header: data.header,
    text: data.subheader,
    images,
    ctas,
  };
  return { content };
};

export const formatHeroOneImages = (heroOneImageData = {}) => {
  const data = heroOneImageData || {};
  const returnObj = {};

  if (data.image_large) {
    if (data.image_large && data.image_large.source) {
      returnObj.desktop = {
        url: data.image_large.source,
        altText: data.image_large.alt || "Hero",
      };
    }
  } else if (!data.image_large) {
    returnObj.desktop = null;
  }

  if (data.image_medium) {
    if (data.image_medium && data.image_medium.source) {
      returnObj.tablet = {
        url: data.image_medium.source,
        altText: data.image_medium.alt || "Hero",
      };
    }
  } else if (!data.image_medium) {
    returnObj.tablet = null;
  }

  if (data.image_small) {
    if (data.image_small && data.image_small.source) {
      returnObj.mobile = {
        url: data.image_small.source,
        altText: data.image_small.alt || "Hero",
      };
    }
  } else if (!data.image_small) {
    returnObj.mobile = null;
  }

  return returnObj;
};
export const formatHeroTwo = () => {};
export const formatHeroThree = () => {};
export const formatHeroFour = (
  heroFourData = {},
  location,
  productType,
  demoLabel
) => {
  const data = heroFourData || {};

  const checkForPrimaryCTA = () => {
    if (data.ctas && data.ctas[0] && data.ctas[0].text && data.ctas[0].url) {
      return true;
    }
  };

  const checkForDemo = (ctaData) => {
    if (ctaData && ctaData.url && ctaData.url.includes("demo")) {
      return true;
    } else {
      return false;
    }
  };

  const ctas = {
    ctaOne: checkForPrimaryCTA()
      ? data.ctas[0]
      : formatDemoCTA(location, demoLabel, productType) || null,
    ctaTwo:
      checkForPrimaryCTA() && checkForDemo(data.ctas[1])
        ? formatDemoCTA(location, data.ctas[1].text, productType)
        : data && data.ctas && data.ctas[1]
        ? data.ctas[1]
        : null,
  };
  // TODO bigger fpo image
  const content = {
    header: data.header,
    subHeader: data.subheader,
    text: data.description,
    image: {
      url:
        data.image_large && data.image_large.source
          ? data.image_large.source
          : data.image_medium && data.image_medium.source
          ? data.image_medium.source
          : data.image_small && data.image_small.source
          ? data.image_small.source
          : fpoBox,
      altText:
        data.image_large && data.image_large.alt
          ? data.image_large.alt
          : data.image_medium && data.image_medium.alt
          ? data.image_medium.alt
          : data.image_small && data.image_small.alt
          ? data.image_small.alt
          : null,
    },
    ctas,
    type: "hero_4",
  };

  const { theme } = formatPattern(data);
  return {
    theme,
    content,
  };
};

export const formatHeroFive = (
  heroFiveData = {},
  location,
  productType,
  demoLabel
) => {
  const data = heroFiveData || {};

  const checkForPrimaryCTA = () => {
    if (data.ctas && data.ctas[0] && data.ctas[0].text && data.ctas[0].url) {
      return true;
    }
  };

  const checkForDemo = (ctaData) => {
    if (ctaData && ctaData.url && ctaData.url.includes("demo")) {
      return true;
    } else {
      return false;
    }
  };

  const ctas = {
    ctaOne: checkForPrimaryCTA()
      ? data.ctas[0]
      : formatDemoCTA(location, demoLabel, productType) || null,
    ctaTwo:
      checkForPrimaryCTA() && checkForDemo(data.ctas[1])
        ? formatDemoCTA(location, data.ctas[1].text, productType)
        : data && data.ctas && data.ctas[1]
        ? data.ctas[1]
        : null,
  };
  // TODO bigger fpo image
  const content = {
    header: data.header,
    subHeader: data.subheader,
    text: data.description,
    image: {
      url:
        data.image_large && data.image_large.source
          ? data.image_large.source
          : fpoBox,
      altText:
        data.image_large && data.image_large.alt ? data.image_large.alt : null,
    },
    ctas,
    type: "hero_5",
  };

  const { theme } = formatPattern(data);
  return {
    theme,
    content,
  };
};

const formatDemoCTA = (location, demoLabel = "Request a Demo", productType) => {
  if (location && location.pathname && productType) {
    return {
      url: `${location.pathname}/demo`,
      text: demoLabel,
    };
  } else {
    return null;
  }
};

export const formatHeroSix = () => {};

export const formatFeatOne = (featOneData = {}, theme) => {
  const data = featOneData || {};

  const cta = {
    url:
      data && data.ctas && data.ctas[0] && data.ctas[0].url
        ? data.ctas[0].url
        : null,
    text:
      data && data.ctas && data.ctas[0] && data.ctas[0].text
        ? data.ctas[0].text
        : null,
  };

  const image = {
    url:
      data && data.image_large && data.image_large.source
        ? data.image_large.source
        : fpoBox,
    altText:
      data && data.image_large && data.image_large.alt
        ? data.image_large.alt
        : "fpo image",
  };
  const content = {
    header: data && data.header ? data.header : null,
    subHeader: data && data.subheader ? data.subheader : null,
    text: data && data.description ? data.description : null,
    cta,
    image,
  };
  return {
    content,
    theme,
  };
};

export const formatFeatTwoA = (featTwoAData = {}) => {
  const data = featTwoAData || {};
  const { list = [] } = data;
  const children = list[0] || null;

  const { theme, background, parallax } = formatPattern(data);
  const content = {
    header: data.intro_header,
    subHeader: data.intro_description,
    image: {
      url:
        data.image_large && data.image_large.source
          ? data.image_large.source
          : fpoBox,
      altText:
        data.image_large && data.image_large.alt ? data.image_large.alt : null,
    },
    children: formatFolds(children),
  };
  return {
    theme,
    content,
    background,
    parallax,
  };
};

export const formatFolds = (foldsData = []) => {
  const data = foldsData || [];

  return data.map((fold) => {
    return {
      header: fold.header || null,
      text: fold.text || null,
    };
  });
};

export const formatFeatTwoB = (featTwoBData = {}) => {
  const data = featTwoBData || {};
  const { list = [] } = data;
  const cards = list[0] && list[0].list ? list[0].list : [];

  const { theme, background, parallax } = formatPattern(data);
  const content = {
    header: data.intro_header ? data.intro_header : null,
    subHeader: data.intro_description ? data.intro_description : null,
    images: {
      desktop: {
        url:
          data.image_large && data.image_large.source
            ? data.image_large.source
            : fpoBox,
        altText:
          data.image_large && data.image_large.alt
            ? data.image_large.alt
            : null,
      },
    },
    cards: formatIcons(cards),
  };
  return {
    theme,
    content,
    background,
    parallax,
  };
};

export const formatIcons = (iconData) => {
  const data = iconData || [];
  return data.map((icon) => {
    return {
      header: icon.header || null,
      text: icon.text || null,
    };
  });
};

export const formatFeatThree = (data = {}) => {
  //   const content = {
  //     header: data.intro_header,
  //     subHeader: data.intro_description,
  //     cards: formatListFeatThreeCards(data.list)
  //     // need ctas
  //   };
  // };
  // const formatFeatThreeCards = data => {
  //   const keys = Object.keys(data);
  //   return keys.map(key => {
  //     return {
  //       header: data[key].header,
  //       text: data[key].description,
  //       image: {
  //         url:
  //           data[key].image && data[key].image.source
  //             ? data[key].image.source
  //             : fpoSquare,
  //         altText:
  //           data[key].image && data[key].image.alt
  //             ? data[key].image.alt
  //             : "card image"
  //       },
  //       button: {
  //         url: data[key].cta && data[key].cta.url ? data[key].cta.url : null,
  //         text: data[key].cta && data[key].cta.title ? data[key].cta.title : null
  //       }
  //     };
  //   });
};

export const formatFeatFour = (featFourData = {}) => {
  const data = featFourData || {};
  const content = {
    header: data && data.intro_header ? data.intro_header : null,
    subHeader: data && data.intro_description ? data.intro_description : null,
  };

  if (data.ctas) {
    content.ctas = formatFeatFourCTAs(data.ctas);
  }

  const { theme, parallax } = formatPattern(data);
  return {
    content,
    theme,
    parallax,
  };
};

export const formatFeatFourCTAs = (ctaData) => {
  const data = ctaData || [];

  const ctas = {};
  if (data[0]) {
    ctas.ctaOne = {
      url: data[0].url || null,
      text: data[0].text || null,
    };
  }
  if (data[1]) {
    ctas.ctaTwo = {
      url: data[1].url || null,
      text: data[1].text || null,
    };
  }
  return ctas;
};

export const formatFeatFive = (featFiveData = {}) => {
  const data = featFiveData || {};

  const content = {
    header: data.intro_header,
    subHeader: data.intro_description,
    thumbnail: {
      url:
        data.video && data.video.poster && data.video.poster.source
          ? data.video.poster.source
          : fpoBox,
      altText:
        data.video && data.video.poster && data.video.poster.alt
          ? data.video.poster.alt
          : "video thumbnail",
    },
    video: {
      url:
        data.video && data.video.code && data.video.source === "youtube"
          ? `https://www.youtube.com/embed/${data.video.code}`
          : null,
      allowFullScreen: true,
      uuid:
        data.video && data.video.code && data.video.source === "vidyard"
          ? data.video.code
          : null,
    },
    vidyard:
      data.video && data.video.source && data.video.source === "vidyard"
        ? true
        : false,
  };

  const { theme, parallax } = formatPattern(data);
  return {
    content,
    theme,
    parallax,
  };
};

export const formatFeatSix = (featSixData = {}) => {
  const data = featSixData || {};

  const quote = {
    text: data && data.quote_body ? data.quote_body : null,
    source: data && data.quote_author ? data.quote_author : null,
    sourceTitle:
      data && data.quote_author_title ? data.quote_author_title : null,
  };
  const image = {
    url:
      data.quote_image && data.quote_image.source
        ? data.quote_image.source
        : fpoSquare,
    altText:
      data.quote_image && data.quote_image.alt
        ? data.quote_image.alt
        : "Quote image",
  };

  const cta = {
    url: data.quote_cta && data.quote_cta.url ? data.quote_cta.url : null,
    text: data.quote_cta && data.quote_cta.text ? data.quote_cta.text : null,
  };

  const content = {
    quote,
    image,
    cta,
  };
  const { theme, background, parallax } = formatPattern(data);

  return {
    content,
    theme,
    parallax,
    background,
  };
};

export const formatFeatSeven = () => {};

export const formatListFeatOne = (listFeatOneData = {}) => {
  const data = listFeatOneData || {};

  const content = {
    header: data.intro_header,
    subHeader: data.intro_description,
    type: "list_feat_1",
  };

  if (data.list && data.list.length) {
    const { cardOne, cardTwo } = formatListFeatOneCards(data.list);
    content.cardOne = cardOne;
    content.cardTwo = cardTwo;
  }

  const { theme, background, parallax } = formatPattern(data);
  return {
    theme,
    background,
    parallax,
    content,
  };
};

export const formatListFeatOneCards = (listFeatOneCardData = []) => {
  const data = listFeatOneCardData || [];

  const list =
    data[Object.keys(data)[0]] && data[Object.keys(data)[0]].list
      ? data[Object.keys(data)[0]].list
      : null;

  const keys = list ? Object.values(list) : null;
  if (keys) {
    const cards = keys.map((key) => {
      return {
        header: key && key.header ? key.header : null,
        text: key && key.description ? key.description : null,
        cta: {
          url: key.cta && key.cta.url ? key.cta.url : null,
          text: key.cta && key.cta.text ? key.cta.text : null,
        },
      };
    });
    return {
      cardOne: cards[0],
      cardTwo: cards[1],
    };
  }
};

export const formatListFeatTwo = (listFeatTwoData = {}) => {
  const data = listFeatTwoData || {};

  const content = {
    header: data && data.intro_header ? data.intro_header : null,
    subHeader: data && data.intro_description ? data.intro_description : null,
    type: "list_feat_2",
    headShotdumplings: formatHeadShotdumplings(data.list),
  };

  return { content, theme: data.theme || "theme-1" };
};

const formatHeadShotdumplings = (dumplingData = []) => {
  const data = dumplingData || [];

  return data.map((dumpling) => {
    return {
      content: {
        link:
          dumpling.link && dumpling.link.url
            ? { url: dumpling.link.url }
            : null,
        header: dumpling && dumpling.header ? dumpling.header : null,
        subHeader: dumpling && dumpling.subtitle ? dumpling.subtitle : null,
        text: dumpling && dumpling.description ? dumpling.description : null,
        image: {
          url:
            dumpling.image && dumpling.image.source
              ? dumpling.image.source
              : fpoBox,
          altText:
            dumpling.image && dumpling.image.alt ? dumpling.image.alt : "logo",
        },
      },
      filter: "default",
    };
  });
};

export const formatListFeatThree = (listFeatThreeData = {}) => {
  const data = listFeatThreeData || {};

  const content = {
    header: data.intro_header,
    subHeader: data.intro_description,
    type: "list_feat_3",
    cards: formatListFeatThreeCards(data.list),
  };
  const { theme, background, parallax } = formatPattern(data);
  return {
    theme,
    background,
    parallax,
    content,
  };
};

export const formatListFeatThreeCards = (listFeatThreeCardsData = []) => {
  // this is for Product Landing Page
  const data = listFeatThreeCardsData || [];

  if (data && data[0]) {
    if (
      (data[0].description && !data[0].text) ||
      (data[0].cta && !data[0].link)
    ) {
      return data.map((d) => {
        return {
          header: d && d.header ? d.header : null,
          text: d && d.description ? d.description : null,
          image: {
            url: d.image && d.image.source ? d.image.source : fpoBox,
            altText: d.image && d.image.alt ? d.image.alt : "fpo image",
          },
          link: {
            url: d.cta && d.cta.url ? d.cta.url : " ",
            text: d.cta && d.cta.text ? d.cta.text : " ",
          },
        };
      });
    } else {
      return data;
    }
  }
  // this is for everything else
  if (!data.value) {
    const keys = Object.keys(data);
    return keys.map((key) => {
      return {
        header: data[key] && data[key].header ? data[key].header : null,
        text: data[key] && data[key].description ? data[key].description : null,
        image: {
          url:
            data[key].image && data[key].image.source
              ? data[key].image.source
              : fpoSquare,
          altText:
            data[key].image && data[key].image.alt
              ? data[key].image.alt
              : "card image",
        },
        link: {
          url: data[key].cta && data[key].cta.url ? data[key].cta.url : null,
          text:
            data[key].cta && data[key].cta.title ? data[key].cta.title : null,
        },
      };
    });
  } else {
    console.log(data.value, "else");
    return data.value.map((card = {}) => {
      return {
        header:
          card.teaser && card.teaser.teaser_title
            ? card.teaser.teaser_title
            : null,
        text:
          card.teaser && card.teaser.teaser_desc
            ? card.teaser.teaser_desc
            : null,
        image: {
          url:
            card.teaser &&
            card.teaser.teaser_image &&
            card.teaser.teaser_image.source
              ? card.teaser.teaser_image.source
              : null,
          altText:
            card.teaser &&
            card.teaser.teaser_image &&
            card.teaser.teaser_image.alt
              ? card.teaser.teaser_image.alt
              : null,
        },
        link: {
          url:
            card.teaser && card.teaser.teaser_url
              ? card.teaser.teaser_url
              : null,
          text: "Link",
        },
      };
    });
  }
};

export const formatListFeatFour = () => {};
export const formatListFeatFive = () => {};

export const formatListFeatSix = (listFeatSixData = {}) => {
  const data = listFeatSixData || {};
  let ctas;
  if (data.ctas) {
    ctas = {
      ctaOne: {
        url:
          data.ctas && data.ctas[0] && data.ctas[0].url
            ? data.ctas[0].url
            : null,
        text:
          data.ctas && data.ctas[0] && data.ctas[0].text
            ? data.ctas[0].text
            : null,
      },
      ctaTwo: {
        url:
          data.ctas && data.ctas[1] && data.ctas[1].url
            ? data.ctas[1].url
            : null,
        text:
          data.ctas && data.ctas[1] && data.ctas[1].text
            ? data.ctas[1].text
            : null,
      },
    };
  }

  const content = {
    header: data.intro_header,
    subHeader: data.intro_description,
    ctas: ctas ? ctas : null,
    type: "list_feat_6",
    list: formatListFeatSixCards(data.list),
  };
  const { theme } = formatPattern(data);
  return {
    theme,
    content,
  };
};

export const formatListFeatSixCards = (listFeatSixCardData = []) => {
  const data = listFeatSixCardData || [];

  return data.map((item) => {
    return item.text;
  });
};

export const formatListFeatSeven = (data = {}) => {
  const content = {
    header: data && data.intro_header ? data.intro_header : null,
    subHeader: data && data.intro_description ? data.intro_description : null,
    type: "list_feat_7",
    cards: formatListFeatSevenCards(data.list),
  };

  const { theme, background, parallax } = formatPattern(data);
  return {
    theme,
    background,
    parallax,
    content,
  };
};
const formatListFeatSevenCards = (data) => {
  const keys = Object.keys(data);
  return keys.map((key) => {
    const card = {
      header: data[key].header ? data[key].header : null,
      text: data[key].description ? data[key].description : null,
      thumbnail: {
        url:
          data[key].image && data[key].image.source
            ? data[key].image.source
            : fpoSquare,
        altText:
          data[key].image && data[key].image.alt
            ? data[key].image.alt
            : "card image",
      },
    };

    if (data[key].video && data[key].video.code) {
      const { video } = data[key];
      card.video = {
        url:
          video.source && video.source !== "vidyard"
            ? `https://www.youtube.com/watch?v=${video.code}`
            : null,
        uuid: video.source && video.source === "vidyard" ? video.code : null,
      };

      card.vidyard = video.source && video.source === "vidyard" ? true : false;
    }

    if (card.video) {
      card.thumbnail = {
        url:
          data[key] &&
          data[key].video &&
          data[key].video.poster &&
          data[key].video.poster.source
            ? data[key].video.poster.source
            : fpoBox,
        altText:
          data[key] &&
          data[key].video &&
          data[key].video.poster &&
          data[key].video.poster.alt
            ? data[key].video.poster.alt
            : "placeholder image",
      };
    }

    if (data[key].big_image && data[key].big_image.source) {
      card.image = {
        url: data[key].big_image.source,
        altText: data[key].big_image.alt,
      };

      card.thumbnail = null;
    }

    return card;
  });
};

export const formatHardwareTable = (data) => {
  return data;
};

// - NOT BEING USED
// export const formatProductInfo = (data = {}) => {
//   const { theme, background, parallax } = formatPattern(data);
//   const content = {
//     header: data.intro_header,
//     text: data.intro_description,
//     languageLabel: "Language",
//     materials: formatMaterials(
//       data.materials && data.materials.value ? data.materials.value : []
//     )
//   };

//   return { theme, content };
// };

// - NOT BEING USED
// const formatMaterials = (materials = []) => {
//   return materials.map((materia) => {
//     return {
//       title: materia && materia.title ? materia.title : null,
//       material_file:
//         materia && materia.material_file ? materia.material_file : null,
//       language:
//         materia && materia.material_lang
//           ? materia.material_lang.toLowerCase()
//           : null,
//     };
//   });
// };

const formatPattern = (data) => {
  // TODO add these back in when the JSON is ready

  // if (!pattern) {
  //   return {
  //     background: null,
  //     parallax: null,
  //     theme: null
  //   };
  // }

  // const background = {
  //   backgroundImage: pattern[2],
  //   backgroundPosition: pattern[3],
  //   backgroundImageWidth: pattern[6],
  //   backgroundImageHeight: pattern[7],
  //   backgroundOffsetX: pattern[4],
  //   backgroundOffsetY: pattern[5]
  // };

  // const parallax = pattern[1];
  const theme = data.theme || "default";
  return {
    // background,
    // parallax,
    theme,
  };
};

export const formatTeaserListFeatThree = (
  data = {},
  pageType,
  categoryAlias,
  learnMoreLabel
) => {
  const content = {
    header: data && data.intro_header ? data.intro_header : null,
    subHeader: data && data.intro_description ? data.intro_description : null,
    cards:
      pageType === "category" && categoryAlias
        ? formatTeaserListFeatThreeCategoryCards(
            data.values,
            categoryAlias,
            learnMoreLabel
          )
        : formatTeaserListFeatThreeCards(data.values, learnMoreLabel),
    type: "list_feat_3",
  };
  const theme = "";

  return { content, theme };
};

const formatTeaserListFeatThreeCards = (data = [], learnMoreLabel) => {
  return data.map((card = {}) => {
    return {
      header: card && card.teaser_title ? card.teaser_title : null,
      text: card && card.teaser_desc ? card.teaser_desc : null,
      link: {
        url: card && card.teaser_url ? card.teaser_url : null,
        text: learnMoreLabel || "Learn More",
      },
      image: {
        url:
          card.teaser_img && card.teaser_img.source
            ? card.teaser_img.source
            : fpoBox,
        altText:
          card.teaser_img && card.teaser_img.alt
            ? card.teaser_img.alt
            : "card image",
      },
      regions: card.regions,
      sticky: card.sticky,
    };
  });
};

const formatTeaserListFeatThreeCategoryCards = (
  data = [],
  categoryAlias,
  learnMoreLabel
) => {
  // This iteration needs to manipulate the urls on links to include the category in the pathname
  return data.map((card = {}) => {
    return {
      header: card && card.teaser_title ? card.teaser_title : null,
      text: card && card.teaser_desc ? card.teaser_desc : null,
      link: {
        url:
          card && card.teaser_url
            ? createCategoryProductLink(card.teaser_url, categoryAlias)
            : null,
        text: learnMoreLabel || "Learn More",
      },
      image: {
        url:
          card.teaser_img && card.teaser_img.source
            ? card.teaser_img.source
            : fpoBox,
        altText:
          card.teaser_img && card.teaser_img.alt
            ? card.teaser_img.alt
            : "card image",
      },
      regions: card.regions,
    };
  });
};

const createCategoryProductLink = (url, categoryAlias) => {
  const productAlias = url.replace("/product/", "");

  return `/products/${categoryAlias}/${productAlias}`;
};

export const formatMiniFeatDropDown = (data = {}) => {
  const header = data.header;
  const text = data.description;
  const name = data.label;
  const defaultText =
    data.taxonomy_list && data.taxonomy_list[0] && data.taxonomy_list[0].name
      ? data.taxonomy_list[0].name
      : null;

  const options = data.taxonomy_list.map((datum = {}) => {
    return datum.name;
  });

  const dropdown = {
    options,
    defaultText: options[0],
    name: name,
  };

  const dropdowns = [dropdown];

  return {
    header,
    text,
    name,
    defaultText,
    dropdowns,
  };
};

export const formatListFeatCustom = (data = {}) => {
  const content = {
    type: "list_feat_custom",
    header: data.intro_header ? data.intro_header : null,
    subHeader: data.intro_description ? data.intro_description : null,
    html: data.custom_html ? data.custom_html : null,
  };

  return { content, theme: "theme-1" };
};
