import React, { Component } from "react";
import fetchSearch from "../../utils/async/fetchSearch";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
} from "../../actions";
import Loading from "../../components/Loading/Loading";
import "./SearchResultsPage.scss";
import ErrorPage from "../ErrorPage/ErrorPage";
//import * as Sentry from "@sentry/browser";
import backendURL from "../../utils/helpers/backendURL";
import { Card } from "terra-component-lib";
import PropTypes from "prop-types";

class SearchResultsPage extends Component {
  constructor() {
    super();
    this.state = {
      content: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getData = async () => {
    this.setLoading();

    const url = this.getUrl();
    const dataStructureURL = this.getDataStructureURL();
    try {
      const { content, innerPageNavData } = await fetchSearch(
        url,
        dataStructureURL
      );
      this.setState({ content });
      this.props.setInnerPageNav(innerPageNavData);
      this.setSuccess();
    } catch (error) {
      console.log(error);
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(error);
      // }
      this.setError();
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  changeRegionClickEvent = (content) => {
    if (content.ctas && content.ctas.ctaTwo) {
      content.ctas.ctaTwo.onClick = this.props.toggleRegionLanguageDeployed;
    }
  };

  getUrl = () => {
    const { searchTerm = "" } = this.props.match.params;
    return `${backendURL}/${this.getLanguage()}/api-v1/FetchSearchResults/0/200/${searchTerm}`;
  };

  getDataStructureURL = () => {
    return `${backendURL}/${this.getLanguage()}/api-v1/GetNodeByURL/data-structure$search-results-page/$`;
  };

  getLanguage = () => {
    return `${this.props.language || "en"}`;
  };

  renderResults = () => {
    return this.state.content.results
      .filter((product) => {
        return (
          product.regions.includes(this.props.region) || !product.regions.length
        );
      })
      .map((product) => {
        return <Card content={product} key={product.header} />;
      });
  };

  render() {
    const { status } = this.props;
    return (
      <section
        className={`cec-search-results-page ${this.props.status === "loading" &&
          "cec-search-results-page--loading"}`}
      >
        {status === "loading" && <Loading />}
        {status === "error" && (
          <ErrorPage type="500" history={this.props.history} />
        )}
        {this.state.content &&
          this.state.content.results &&
          this.props.match.params.searchTerm &&
          this.state.content.dataStructureLabels.searchResultsLabel && (
            <>
              <p>
                {this.state.content.dataStructureLabels.searchResultsLabel} "
                {this.props.match.params.searchTerm}":
              </p>
              <section className="cec-search-results-page__results">
                {this.renderResults()}
              </section>
            </>
          )}
        {this.state.content &&
          this.state.content.noResults &&
          this.state.content.dataStructureLabels.noResultsLabel && (
            <p>{this.state.content.dataStructureLabels.noResultsLabel}</p>
          )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
  region: state.region,
  industries: state.industries,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(updateStatus(status)),
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
  setMetadata: (metadata) => dispatch(updateMetadata(metadata)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsPage);

SearchResultsPage.propTypes = {
  status: PropTypes.string,
  language: PropTypes.string,
  region: PropTypes.string,
  industries: PropTypes.array,
  labels: PropTypes.object,
  setInnerPageNav: PropTypes.func,
  setStatus: PropTypes.func,
};
