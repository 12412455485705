import basicFetch from "./basicFetch";
import formatProductData from "../methods/formatProductData";

export default async (url) => {
	const response = await basicFetch(url);
	// productData should return as an object with keys content and innerPageNavLinks with values as arrays
	const productData =
		response.data && response.data[0] && response.data[0].content_sections
			? formatProductData(response.data[0].content_sections)
			: { content: [], innerPageNavLinks: [] };

	const regions = response.data[0].regions ? response.data[0].regions : [];

	const innerPageNavData = {
		pageTitle:
			response.data && response.data[0] && response.data[0].page_name
				? response.data[0].page_name
				: "",
		links: productData.innerPageNavLinks,
	};

	const metadata =
		response.data && response.data[0] && response.data[0].metatags
			? response.data[0].metatags
			: null;

	const productType =
		response.data && response.data[0] && response.data[0].type
			? response.data[0].type
			: null;

	return {
		content: productData.content,
		innerPageNavData,
		metadata,
		regions,
		productType,
	};
};
