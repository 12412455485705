import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

const HelmetHandler = ({ language, metadata, status }) => {
  return (
    <Helmet>
      {
        // -BW-
        <meta
          name="google-site-verification"
          content="sYgUhtITy75Z3ywCuofFL45uGZtsopt3xCA50bZwDUo"
        />
      }
      <link
        id="favicon"
        rel="alternate"
        hrefLang={language ? language : "en"}
        href="/favicon.ico"
        type="image/x-icon"
        data-react-helmet="true"
      />
      <meta charSet="utf-8" />
      <meta name="description" content={metadata.description} />
      {status === "error" && (
        <meta name="prerender-status-code" content="501" />
      )}
      <title>{metadata.title || "Trimble Field Technology"}</title>
      <link
        rel="canonical"
        href={`https://fieldtech.trimble.com${metadata.canonical_url}`}
        hrefLang={language ? language : "en"}
      />
      {metadata.og_image && (
        <meta property="og:image" content={metadata.og_image} />
      )}
    </Helmet>
  );
};

const mapStateToProps = (state) => {
  return {
    languages: state.languages,
    language: state.language,
    metadata: state.metadata,
  };
};

export default connect(mapStateToProps)(HelmetHandler);
