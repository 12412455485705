import React, { Component } from "react";
import fetchProductLanding from "../../utils/async/fetchProductLanding";
import PageBuilder from "../../components/PageBuilder/PageBuilder";
import backendURL from "../../utils/helpers/backendURL";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";
import { connect } from "react-redux";
//import * as Sentry from "@sentry/browser";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
} from "../../actions";
import PropTypes from "prop-types";
import "./ProductsLandingPage.scss";

class ProductsLandingPage extends Component {
  constructor() {
    super();

    this.state = {
      content: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getLanguage = () => {
    return `${this.props.language || "en"}/`;
  };

  getURL = () => {
    return `${backendURL}/${this.getLanguage()}api-v1/GetNodeByURL/products/$`;
  };

  getData = async () => {
    this.setLoading();
    const url = this.getURL();
    try {
      const { data, innerPageNavData, metadata } = await fetchProductLanding(
        this.props.learnMoreLabel,
        url
      );
      this.props.setInnerPageNav(innerPageNavData);
      this.props.setMetadata(metadata);
      this.setState({ content: data });
      this.setSuccess();
    } catch (e) {
      this.setError();
      console.log(e);
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(e);
      // }
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  render() {
    const { status } = this.props;
    return (
      <section
        className={`ftg-products-landing-page ${status === "loading" &&
          "ftg-products-landing-page--loading"}`}
      >
        {status === "loading" && <Loading />}
        {status === "error" && (
          <ErrorPage type="500" history={this.props.history} />
        )}
        {status === "success" && (
          <PageBuilder type={this.props.type} content={this.state.content} />
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  learnMoreLabel: state.learnMoreLabel,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(updateStatus(status)),
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
  setMetadata: (metadata) => dispatch(updateMetadata(metadata)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsLandingPage);

ProductsLandingPage.propTypes = {
  status: PropTypes.string,
  language: PropTypes.string,
  setInnerPageNav: PropTypes.func,
  setStatus: PropTypes.func,
  setMetadata: PropTypes.func,
  region: PropTypes.string,
};
