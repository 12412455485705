import basicFetch from "./basicFetch";
import _ from "lodash";

export default async (url, content) => {
  const response = await basicFetch(url);
  const formattedData = formatData(response.data, content);

  return {
    data: response.data.map((order) => {
      return order.name;
    }),
    content: formattedData,
  };
};

const formatData = (data, content) => {
  const products = content.map((product) => {
    if (product.list.length === data.length) {
      return product;
    }
    data.forEach((item) => {
      let present = false;
      product.list.forEach((feat) => {
        if (feat.feature_name === item.name) {
          present = true;
        }
      });

      if (!present) {
        product.list.push({
          feature_name: item.name,
          cell_value: " ",
        });
      }
    });
    return product;
  });

  let emptyFeatureList = [];

  let removeEmptiesHelper = {};

  // puts all features that have empty cell values into an array called emptyFeatureList
  products.forEach((product) => {
    product.list.forEach((x) => {
      if (x.cell_value === " ") {
        emptyFeatureList.push(x.feature_name);
      }
    });
  });

  // removeEmptiesHelper object will contain keys as features and values as the number of times the feature is "empty". Result will look like this

  // {
  // feature_1: 1,
  // feature_2: 3,
  // feature_3: 3,
  // feature_4: 2
  // }

  // if the value of a key is the same as the length of our products array, then we know that the particular feature is a complete empty row on the table and we do not want to display it.

  for (let i = 0; i < emptyFeatureList.length; i++) {
    let num = emptyFeatureList[i];
    if (removeEmptiesHelper[num] > 0) {
      removeEmptiesHelper[num]++;
    } else {
      removeEmptiesHelper[num] = 1;
    }
  }

  // removeFeaturesfromList creates an array that has the indices of our exisiting data array that we will want to remove. We create this array so that we can easily remove the unwanted indices using _.pull from lodash.

  let removeFeaturesfromList = [];
  for (let i in removeEmptiesHelper) {
    if (removeEmptiesHelper[i] === products.length) {
      data.forEach((d) => {
        if (d.name === i) {
          removeFeaturesfromList.push(d);
        }
      });
    }
  }

  // _.pull modifies an array by removing the indice as stated. an example is:
  // const array = [1, 2, 3, 4, 5]
  // _.pull(array, 3)
  //output is [1, 2, 4, 5]

  // we loop through removeFeaturesList bc each indice of removeFeaturesList needs to be removed from the data array.
  for (let i in removeFeaturesfromList) {
    _.pull(data, removeFeaturesfromList[i]);
  }

  return products;
};
