import React from "react";
import { Footer } from "luna-one";

const FooterHandler = ({ footerData }) => {
  return (
    <Footer
      trimbleText={
        footerData && footerData.trimbleText ? footerData.trimbleText : null
      }
      trimbleLink={
        footerData && footerData.trimbleLink ? footerData.trimbleLink : null
      }
      siteText={footerData && footerData.siteText ? footerData.siteText : null}
      siteLink={footerData && footerData.siteLink ? footerData.siteLink : null}
      linksOne={footerData && footerData.linksOne ? footerData.linksOne : null}
      linksTwo={footerData && footerData.linksTwo ? footerData.linksTwo : null}
      logo={footerData && footerData.logo ? footerData.logo : null}
      socialLinks={
        footerData && footerData.socialLinks ? footerData.socialLinks : null
      }
      legalStuff={
        footerData && footerData.legalStuff ? footerData.legalStuff : null
      }
    />
  );
};

export default FooterHandler;
