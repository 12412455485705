const organizeMenus = (data = []) => {
  // Format menu JSON for the NavBar
  const menusSetByType = setMenusByType(data);
  const organizedMenus = menusSetByType.map((menu) => {
    const organizedMenu = {
      text: menu.title,
      type: menu.type,
    };

    if (menu.type === "link") {
      organizedMenu.url = menu.url;
    }

    if (menu.type === "mini menu") {
      organizedMenu.links = organizeMiniMenu(menu);
    }

    if (menu.type === "mega menu") {
      // Format the MegaMenu content
      if (menu.url) {
        // Set the left-side bottom link for the MegaMenu
        // TODO The link's text is currently hard-coded, but needs to come from Drupal
        organizedMenu.bottomLink = { url: menu.url, text: "Link" };
      }
      organizedMenu.links = organizeMegaMenu(menu);
    }
    return organizedMenu;
  });

  return organizedMenus;
};

export const setMenusByType = (menuData) => {
  const data = menuData || [];
  const menus = data.map((point) => {
    if (point.children) {
      // Check to see if the object's children array has nested children array and define as a mega/mini menu
      const megaMenu = checkforMegaMenu(point.children);

      //TODO this will need to come from drupal instead of hardcoding products

      if (megaMenu) {
        point.type = "mega menu";
      } else {
        point.type = "mini menu";
      }
    } else {
      // If the object has no children, define it as a link
      point.type = "link";
    }

    return point;
  });
  return menus;
};

export const checkforMegaMenu = (childrenData) => {
  const children = childrenData || [];
  return children.reduce((result, child) => {
    // iterate over all the children and check for nested children arrays, returning true if any are found
    if (child.children) {
      result = true;
    }
    return result;
  }, false);
};

export const organizeMegaMenu = (menu) => {
  // format the mega menu object to fit the NavBar

  if (menu && menu.children) {
    return (menu.links = menu.children.map((child) => {
      return {
        text: child.title,
        links: child.children
          ? child.children.map((grandchild) => {
              return {
                url: grandchild.url,
                text: grandchild.title,
              };
            })
          : [],
      };
    }));
  } else {
    return [];
  }
};

export const organizeMiniMenu = (menuData) => {
  const menu = menuData || {};
  // format the mini menu object to fit the NavBar

  if (menu && menu.children) {
    return menu.children.map((child) => {
      return {
        url: child.url,
        text: child.title,
      };
    });
  } else {
    return [];
  }
};

export default organizeMenus;
