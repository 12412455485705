import basicFetch from "./basicFetch";
import formatSearchResults, {
  formatDataStructureResponse
} from "../methods/formatSearchResults";

const fetchSearch = async (url, dataStructureURL) => {
  const response = await basicFetch(url);
  const dataStructureResponse = await basicFetch(dataStructureURL);

  if (response && response.data && response.data.count === 0) {
    const content = { noResults: true };

    content.dataStructureLabels = formatDataStructureResponse(
      dataStructureResponse.data[0].content_sections.data_struct_items
    );

    return {
      content,
      innerPageNavData: {
        pageTitle: content.dataStructureLabels.innerNavLabel,
        links: []
      }
    };
  }

  const dataStructureLabels =
    formatDataStructureResponse(
      dataStructureResponse.data[0].content_sections.data_struct_items
    ) || {};

  const { content } = formatSearchResults(
    response.data.results,
    dataStructureLabels.learnMoreLabel
  );

  content.dataStructureLabels = dataStructureLabels;

  return {
    content,
    innerPageNavData: {
      pageTitle: content.dataStructureLabels.innerNavLabel,
      links: []
    }
  };
};

export default fetchSearch;
