import basicFetch from "./basicFetch";

const fetchAllProducts = async (url) => {
  const response = await basicFetch(url);

  const content = formatData(response);

  return { content };
};

const formatData = (response = {}) => {
  if (!response.data) {
    throw new Error("Missing Products");
  }

  const { data } = response;

  if (!data.nodes) {
    throw new Error("Missing Products");
  }

  const { nodes } = data;

  return nodes.map((node = {}) => {
    return {
      header: node.teaser_title || "No product name",
      text: node.teaser_desc || "No product description",
      image: getImage(node.teaser_img || node.image),
      button: getLink(node.teaser_url),
      type: node.type,
    };
  });
};

const getImage = (imageData = {}) => {
  if (!imageData.source) {
    return null;
  }

  return {
    url: imageData.source,
    altText: imageData.alt || "product image",
  };
};

const getLink = (linkData = {}) => {
  if (!linkData) {
    return null;
  }

  return {
    text: "Product Page",
    url: linkData,
  };
};
export default fetchAllProducts;
