import "react-app-polyfill/ie11";
import "babel-polyfill";
import "isomorphic-fetch";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
//import * as Sentry from "@sentry/browser";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-KN8TCZ9",
};

TagManager.initialize(tagManagerArgs);

const ignoredErrors = [
  "SyntaxError: DOM Exception 12",
  "SyntaxError: SyntaxError: DOM Exception 12",
  "Error: SyntaxError: DOM Exception 12",
  `NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
  `Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
  "Request aborted",
];

// Sentry.init({
//   dsn: "https://bf51be8a7a394108bee01d0644f253c0@sentry.io/2714889",
//   beforeSend(event, hint) {
//     const error = hint?.originalException?.message;

//     if (
//       process.env.NODE_ENV === "development" ||
//       ignoredErrors.includes(error)
//     ) {
//       return null;
//     } else {
//       return event;
//     }
//   },
// });

const store = createStore(rootReducer, composeWithDevTools());

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
