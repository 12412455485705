import basicFetch from "./basicFetch";

const fetchAdminCategoriesPage = async url => {
  const response = await basicFetch(url);

  const content = formatData(response.data);

  return { content };
};

const formatData = data => {
  // First create the parent object that will contain basic category info with translations as a sub category - English will be the standard langauge for all of these.
  const categories = data
    .filter((category = {}) => {
      return category.lang === "en";
    })
    .map((category = {}) => {
      const splitName = category.name.split(" - ");
      return {
        name: splitName[0],
        industry: splitName[1],
        url: category.canonical_url,
        translations: []
      };
    });

  data.forEach(category => {
    const parentCategoryObj = categories.find(parentCategory => {
      return parentCategory.url === category.canonical_url;
    });

    const translationData = {
      name: category["teaser-title"],
      teaserImage: { url: category["teaser-image"].source || "" },
      text: category["teaser-description"],
      language: category.lang
    };

    if (parentCategoryObj) {
      parentCategoryObj.translations.push(translationData);
    }
  });

  return categories;
};

export default fetchAdminCategoriesPage;
