const formatSearchResults = (results = [], learnMoreLabel) => {
  const formattedResults = results
    .filter((result = {}) => {
      return result && result.teaser_title;
    })
    .map((product = {}) => {
      return {
        header: product.teaser_title || null,
        text: product.teaser_desc || null,
        image: {
          url:
            product.teaser_img && product.teaser_img.source
              ? product.teaser_img.source
              : null,
          altText:
            product.teaser_img && product.teaser_img.alt
              ? product.teaser_img.alt
              : product.teaser_title
        },
        button: {
          text: learnMoreLabel || "Learn More",
          url: product.teaser_url || null
        },
        regions: product.regions || []
      };
    });

  return { content: { results: formattedResults } };
};

export const formatDataStructureResponse = data => {
  const searchResultsLabel = data.find(
    point => point.attribute_name === "Search Results Label"
  );
  const noResultsLabel = data.find(
    point => point.attribute_name === "No Results Label"
  );

  const innerNavLabel = data.find(
    point => point.attribute_name === "Inner Nav Title"
  );
  const learnMoreLabel = data.find(
    point => point.attribute_name === "Learn More Label"
  );

  return {
    searchResultsLabel:
      searchResultsLabel && searchResultsLabel.attribute_value
        ? searchResultsLabel.attribute_value
        : null,
    noResultsLabel:
      noResultsLabel && noResultsLabel.attribute_value
        ? noResultsLabel.attribute_value
        : null,
    innerNavLabel:
      innerNavLabel && innerNavLabel.attribute_value
        ? innerNavLabel.attribute_value
        : null,
    learnMoreLabel:
      learnMoreLabel && learnMoreLabel.attribute_value
        ? learnMoreLabel.attribute_value
        : "Learn More"
  };
};

export default formatSearchResults;
