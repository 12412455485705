import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateStatus,
  updateInnerPageNav,
  updateMetadata,
} from "../../actions";
import fetchAdminCategoriesPage from "../../utils/async/fetchAdminCategoriesPage";
import backendURL from "../../utils/helpers/backendURL";
import Loading from "../../components/Loading/Loading";
import "./AdminCategoriesPage.scss";
//import * as Sentry from "@sentry/browser";
import { AdminCategoryData } from "luna-one";
import { TextInput, Radios, Button } from "terra-component-lib";
import PropTypes from "prop-types";

class AdminCategoriesPage extends Component {
  constructor() {
    super();

    this.state = {
      content: [],
      filter: "",
      radio: null,
    };
  }

  onChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    this.checkRoute(prevProps);
  }

  checkRoute = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
  };

  getURL = () => {
    return `${backendURL}/${this.getLanguage()}/api-v1/FetchUtility/category_page`;
  };

  getLanguage = () => {
    return this.props.language || "en";
  };

  getData = async () => {
    const url = this.getURL();
    this.setLoading();

    try {
      const { content } = await fetchAdminCategoriesPage(url);
      this.props.setInnerPageNav({
        pageTitle: "Admin Category Page QA",
        links: [],
      });
      this.setState({ content });
      this.setSuccess();
    } catch (error) {
      console.log(error);
      this.setError();
      // if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
      //   Sentry.captureException(error);
      // }
    }
  };

  setLoading = () => {
    this.props.setStatus("loading");
    window.prerenderReady = false;
  };

  setSuccess = () => {
    this.props.setStatus("success");
    window.prerenderReady = true;
  };
  setError = () => {
    this.props.setStatus("error");
    window.prerenderReady = true;
  };

  renderProductData = () => {
    const products = this.state.content || [];

    const filteredProducts = this.filterProducts(products);

    return filteredProducts.map((product, index) => {
      return (
        <AdminCategoryData
          key={product.name + index}
          content={product}
          currentLanguageURL={this.props.language}
        />
      );
    });
  };

  filterProducts = (products) => {
    if (!this.state.filter || this.state.filter.length === 0) {
      if (!this.state.radio) {
        return products;
      } else {
        return products.filter((product) => {
          return product.translations.reduce((result, translation = {}) => {
            if (translation.language === this.state.radio) {
              result = true;
            }
            return result;
          }, false);
        });
      }
    } else {
      if (!this.state.radio) {
        return products.filter((product = {}) => {
          return (
            product.name &&
            product.name.toLowerCase &&
            product.name.toLowerCase().includes(this.state.filter.toLowerCase())
          );
        });
      } else {
        return products
          .filter((product = {}) => {
            return (
              product.name &&
              product.name.toLowerCase &&
              this.state.filter &&
              this.state.filter.toLowerCase &&
              product.name
                .toLowerCase()
                .includes(this.state.filter.toLowerCase())
            );
          })
          .filter((product) => {
            return product.translations.reduce((result, translation = {}) => {
              if (
                translation.language &&
                this.state.radio &&
                translation.language === this.state.radio
              ) {
                result = true;
              }
              return result;
            }, false);
          });
      }
    }
  };

  handleSelect = (radio) => {
    const langs = {
      English: "en",
      French: "fr",
      German: "de",
      Spanish: "es",
    };

    this.setState({ radio: langs[radio] });
  };

  clearRadios = () => {
    this.setState({ radio: null });
  };

  determineSelected = () => {
    const langs = {
      en: "English",
      fr: "French",
      de: "German",
      es: "Spanish",
    };

    return langs[this.state.radio];
  };

  render() {
    const { status } = this.props;
    const { content } = this.state;
    return (
      <main
        className={`luna-admin-page ${status === "loading" &&
          "luna-admin-page--loading"}`}
      >
        {status === "loading" && <Loading />}
        {status === "error" && <p>error</p>}
        {status === "success" && content && (
          <main>
            <h1 className="luna-admin-page__header">
              Administrative QA: Category Pages
            </h1>
            <TextInput
              label="Search categories:"
              inputChange={this.onChange}
              value={this.state.filter}
              placeholder="search"
            />
            <p className="luna-admin-page__lang-filter-label">
              Filter by language:
            </p>
            <section className="luna-admin-page__radios">
              <Radios
                radios={["English", "French", "German", "Spanish"]}
                selected={this.determineSelected()}
                selectRadio={this.handleSelect}
              />
              <Button
                onClick={this.clearRadios}
                text="Clear"
                className="ter-button--primary--1"
              />
            </section>
            <p className="luna-admin-page__prod-section-label">Categories:</p>
            {this.renderProductData()}
          </main>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(updateStatus(status)),
    setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
    updateMetadata: (metadata) => dispatch(updateMetadata(metadata)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCategoriesPage);

AdminCategoriesPage.propTypes = {
  status: PropTypes.string,
  language: PropTypes.string,
  setInnerPageNav: PropTypes.func,
  setStatus: PropTypes.func,
  updateMetadata: PropTypes.func,
};
