let backendURL;

if (process.env.REACT_APP_DOMAIN === "development") {
	backendURL = "https://fieldtech-cms.trimble.com";
} else if (process.env.REACT_APP_DOMAIN === "staging") {
	backendURL = "https://fieldtech-cms.trimble.com";
} else if (process.env.REACT_APP_DOMAIN === "production") {
	backendURL = "https://fieldtech-cms.trimble.com";
}

export default backendURL;
