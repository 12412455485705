import React from "react";
import loadingImage from "../../assets/images/loading-mini.gif";

const Loading = () => {
  return (
    <div className="luna-loading">
      <img src={loadingImage} alt="loading animation" />
    </div>
  );
};

export default Loading;
