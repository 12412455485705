import basicFetch from "./basicFetch";
import fpoBox from "../../assets/images/fpoBox.png";

export default async url => {
  const response = await basicFetch(url);
  formatItems(response.data.nodes);
  return formatItems(response.data.nodes);
};

const formatItems = data => {
  return data.map((datum = {}) => {
    return {
      title: datum.item_label || null,
      header: datum.item_subheader || null,
      text: datum.field_item_description || null,
      link: {
        url:
          datum &&
          datum.item_link &&
          datum.item_link[0] &&
          datum.item_link[0].url
            ? datum.item_link[0].url
            : null,
        text:
          datum &&
          datum.item_link &&
          datum.item_link[0] &&
          datum.item_link[0].text
            ? datum.item_link[0].text
            : null
      },
      image: {
        url:
          datum && datum.item_image && datum.item_image.source
            ? datum.item_image.source
            : fpoBox,
        altText:
          datum && datum.item_image && datum.item_image.alt
            ? datum.item_image.alt
            : "placeholder image"
      },
      priceTitle: datum.item_price_label || null,
      price: datum.item_price || null,
      button: {
        url:
          datum &&
          datum.item_purchase &&
          datum.item_purchase[0] &&
          datum.item_purchase[0].url
            ? datum.item_purchase[0].url
            : null,
        text:
          datum &&
          datum.item_purchase &&
          datum.item_purchase[0] &&
          datum.item_purchase[0].text
            ? datum.item_purchase[0].text
            : null
      },
      deliveryEstimation: datum.item_delivery || null
    };
  });
};
